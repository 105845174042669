<template>
    <div v-show="editorDialog" class="modal-overlay">
        <div class="modal-content">
            <v-card v-if="selectedDocument">
                <v-toolbar dense flat ref="toolbarRef">
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="save">Speichern</v-btn> <!-- Speichern-Button hinzugefügt -->
                    <v-btn icon @click="closeEditor">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-container>
                    <v-select
                        :items="categories"
                        item-title="name"
                        item-value="id"
                        v-model="selectedCategory"
                        label="Kategorie auswählen"
                    ></v-select>
                    <v-row>
                <v-col cols="12" sm="10">
                    <v-text-field v-model="title" label="Titel" single-line></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                    <v-text-field v-model="sort_order" label="Sort_Order" type="number" single-line></v-text-field>
                </v-col>
                </v-row>
                    <ckeditor
                        :editor="EditorClass"
                        :config="EditorClass.defaultConfig"
                        v-model="content"
                    />
                    <v-textarea v-model="notes" label="Notizen" class="mt-5"></v-textarea>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="save" style="float:right">Speichern</v-btn>
                </v-container>
            </v-card>
            <v-card v-else-if="selectedDocumentPreview">
                <v-toolbar dense flat ref="toolbarRef">
                    <v-toolbar-title>Vorschau</v-toolbar-title>
                    <v-btn icon @click="closeEditor">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-container>
                    <ckeditor
                        :editor="EditorClass"
                        :config="EditorClass.defaultConfig"
                        v-model="content"
                    />
                </v-container>
            </v-card>
            <v-card v-else-if="selectedDocumentEmployeeDocument">
                <v-toolbar dense flat ref="toolbarRef">
                    <v-toolbar-title>{{employeeToEditName}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="save">Speichern</v-btn> <!-- Speichern-Button hinzugefügt -->
                    <v-btn icon @click="closeEditor">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-container>
                    <ckeditor
                        :editor="EditorClass"
                        :config="EditorClass.defaultConfig"
                        v-model="content"
                    />
                    <v-textarea v-model="notes" label="Notizen" class="mt-5"></v-textarea>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="save" style="float:right">Speichern</v-btn>
                </v-container>
            </v-card>
        </div>
    </div>
  </template>
  
  <script lang="ts">
    import { defineComponent, ref, watch, watchEffect, Ref, onBeforeUnmount, onMounted } from 'vue';
    import EditorClass from '@/ckeditor/ckeditor.js';
    import { Document, Category } from '@/types/Document';
    import { Employee } from '@/types/Members';


  export default defineComponent({
    props: {
        selectedDocument: {
            type: Object as () => Document | null,
            default: null,
        },
        selectedDocumentPreview: {
            type: String,
            default: null,
        },
        selectedDocumentEmployeeDocument:{
            type: Object as () => Employee | null,
            default: null,
        },
        categories: {
            type: Array as () => Array<Category>,
            required: true,
        },
    },
    components: {
    },
    setup(props, { emit }) {
        const selectedCategory = ref<number>(1);
        const title = ref('');
        const content = ref('');
        const notes = ref('');
        const sort_order = ref(0);
        const editorDialog = ref(false);
        const showModal = ref(false);
        const employeeToEditId = ref(-1);
        const employeeToEditName = ref("");


        let editorInstance: EditorClass | null = null;



        const toolbarRef = ref<HTMLElement | null>(null);


        onBeforeUnmount(() => {
            toggleBodyScroll(false);
        });



        watch(
            () => props.selectedDocument,
            (newVal) => {
                editorDialog.value = newVal != null;
            }
        );

        watch(
            () => props.selectedDocumentPreview,
            (newVal) => {
                editorDialog.value = newVal != null;
            }
        );

        watch(
            () => props.selectedDocumentEmployeeDocument,
            (newVal) => {
                editorDialog.value = newVal != null;
            }
        );

        watch(
            () => props.categories,
            (newCategories) => {
                if (newCategories.length > 0) {
                    selectedCategory.value = newCategories[0].id;
                }
            },
            { immediate: true }
        );

        const editorContainer = ref(null);
        

        const save = () => {
            if (props.selectedDocument) {
                emit("update:selectedDocument", {
                    ...props.selectedDocument,
                    category_id: selectedCategory.value,
                    title: title.value,
                    content: content.value,
                    notes: notes.value,
                    sort_order: sort_order.value
                });
                closeEditor();
            }else if(props.selectedDocumentEmployeeDocument){
                emit("update:selectedDocumentEmployeeDocument", {
                    notes: content.value,
                    id: employeeToEditId.value,
                })
                closeEditor();
            }
        };


        watchEffect(() => {
            if (editorDialog.value && editorContainer.value && !editorInstance) {
                EditorClass.create(editorContainer.value, EditorClass.defaultConfig)
                    .then((editor) => {
                        editorInstance = editor;
                        updateEditorContent(); // Füge den Inhalt ein, sobald der Editor erstellt wurde
                        watch(
                            () => props.selectedDocument,
                            () => {
                                updateEditorContent();
                            },
                            { immediate: true }
                        );

                        watch(
                            () => props.selectedDocumentPreview,
                            () => {
                                updateEditorContent();
                            },
                            { immediate: true }
                        );

                        watch(
                            () => props.selectedDocumentEmployeeDocument,
                            () => {
                                updateEditorContent();
                            },
                            { immediate: true }
                        );

                        editorInstance.model.document.on('change:data', () => {
                            if (editorInstance) {
                                content.value = editorInstance.getData();
                            }
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else if (!editorDialog.value && editorInstance) {
                editorInstance.destroy().then(() => {
                    editorInstance = null;
                });
            }
        });


        watchEffect(() => {
            if (props.selectedDocument && props.selectedDocument.id != -1) {
                title.value = props.selectedDocument.title;
                content.value = props.selectedDocument.content;
                notes.value = props.selectedDocument.notes;
                sort_order.value = props.selectedDocument.sort_order
                // Finde die Kategorie in props.categories, die der category_id entspricht
                const category = props.categories.find(
                    (category) => category.id == props.selectedDocument.category_id
                );
                if (category) {
                    selectedCategory.value = category.id;
                }
                showModal.value= true;
            } else if (props.selectedDocumentPreview) {
                content.value = props.selectedDocumentPreview;
                showModal.value= true;
            } else if(props.selectedDocumentEmployeeDocument){
                content.value = props.selectedDocumentEmployeeDocument.notes;
                employeeToEditId.value = props.selectedDocumentEmployeeDocument.id;
                employeeToEditName.value = props.selectedDocumentEmployeeDocument.name;
                showModal.value= true;
            }else if(props.selectedDocument && props.selectedDocument.id == -1) {
                title.value = "";
                content.value = "";
                notes.value = "";
                sort_order.value = 0;
                // Aktualisiere selectedCategory nur, wenn props.categories definiert ist und mindestens ein Element enthält
                if (props.categories && props.categories.length > 0) {
                    selectedCategory.value = props.categories[0].id;
                }
                showModal.value= true;
            }
        });


        onBeforeUnmount(() => {
            if (editorInstance) {
                editorInstance.destroy();
                showModal.value= false;
            }
        });

        // ... setup function ...
        const updateEditorContent = () => {
            if (editorInstance && props.selectedDocument) {
                editorInstance.setData(props.selectedDocument.content);
            }else if( editorInstance && props.selectedDocumentPreview){
                editorInstance.setData(props.selectedDocumentPreview)
            }else if(editorInstance && props.selectedDocumentEmployeeDocument){
                editorInstance.setData(props.selectedDocumentEmployeeDocument.notes)
            }
        };

        watch(
            () => props.selectedDocument,
            () => {
                updateEditorContent();
            }
        );
        
        const closeEditor = () => {
            editorDialog.value = false;
            emit("close-document");
        };  

        const toggleBodyScroll = (disable) => {
            if (disable) {
                document.documentElement.classList.add("disable-scroll");
            } else {
                document.documentElement.classList.remove("disable-scroll");
            }
        };


        watchEffect(() => {
            toggleBodyScroll(editorDialog.value);
        });
        return {
            editorDialog,
            closeEditor,
            title,
            content,
            notes,
            editorContainer,
            save,
            selectedCategory,
            EditorClass,
            toolbarRef,
            sort_order,
            showModal,
            employeeToEditName,
        };
    },
  });
  </script>
    <style scoped>
        @import "../../scss/ckeditor-dark-theme.css";

        /* Verhindert horizontale und vertikale Scrollbars in Tabellenzellen (CKEditor) für Chrome */
        .ck.ck-content table td,
        .ck.ck-content table th {
            overflow: hidden;
        }

        /* Behält die vertikale Scrollbar bei, falls erforderlich */
        .ck.ck-content table td > *,
        .ck.ck-content table th > * {
            resize:none;
        }


        .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            justify-content: center;
            align-items: center;
            z-index: 9999; /* Adjust this value if needed */
        }

        .modal-content {
            width: calc(100vw - 255px);
            max-height: calc(100vh - 64px);
            overflow-y: auto;
            background-color: #212121;
            min-height: 95%;
        }
        

        .document-editor-container {
            position: fixed;
            top: 34px;
            left: 128px;
            right: 0;
            bottom: 0;
            background-color: transparent;
            z-index: 1000;
        }

        .v-card.v-theme--dark.v-card--density-default.v-card--variant-elevated {
            height: 100%;
        }

        
        .ck-editor__editable {
            resize: vertical;
            overflow: auto;
            min-height: 500px;
            padding: 30px;
        }
        .ck.ck-editor__main > .ck-editor__editable {
            padding-left: 30px;
        }
        .ck.ck-editor__top.ck-reset_all {
            z-index: var(--ck-z-modal);
            position: -webkit-sticky; /* Für Safari-Browser */
            position: sticky;
            top: 0;
            width: 100%;
            background-color: var(--ck-color-toolbar-background);
        }




    </style>
