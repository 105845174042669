<template>
	<v-container fluid>
	<v-row>
		<v-col>
		<h3>Wetterverwaltung</h3>
		<v-btn @click="openNewWeatherDialog">Neuen Wettereintrag hinzufügen</v-btn>
		<v-data-table
			:headers="weatherHeaders"
			:items="weatherData"
			:item-key="'id'"
			class="mt-4"
		>
			<template v-slot:[`item.actions`]="{ item }">
			<v-icon small class="mr-2" @click="openEditWeatherDialog(item)">mdi-pencil</v-icon>
			<v-icon small @click="confirmDelete(item)">mdi-delete</v-icon>
			</template>
		</v-data-table>
		</v-col>
	</v-row>

	<v-dialog v-model="editDialog" persistent max-width="600px">
		<v-card>
		<v-card-title>
			<span class="text-h5">{{ itemType === 'weather' ? 'Wettereintrag bearbeiten' : 'Neuen Wettereintrag hinzufügen' }}</span>
		</v-card-title>
		<v-card-text>
			<v-container>
			<v-row>
				<v-col cols="12">
				<v-text-field v-model="selectedWeather.date" label="Datum" required type="date"></v-text-field>
				</v-col>
				<v-col cols="12">
				<v-select v-model="selectedWeather.day" :items="daysOfWeek" label="Tag" required></v-select>
				</v-col>
				<v-col cols="6">
				<v-text-field v-model="selectedWeather.min_temp" label="Mind. Temperatur" required></v-text-field>
				</v-col>
				<v-col cols="6">
				<v-text-field v-model="selectedWeather.max_temp" label="Max. Temperatur" required></v-text-field>
				</v-col>
				<v-col cols="12">
				<v-select v-model="selectedWeather.icon" :items="icons" item-title="name" item-value="icon" label="Icon" required></v-select>
				</v-col>
				<v-col cols="12">
				<v-text-field v-model="selectedWeather.humidity" label="Luftfeuchtigkeit" required></v-text-field>
				</v-col>
				<v-col cols="12">
				<v-text-field v-model="selectedWeather.warning" label="Warnung"></v-text-field>
				</v-col>
				<v-col cols="12">
				<v-text-field v-model="selectedWeather.wind_speed" label="Windgeschwindigkeit" required></v-text-field>
				</v-col>
			</v-row>
			</v-container>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="editDialog = false">Abbrechen</v-btn>
			<v-btn color="blue darken-1" text @click="saveWeather">Speichern</v-btn>
		</v-card-actions>
		</v-card>
	</v-dialog>

	<v-dialog v-model="confirmDialog" persistent max-width="300px">
		<v-card>
		<v-card-title>
			<span class="text-h5">Löschen bestätigen</span>
		</v-card-title>
		<v-card-text>
			Willst du diesen Eintrag wirklich löschen?
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="confirmDialog = false">Abbrechen</v-btn>
			<v-btn color="blue darken-1" text @click="proceedWithDelete">Löschen</v-btn>
		</v-card-actions>
		</v-card>
	</v-dialog>
	</v-container>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import api from '@/api';

interface Weather {
	id: number;
	date: string;
	day: string;
	min_temp: number;
	max_temp: number;
	icon: string;
	humidity: number;
	warning: string;
	wind_speed: number;
}

interface Icon {
	name: string;
	icon: string;
}

export default defineComponent({
	setup() {
	const weatherData = ref<Weather[]>([]);
	const selectedWeather = ref<Weather | null>(null);
	const editDialog = ref(false);
	const confirmDialog = ref(false);
	const itemType = ref('');
	const errorSnackbar = ref<any>({ visible: false, message: "" });

	const weatherHeaders = [
		{ title: 'Datum', key: 'date', align: 'start' },
		{ title: 'Tag', key: 'day' },
		{ title: 'Min Temperatur', key: 'min_temp' },
		{ title: 'Max Temperatur', key: 'max_temp' },
		{ title: 'Icon', key: 'icon' },
		{ title: 'Luftfeuchtigkeit', key: 'humidity' },
		{ title: 'Warnung', key: 'warning' },
		{ title: 'Windgeschwindigkeit', key: 'wind_speed' },
		{ title: 'Aktionen', key: 'actions' },
	];

	const icons: Icon[] = [
		{ name: 'Sonnig', icon: 'mdi-weather-sunny' },
		{ name: 'Teilweise bewölkt', icon: 'mdi-weather-partly-cloudy' },
		{ name: 'Regnerisch', icon: 'mdi-weather-rainy' },
		{ name: 'Bewölkt', icon: 'mdi-weather-cloudy' },
		{ name: 'Gewitter mit Regen', icon: 'mdi-weather-lightning-rainy' },
	];

	const daysOfWeek = [
		'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'
	];

	const fetchWeatherData = async () => {
		try {
		const response = await api.get("admin/weather/?action=getWeather");
		weatherData.value = response.data;
		} catch (error) {
		errorSnackbar.value.message = error.response.data.error;
		errorSnackbar.value.visible = true;
		}
	};

	const openEditWeatherDialog = (weather: Weather) => {
		selectedWeather.value = { ...weather };
		itemType.value = 'weather';
		editDialog.value = true;
	};

	const openNewWeatherDialog = () => {
		selectedWeather.value = { id: 0, date: '', day: '', min_temp: 0, max_temp: 0, icon: '', humidity: 0, warning: '', wind_speed: 0 };
		itemType.value = 'weather';
		editDialog.value = true;
	};

	const saveWeather = async () => {
		try {
		const payload = {
			...selectedWeather.value,
		};
		if (payload.id) {
			await api.post("admin/weather/?action=updateWeather", payload);
		} else {
			await api.post("admin/weather/?action=insertWeather", payload);
		}
		editDialog.value = false;
		await fetchWeatherData();
		} catch (error) {
		errorSnackbar.value.message = error.response.data.error;
		errorSnackbar.value.visible = true;
		}
	};

	const deleteWeather = async (weather: Weather) => {
		try {
		await api.post("admin/weather/?action=deleteWeather", { id: weather.id });
		await fetchWeatherData();
		} catch (error) {
		errorSnackbar.value.message = error.response.data.error;
		errorSnackbar.value.visible = true;
		}
	};

	const confirmDelete = (weather: Weather) => {
		selectedWeather.value = weather;
		confirmDialog.value = true;
	};

	const proceedWithDelete = async () => {
		if (selectedWeather.value) {
		await deleteWeather(selectedWeather.value);
		confirmDialog.value = false;
		}
	};

	onMounted(async () => {
		await fetchWeatherData();
	});

	return {
		weatherData,
		weatherHeaders,
		selectedWeather,
		editDialog,
		confirmDialog,
		itemType,
		openEditWeatherDialog,
		openNewWeatherDialog,
		saveWeather,
		confirmDelete,
		proceedWithDelete,
		errorSnackbar,
		icons,
		daysOfWeek,
	};
	},
});
</script>
<style scoped>
</style>
