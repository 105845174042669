<template>
    <v-container class="cheatsheet">
      <!-- Header image -->
        <v-img
            src="@/assets/lsfdschriftzug.png"
            class="mb-5 centered-image"
            style="max-width: 1300px; max-height:100px;"
        ></v-img>
  
      <!-- Top row with two tables and a div -->
      <v-row>
        <!-- Table 1: Code and Bezeichnung -->
        <v-col cols="12" sm="4">
          <v-card>
            <v-data-table 
                :headers="headers" 
                :items="tencodes" 
                density="compact"
                :items-per-page="-1"
                class="custom-table-no-footer"
            >
                <template v-slot:bottom>
                    <div class="text-center pt-2">

                    </div>
                </template>
            </v-data-table>
          </v-card>
        </v-col>
  
        <!-- Div with image and text -->
        <v-col cols="12" sm="4" style="background-color:#212121">
                <v-img
                    src="@/assets/cheatsheet.png"
                    class="mb-5 centered-image"
                    style="max-width: 1300px; max-height:300px;"
                ></v-img>
          <span>Alpha = Straßenseite, wenn mehr Straßenseiten -> <br>
                Alpha = die mit dem leichteren Zugang.<br>
                Danach im Uhrzeigersinn Bravo, Charlie, Delta<br>
                Division X  = Stockwerk<br>
                Division 1 = Immer Erdgeschoss<br>
                Division 0 = Keller<br>
                Roof = Dach
            </span>
        </v-col>
  
        <!-- Table 2: Name and Bezeichnung -->
        <v-col cols="12" sm="4">
          <v-card>
            <v-data-table 
                :headers="headers" 
                :items="emergencyNumbers"
                density="compact"
                :items-per-page="-1"
                class="custom-table-no-footer"
            >
            <template v-slot:bottom>
                    <div class="text-center pt-2">

                    </div>
                </template></v-data-table>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- Bottom row with four tables -->
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-card>
            <v-data-table 
                :headers="headers" 
                :items="responseCodes"
                density="compact"
                :items-per-page="-1"
                class="custom-table-no-footer"
            ><template v-slot:bottom>
                    <div class="text-center pt-2">

                    </div>
                </template></v-data-table>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card>
            <v-data-table 
                :headers="headers" 
                :items="vehicles"
                density="compact"
                :items-per-page="-1"
                class="custom-table-no-footer"
            ><template v-slot:bottom>
                    <div class="text-center pt-2">

                    </div>
                </template></v-data-table>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card>
            <v-data-table 
                :headers="headers" 
                :items="workgroups"
                density="compact"
                :items-per-page="-1"
                class="custom-table-no-footer"
            ><template v-slot:bottom>
                    <div class="text-center pt-2">

                    </div>
                </template></v-data-table>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card class="mt-n10">
            <v-data-table 
                :headers="headers" 
                :items="nato"
                item-value="description"
                density="compact"
                :items-per-page="-1"
                class="custom-table-no-footer"
            > 
            <template v-slot:bottom>
                    <div class="text-center pt-2">

                    </div>
                </template> 
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from 'vue';
  
  export default defineComponent({
    setup() {
      const headers = [
        { text: 'Title', value: 'title' },
        { text: 'Description', value: 'description' },
      ];
  
        const tencodes = [
            { title: '10-3', description: 'Funkspruch wiederholen' },
            { title: '10-4', description: 'Verstanden' },
            { title: '10-6 | Check', description: 'Nicht Verfügbar / Pause' },
            { title: '10-7', description: 'Außer Dienst' },
            { title: '10-8 | Funkcheck', description: 'Im Dienst' },
            { title: '10-10', description: 'Bewergungsfahrt' },
            { title: '10-20', description: 'Standortabfrage / Angabe' },
            { title: '10-30', description: 'Transport Material / Personal' },
            { title: '10-40', description: 'Stiller Alarm' },
            { title: '10-44', description: 'Ambulance benötigt' },
            { title: '10-45', description: 'Code Blue' },
            { title: '10-60', description: 'Technischer Rettungseinsatz' },
            { title: '10-61', description: 'Verkehrsunfall' },
            { title: '10-75', description: 'Kleinbrand' },
            { title: '10-76', description: 'Großbrand' },
            { title: '10-80', description: 'Amtshilfe' },
            { title: '10-90', description: 'Naturkatastrophe' },
            { title: '10-91', description: 'Bombeneinsatz' },
            { title: '10-92', description: 'Hazmat inkl. Bestätigung' },
        ];

        const emergencyNumbers = [
            { title: 'SAPD', description: '911 | 921' },
            { title: 'LSMD', description: '912 | 922' },
            { title: 'LSFD', description: '913 | 923' },
            { title: 'SASP', description: '811 | 821' },
            { title: 'BCSO', description: '910 | 920' },
            { title: 'USMS', description: '550' },
            { title: 'DoJ StA', description: '511' },
            { title: 'DoJ Richter', description: '512' },
            { title: 'CASA', description: '611' },
            { title: 'Kanzlei', description: '623623' },
            { title: 'ACLS', description: '777777' },
            { title: 'Bennys', description: '200200' },
            { title: 'Flywheels', description: '200200' },
            { title: '2W1E', description: '686868' },
            { title: 'Eagle Motors', description: '666777' },
        ];

        const responseCodes = [
            { title: 'Code 1', description: 'Kein akuter Notfall' },
            { title: 'Code 2', description: 'Stille und schnellere Anfahrt' },
            { title: 'Code 3', description: 'Kritisch / Unbekannt' },
            { title: 'Code 4', description: 'Einsatz beendet' },
            { title: 'Code 6', description: 'Am Einsatzort angekommen' },
        ];

        const vehicles = [
            { title: 'Engine', description: 'Löscheinsätze, Verkewhrsunfälle, Innenangriff' },
            { title: 'Tower', description: 'Löscheinsätze, Innenangriff, Unterstützung Höhenrettung' },
            { title: 'Rescue', description: 'Taucheinsätze, Höhen und Tiefenrettung, Technische Hilfeleistung, Hazmat Einsätze' },
            { title: 'Fleet', description: 'Fleet3 = Flatbed, Fleet 1& 2 = Abschlepper' },
            { title: 'Firehawk', description: '3 = Löschheli, 1&2 = Rescue' },
        ];

        const workgroups = [
            { title: 'Führung/Leitung', description: 'Fire Chief, Assistant Chief' },
            { title: 'leit. Fachpersonal', description: 'Battalion Chief, General Secretary, Captain' },
            { title: 'Personal', description: 'Alle übrigen' },
            { title: 'Beamte', description: 'Jeder Angestellte' },
        ];

        const nato = [
            { title: 'Alpha', description: 'November' },
            { title: 'Bravo', description: 'Oscar' },
            { title: 'Charlie', description: 'Papa' },
            { title: 'Delta', description: 'Quebec' },
            { title: 'Echo', description: 'Romeo' },
            { title: 'Foxtrott', description: 'Sierra' },
            { title: 'Golf', description: 'Tango' },
            { title: 'Hotel', description: 'Uniform' },
            { title: 'India', description: 'Victor' },
            { title: 'Juliette', description: 'Whiskey' },
            { title: 'Kilo', description: 'X-Ray' },
            { title: 'Lima', description: 'Yankee' },
            { title: 'Mike', description: 'Zulu' },
        ];
  
      return {
        headers,
        tencodes,
        emergencyNumbers,
        responseCodes,
        vehicles,
        workgroups,
        nato
      };
    },
  });
  </script>
  
  <style>
    .cheatsheet .centered-image {
        display: block;
        margin: 0 auto;
    }
    .cheatsheet .v-table--density-compact > .v-table__wrapper > table > tbody > tr > td, .v-table--density-compact > .v-table__wrapper > table > thead > tr > td, .v-table--density-compact > .v-table__wrapper > table > tfoot > tr > td {
        height: calc(var(--v-table-row-height, 46px) - 21px);
    }
    .cheatsheet .v-table--density-compact > .v-table__wrapper > table > tbody > tr > th, .v-table--density-compact > .v-table__wrapper > table > thead > tr > th, .v-table--density-compact > .v-table__wrapper > table > tfoot > tr > th {
        height: calc(var(--v-table-header-height) - 51px);
    }
  </style>
  
  