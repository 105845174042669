import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openAddQuestionDialog()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Frage hinzufügen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.generateRandomTest())),
                  class: "ml-3"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Test ausgeben")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_data_table, {
          headers: _ctx.headers,
          items: _ctx.questionsAndAnswers,
          class: "elevation-1 mt-3"
        }, {
          top: _withCtx(() => [
            _createVNode(_component_v_toolbar, { flat: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Test")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider, {
                  class: "mx-4",
                  inset: "",
                  vertical: ""
                }),
                _createVNode(_component_v_spacer)
              ]),
              _: 1
            })
          ]),
          [`item.question`]: _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(item.question), 1)
          ]),
          [`item.isKO`]: _withCtx(({ item }) => [
            (item.isKO)
              ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.isKO == 'true' ? 'mdi-check-bold' : item.isKO != '' ? '' : ''), 1)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ]),
          [`item.answer1`]: _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(item.answer1) + " " + _toDisplayString(item.answer1_result == 'true' ? '🟢' : item.answer1 != '' ? '🔴' : ''), 1)
          ]),
          [`item.answer2`]: _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(item.answer2) + " " + _toDisplayString(item.answer2_result == 'true' ? '🟢' : item.answer2 != '' ? '🔴' : ''), 1)
          ]),
          [`item.answer3`]: _withCtx(({ item }) => [
            _createElementVNode("span", null, _toDisplayString(item.answer3) + " " + _toDisplayString(item.answer3_result == 'true' ? '🟢' : item.answer3 != '' ? '🔴' : ''), 1)
          ]),
          [`item.actions`]: _withCtx(({ item }) => [
            _createVNode(_component_v_icon, {
              size: "small",
              class: "me-2",
              onClick: ($event: any) => (_ctx.openDeleteConfirmationDialog(item.id))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" mdi-delete ")
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_v_icon, {
              size: "small",
              class: "me-2",
              onClick: ($event: any) => (_ctx.openEditQuestionDialog(item))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" mdi-pencil ")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1032, ["headers", "items"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.addQuestionDialog,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.addQuestionDialog) = $event)),
          width: "600"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Neue Frage hinzufügen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "addQuestionForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "9"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Frage",
                                  required: "",
                                  modelValue: _ctx.newQuestion.question,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newQuestion.question) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  label: "K.O. Frage?",
                                  modelValue: _ctx.newQuestion.isKO,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newQuestion.isKO) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "9"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Antwort 1",
                                  required: "",
                                  modelValue: _ctx.newQuestion.answer1,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newQuestion.answer1) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  label: "Richtig?",
                                  modelValue: _ctx.newQuestion.answer1_result,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newQuestion.answer1_result) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "9"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Antwort 2",
                                  required: "",
                                  modelValue: _ctx.newQuestion.answer2,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newQuestion.answer2) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  label: "Richtig?",
                                  modelValue: _ctx.newQuestion.answer2_result,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newQuestion.answer2_result) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "9"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Antwort 3",
                                  required: "",
                                  modelValue: _ctx.newQuestion.answer3,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.newQuestion.answer3) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  label: "Richtig?",
                                  modelValue: _ctx.newQuestion.answer3_result,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.newQuestion.answer3_result) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      text: "",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.addQuestionDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      text: "",
                      onClick: _ctx.submitNewQuestion
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Hinzufügen")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.deleteConfirmationDialog,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.deleteConfirmationDialog) = $event)),
          width: "500"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Frage löschen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Bist du sicher, dass du die Frage löschen willst? ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      text: "true",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.deleteConfirmationDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Nein")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "error",
                      text: "true",
                      onClick: _ctx.deleteQuestion
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Ja")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.editQuestionDialog,
          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editQuestionDialog) = $event)),
          width: "600"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Bearbeite Frage")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "editQuestionForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "9"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Frage",
                                  required: "",
                                  modelValue: _ctx.editingQuestion.question,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editingQuestion.question) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  label: "K.O. Frage?",
                                  modelValue: _ctx.editingQuestion.isKO,
                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editingQuestion.isKO) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "9"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Antwort 1",
                                  required: "",
                                  modelValue: _ctx.editingQuestion.answer1,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editingQuestion.answer1) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  label: "Richtig?",
                                  modelValue: _ctx.editingQuestion.answer1_result,
                                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editingQuestion.answer1_result) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "9"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Antwort 2",
                                  required: "",
                                  modelValue: _ctx.editingQuestion.answer2,
                                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editingQuestion.answer2) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  label: "Richtig?",
                                  modelValue: _ctx.editingQuestion.answer2_result,
                                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editingQuestion.answer2_result) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "9"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Antwort 3",
                                  required: "",
                                  modelValue: _ctx.editingQuestion.answer3,
                                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.editingQuestion.answer3) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_checkbox, {
                                  label: "Richtig?",
                                  modelValue: _ctx.editingQuestion.answer3_result,
                                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.editingQuestion.answer3_result) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      text: "",
                      onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.editQuestionDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      text: "",
                      onClick: _ctx.submitEditedQuestion
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Speichern")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        (_ctx.testQuestions.length > 0)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.copySolutionsWithoutUnicode())),
              class: "mat-6 mt-3"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Lösungen ohne Unicode kopieren")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.testQuestions.length > 0)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 1,
              onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.copySolutions())),
              class: "mat-6 ml-3 mt-3"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Lösungen kopieren")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.testQuestions.length > 0)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 2,
              onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.copyTest())),
              class: "mat-6 ml-3 mt-3"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Test kopieren")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.testQuestions.length > 0)
          ? (_openBlock(), _createBlock(_component_v_container, {
              key: 3,
              class: "pat-6 mt-3",
              style: {"background-color":"#212121"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.testQuestions, (question, index) => {
                  return (_openBlock(), _createBlock(_component_v_row, { key: index }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(index + 1) + ". " + _toDisplayString(question.question), 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.answers, (answer, aIndex) => {
                            return (_openBlock(), _createElementBlock("div", { key: aIndex }, [
                              (answer.result == '1')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "🟢"))
                                : (_openBlock(), _createElementBlock("span", _hoisted_2, "🔴")),
                              _createTextVNode(" " + _toDisplayString(answer.answer), 1)
                            ]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}