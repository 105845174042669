import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";
import { useStore } from "vuex";
import store from "@/store";
import { isAuthenticated, State, isTokenExpired } from "@/store";
import HomeView from "@/views/HomeView.vue";
import EmployeeView from "@/views/EmployeeView.vue";
import VacationView from "@/views/VacationView.vue";
import ApplicationView from "@/views/ApplicationView.vue";
import CalendarView from "@/views/CalendarView.vue";
import DocumentView from "@/views/DocumentView.vue";
import FireprotectionView from "@/views/FireprotectionView.vue";
import TemplateView from "@/views/TemplateView.vue";
import TodoView from "@/views/TodoView.vue";
import LoginView from "@/views/LoginView.vue";
import ProfileView from "@/views/ProfileView.vue";
import TrainingView from "@/views/TrainingView.vue";
import AdminUserView from "@/views/admin/UserView.vue";
import AdminApplicationView from "@/views/admin/ApplicationQuestionsView.vue";
import AdminRolesView from "@/views/admin/RoleView.vue"
import MapView from "@/views/MapView.vue";
import AdminSettingsView from "@/views/admin/SettingsView.vue";
import AdminEmployeeView from "@/views/admin/EmployeeView.vue";
import AdminTrainingView from "@/views/admin/TrainingView.vue";
import AdminMapView from "@/views/admin/MapView.vue";
import CheatsheetView from "@/views/CheatsheetView.vue";
import TestView from "@/views/TestView.vue";
import MapOnlyView from "@/views/MapOnlyView.vue";
import BlackboardView from "@/views/BlackboardView.vue";
import DispatchView from "@/views/DispatchView.vue";
import VehicleView from "@/views/VehicleView.vue";
import CrewView from "@/views/CrewView.vue";
import ReportView from "@/views/ReportView.vue";
import ReportCategorieView from "@/views/ReportCategorieView.vue";
import ReportTemplateView from "@/views/ReportTemplateView.vue";
import MessageView from "@/views/MessageView.vue";
import AdminMessagesView from "@/views/admin/MessagesView.vue"
import ReportCodeView from "@/views/ReportCodeView.vue";
import CompanyView from "@/views/CompanyView.vue";
import CompanyTypeView from "@/views/CompanyTypeView.vue";
import DashboardView from "@/views/DashboardView.vue";
import AdminWeatherView from "@/views/admin/WeatherView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "login",
        component: LoginView,
    },
    {
        path: "/home",
        name: "home",
        component: HomeView,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: DashboardView,
        meta: { requiresAuth: true },
    },
    {
        path: "/employee",
        name: "employee",
        component: EmployeeView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_EMPLOYEE'  },
    },
    {
        path: "/message",
        name: "message",
        component: MessageView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_MESSAGES'  },
    },
    {
        path: "/dispatch",
        name: "dispatch",
        component: DispatchView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DISPATCH'  },
    },
    {
        path: "/vehicle",
        name: "vehicle",
        component: VehicleView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_VEHICLE'  },
    },
    {
        path: "/reportcategory",
        name: "reportcategory",
        component: ReportCategorieView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_REPORTCATEGORY'  },
    },
    {
        path: "/reporttemplate",
        name: "reporttemplate",
        component: ReportTemplateView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_REPORTTEMPLATE'  },
    },
    {
        path: "/report",
        name: "report",
        component: ReportView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_REPORT'  },
    },
    {
        path: "/reportcode",
        name: "reportcode",
        component: ReportCodeView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_REPORTCODE'  },
    },
    {
        path: "/crew",
        name: "crew",
        component: CrewView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_CREW'  },
    },
    {
        path: "/vacation",
        name: "vacation",
        component: VacationView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_EMPLOYEE'  },
    },
    {
        path: "/trainingassign",
        name: "trainingassign",
        component: TrainingView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_TRAININGASSIGN'  },
    },
    {
        path: "/application",
        name: "application",
        component: ApplicationView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_APPLICATION'  },
    },
    {
        path: "/calendar",
        name: "calendar",
        component: CalendarView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_CALENDAR'  },
    },
    {
        path: "/document",
        name: "documents",
        component: DocumentView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DOCUMENT', docType: 'document'  },
    },
    {
        path: "/test",
        name: "tests",
        component: TestView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_TEST' },
    }, 
    {
        path: "/training",
        name: "trainings",
        component: DocumentView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DOCUMENT_TRAINING', docType: 'training'  },
    }, 
    {
        path: "/firedepartment",
        name: "firedepartment",
        component: DocumentView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DOCUMENT_FIREDEPARTMENT', docType: 'firedepartment'  },
    }, 
    {
        path: "/administration",
        name: "administration",
        component: DocumentView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DOCUMENT_ADMINISTRATION', docType: 'administration'  },
    }, 
    {
        path: "/fireprotection",
        name: "fireprotection",
        component: FireprotectionView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_FIREPROTECTION'  },
    },
    {
        path: "/company",
        name: "company",
        component: CompanyView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_COMPANY'  },
    },
    {
        path: "/template",
        name: "template",
        component: TemplateView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_TEMPLATE'  },
    },
    {
        path: "/blackboard/admin",
        name: "blackboardAdmin",
        component: BlackboardView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_BLACKBOARD_ADMIN', boardType: 'admin' },
    },
    {
        path: "/blackboard/employee",
        name: "blackboardEmployee",
        component: BlackboardView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_BLACKBOARD_EMPLOYEE', boardType: 'employee' },
    },
    {
        path: "/companytype",
        name: "companytype",
        component: CompanyTypeView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_COMPANYTYPE' },
    },
    {
        path: "/todo",
        name: "todo",
        component: TodoView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_TODO'  },
    },
    {
        path: '/profile',
        name: "profile",
        component: ProfileView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_ACCOUNT'  },
    },
    {
        path: '/admin/applicationquestions',
        name: "admin_applicationquestions",
        component: AdminApplicationView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_APPLICATION'  },
    },
    {
        path: '/admin/users',
        name: "admin_users",
        component: AdminUserView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_USERS'  },
    },
    {
        path: '/admin/roles',
        name: "admin_roles",
        component: AdminRolesView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_ROLES'  },
    },
    {
        path: '/admin/settings',
        name: "admin_settings",
        component: AdminSettingsView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_SETTINGS'  },
    },
    {
        path: '/admin/weather',
        name: "admin_weather",
        component: AdminWeatherView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_WEATHER'  },
    },
    {
        path: '/admin/employees',
        name: "admin_employee",
        component: AdminEmployeeView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_EMPLOYEE'  },
    },
    {
        path: '/admin/trainings',
        name: "admin_training",
        component: AdminTrainingView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_TRAINING'  },
    },
    {
        path: '/admin/map',
        name: "admin_map",
        component: AdminMapView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_MAP'  },
    },
    {
        path: '/admin/messages',
        name: "admin_messages",
        component: AdminMessagesView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_MESSAGES'  },
    },
    {
        path: '/map',
        name: "map",
        component: MapView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_MAP'  },
    },
    {
        path: '/mapOnly',
        name: "mapOnly",
        component: MapOnlyView,
        meta: { requiresAuth: false  },
    },
    {
        path: '/cheatsheet',
        name: "cheatsheet",
        component: CheatsheetView,
        meta: { requiresAuth: true  },
    }

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

import api from "@/api";

router.beforeEach(async (to, from, next) => {
    const isUserAuthenticated = isAuthenticated(store.state as State);
    const isExpired = isTokenExpired(store.state as State);

    if (isUserAuthenticated) {
        if (isExpired) {
          // Benutzer abmelden
            await store.dispatch("logout");
            // Benutzer zur Login-Seite umleiten
            if (to.name !== "login") {
                next({ name: "login" });
            } else {
                next();
            }
        } else {
          // Benutzer ist eingeloggt und versucht, auf die Login-Seite zuzugreifen,
          // leiten Sie ihn zur Startseite weiter
            if (to.name === "login") {
                next({ name: "dashboard" });
            } else {
                try {
                const response = await api.post("account/?action=checkUser");
                const { permissions, banned } = response.data;
        
                if (banned) {
                    store.dispatch("logout");
                    next({ name: "login" });
                } else {
                    store.commit("setPermissions", permissions);
        
                    if (to.meta.requiredPermission && (!store.state.user.permissions || (!store.state.user.permissions.includes(to.meta.requiredPermission) && !store.state.user.permissions.includes('ALL_PERMISSIONS')))) {
                    next({ name: "dashboard" });
                    } else {
                    next();
                    }
                }
                } catch (error) {
                console.error("Error fetching permissions:", error);
                next();
                }
            }
        }
    } else {
        if (to.meta.requiresAuth) {
          next({ name: "login" });
        } else {
          next();
        }
    }
});


export default router;
