import axios from 'axios';
import store from '@/store';

const api = axios.create({
    baseURL: 'https://system.kyuubiddragon.com/restapi/',
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

api.interceptors.request.use((config) => {
    const token = store.state.jwt;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default api;
