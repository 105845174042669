<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-btn @click="toggleShowRecentReturns(30)" class="ml-2">
                    Letzte 30 Tage
                </v-btn>
                <v-btn @click="toggleShowRecentReturns(7)" class="ml-2">
                    Letzte 7 Tage
                </v-btn>
                <v-btn @click="toggleShowVacations" class="ml-2">
                    Aktuell
                </v-btn>
                <v-btn @click="toggleShowRecentVacations(7)" class="ml-2">
                    Nächste 7 Tage
                </v-btn>
                <v-btn @click="toggleShowRecentVacations(30)" class="ml-2">
                    Nächste 30 Tage
                </v-btn>
            </v-col>
        </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            label="Search"
            dark
            outlined
            hide-details
            @input="updateFilteredItems"
          />
        </v-col>
      </v-row>
      
        <v-row>
            <template v-for="(rank, index) in ranksWithEmployee" :key="index"> 
                <VacationCard
                    v-for="(employee, employeeIndex) in rank.employees.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))"
                    :key="employeeIndex"
                    :member="employee"
                    :logoURL="rank.rankImage"
                    :companies="companies"
                    :departments="departments"
                    :ranks="ranks"
                    :licenses="licenses"
                    :showPreview="showPreview"
                    :showIsTerminated="showIsTerminated"
                    :showRecentReturns="showRecentReturns"
                    :showRecentVacations="showRecentVacations"
                    :filterdays="filterdays"
                >
                </VacationCard>
            </template>
        </v-row>
      

    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed, onMounted } from "vue";
  import VacationCard from "@/components/cards/VacationCard.vue";
  import { Rank, Company, Department, License } from "@/types/Members";
  import api from "@/api";
  import { JobTypes } from "@/types/Members";
  
  export default defineComponent({
        components: {
            VacationCard,
        },
        setup() {
            const errorSnackbar = ref<any>({ visible: false, message: "" });
            const headers = ref([
                { text: "Name", value: "name" },
                { text: "Phone", value: "phone" },
            ]);
            const showPreview = ref(false);
            const search = ref("");
            const sortBy = ref({ text: "Name", value: "name" });
            const companies = ref<Company[]>([]);
            const departments = ref<Department[]>([]);
            const ranks = ref<Rank[]>([]);
            const licenses = ref<License[]>([]);
            const employeeFormRef = ref(null);
            const showSortingModal = ref(false);
            const showIsTerminated = ref(false);
            const filterdays = ref(7);

            const editedValue = ref('');
            const editingField = ref({ type: '', id: -1 });

            const addRankDialog = ref(false);
            const newRank = ref({
                name: "",
                department: 1,
            });

            const newEmployee = ref({
                name: '',
                personalid: '',
                phonenumber: '',
                mail: '',
                servicenumber: '',
                entrydate: '',
                bankaccount: '',
                leavedate: '',
                sidejob: '',
                company: [],
                department: [],
                rank: '',
                license: [],
                companies: [],
                departments: [],
                licenses: [],
                is_terminated: false,
                // Fügen Sie hier weitere Felder hinzu, falls erforderlich
            });

            const showRecentReturns = ref(false);
            const showRecentVacations = ref(false);


            const JobTypes = ref<JobTypes[]>([]);
            async function fetchJobTypes() {
                try {
                    const response = await api.get('employee/index_fetch.php?action=getJobTypes');
                    JobTypes.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                    JobTypes.value = [];
                }
            }
        
            onMounted(async () => {
                await fetchJobTypes();
                await fetchEmployee();
                await fetchCompanies();
                await fetchDepartments();
                await fetchRanks();
                await fetchLicenses();
            });

            const idsToArray = (obj) => {
                return Object.keys(obj).map((key) => {
                    return key;
                });
            };



            const saveCategorySorting = async () => {
                try {
                    console.log("Sending category data:", ranks.value);
                    const response = await api.post("employee/?action=saveCategorySorting", JSON.stringify(ranks.value));

                    if (response.data.success) {
                        console.log("Category sorting saved successfully");
                        fetchEmployee();
                        fetchRanks();
                    } else {
                    console.error("Failed to save category sorting:", response.data.error);
                    }
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }

                showSortingModal.value = false;
            };

            function toggleShowRecentReturns(days:number) {
                showRecentReturns.value = true;
                showRecentVacations.value = false;
                filterdays.value = days;
            }

            function toggleShowVacations() {
                showRecentReturns.value = false;
                showRecentVacations.value = false;
            }

            function toggleShowRecentVacations(days:number) {
                showRecentReturns.value = false;
                showRecentVacations.value = true;
                filterdays.value = days;
            }

            const employeeData = ref<Rank[]>([]);
            const selectedDepartment = ref(1);

            const ranksWithEmployee = computed(() => {
                const currentDate = new Date();  // das heutige Datum
                currentDate.setHours(0, 0, 0, 0);  // setzen Sie die Stunden, Minuten, Sekunden und Millisekunden auf 0, um nur das Datum zu vergleichen
                
                if (selectedDepartment.value == 0) {
                    return employeeData.value.map(rank => ({
                    ...rank,
                    employees: rank.employees.filter(employee => employee.is_terminated == false)
                    }));
                } else {
                    if(showIsTerminated.value){
                    return employeeData.value
                        .filter(rank => rank.jobrole_id == selectedDepartment.value)
                        .map(rank => ({
                            ...rank,
                            employees: rank.employees.filter(employee => {
                                const leaveDate = new Date(employee.leavedate);  // das Leave-Datum des Mitarbeiters
                                leaveDate.setHours(0, 0, 0, 0);  // setzen Sie die Stunden, Minuten, Sekunden und Millisekunden auf 0, um nur das Datum zu vergleichen
                                return employee.is_terminated == true && currentDate > leaveDate;
                            })
                        }));
                    } else {
                    return employeeData.value
                        .map(rank => ({
                        ...rank,
                        employees: rank.employees.filter(employee => {
                                const leaveDate = new Date(employee.leavedate);  // das Leave-Datum des Mitarbeiters
                                leaveDate.setHours(0, 0, 0, 0);  // setzen Sie die Stunden, Minuten, Sekunden und Millisekunden auf 0, um nur das Datum zu vergleichen
                                return employee.is_terminated == false || currentDate <= leaveDate;
                            })
                        }));
                    }
                }
            });






            const openEditForm = (data) => {
                console.log(data)
                if (data != null) {
                    data.employee.company = data.employee.companies ? idsToArray(data.employee.companies) : [];
                    data.employee.department = data.employee.departments ? idsToArray(data.employee.departments) : [];
                    data.employee.license = data.employee.licenses ? idsToArray(data.employee.licenses) : [];
                    employeeFormRef.value.openDialog(data.employee, false);
                    employeeFormRef.value.companies.value = companies.value;
                    employeeFormRef.value.departments.value = departments.value;
                    employeeFormRef.value.ranks.value = ranks.value;
                    employeeFormRef.value.licenses.value = licenses.value;
                } else {
                    employeeFormRef.value.openDialog(newEmployee, true);
                }
            };

            const updateNotes = (data) => {
                if (data != null) {
                    console.log(data.id);
                    console.log(data.notes);

                    ranksWithEmployee.value.forEach(ranksWithEmployee => {
                        ranksWithEmployee.employees.forEach(employee => {
                            if(employee.id == data.id){
                                employee.notes = data.notes;
                            }
                        })
                    })                    
                }
            }


            const startEditingField = (fieldType: string, fieldId: number) => {
                editingField.value = { type: fieldType, id: fieldId };
                if (fieldType === 'rankName' && ranksWithEmployee.value) {
                    const ranks = ranksWithEmployee.value.find(rank => rank.id === fieldId);
                    if (ranks) {
                        editedValue.value = ranks.name;
                    }
                }
                // Füge hier weitere Bedingungen für andere Feldtypen hinzu
            };

            const cancelEditingField = () => {
                editingField.value = { type: '', id: -1 };
            };

            async function updateField(fieldId: number) {
                if (editedValue.value.trim() === '') return;

                if (editingField.value.type === 'rankName' && ranksWithEmployee.value) {
                    try {
                        const ranks = ranksWithEmployee.value.find(rank => rank.id === fieldId);
                        if (!ranks) {
                            return;
                        }

                        await api.post('employee/?action=updateRankName', {
                            id: fieldId,
                            name: editedValue.value,
                        });

                        ranks.name = editedValue.value;
                    } catch (error) {
                        errorSnackbar.value.message = error.response.data.error;
                        errorSnackbar.value.visible  = true;
                    }
                }

                // Füge hier weitere Bedingungen für andere Feldtypen hinzu

                cancelEditingField(); // Schließt das bearbeitete Feld nach der Aktualisierung
            }




            async function fetchCompanies() {
                try {
                    const response = await api.post('employee/?action=getCompanies');
                    companies.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }

            async function fetchDepartments() {
                try {
                    const response = await api.post('employee/?action=getDepartments');
                    departments.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }

            async function fetchRanks() {
                try {
                    const response = await api.post('employee/?action=getRanks');
                    ranks.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }

            async function fetchLicenses() {
                try {
                    const response = await api.post('employee/?action=getLicenses');
                    licenses.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
        
            async function fetchEmployee() {
                try {
                const response = await api.post("employee/?action=getEmployee");
                employeeData.value = response.data;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
        
            const updateFilteredItems = () => {
                // No need to implement this function since filtering is now done in the template
            };

            function openAddRankDialog() {
                addRankDialog.value = true;
            }

            async function addNewRank() {
                try {
                    await api.post("employee/?action=addRank", newRank.value);
                    fetchRanks(); // Refresh Ranks after adding the new template+
                    fetchEmployee();
                    addRankDialog.value = false;
                    newRank.value.name = "";
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }

            function filterByDepartment(department: number) {
                selectedDepartment.value = department;
            }


            const deleteRankDialog = ref(false);
            const rankToDelete = ref(null);

            const openDeleteRankDialog = (rank) => {
                rankToDelete.value = rank;
                deleteRankDialog.value = true;
            };
            const deleteRank = async () => {
                try {
                    const response = await api.post("employee/?action=deleteRank", { id: rankToDelete.value.id });
                    
                    if (response.data.success) {
                    // Aktualisieren Sie die Rangliste, nachdem der Rang erfolgreich gelöscht wurde
                        fetchRanks();
                        employeeData.value = employeeData.value.filter(rank => rank.id !== rankToDelete.value.id);
                    } else {
                    // Fehlerbehandlung, falls das Löschen des Rangs nicht erfolgreich war
                    console.error("Error deleting rank:", response.data.message);
                    }

                    deleteRankDialog.value = false;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            };



        
            return {
                headers,
                search,
                sortBy,
                updateFilteredItems,
                companies,
                departments,
                ranks,
                licenses,
                ranksWithEmployee,
                openEditForm,
                employeeFormRef,
                fetchEmployee,
                editingField,
                editedValue,
                startEditingField,
                cancelEditingField,
                updateField,
                showSortingModal,
                saveCategorySorting,
                newRank, 
                addRankDialog,
                openAddRankDialog,
                addNewRank,
                selectedDepartment,
                filterByDepartment,
                showIsTerminated,
                JobTypes,
                deleteRankDialog,
                rankToDelete,
                openDeleteRankDialog,
                deleteRank,
                errorSnackbar,
                showPreview,
                updateNotes,
                showRecentReturns,
                toggleShowRecentReturns,
                showRecentVacations,
                toggleShowRecentVacations,
                toggleShowVacations,
                filterdays
            };
        },
  });
  </script>
  