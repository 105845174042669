<template>
    <v-container>
      <v-data-table :headers="categoryHeaders" :items="categories" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Kategorien verwalten</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn @click="openNewCategoryDialog">Neue Kategorie</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon size="small" class="me-2" @click="openEditCategoryDialog(item)">mdi-pencil</v-icon>
          <v-icon size="small" class="me-2" @click="openDeleteCategoryDialog(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
  
      <!-- New Category Dialog -->
      <v-dialog v-model="newCategoryDialog" max-width="60%">
        <v-card>
          <v-card-title>
            Neue Abteilung
          </v-card-title>
          <v-card-text>
            <v-form ref="newCategoryForm">
              <v-text-field label="Name" v-model="newCategory.name" required :rules="[requiredRule]"></v-text-field>
              <v-text-field label="Titel" v-model="newCategory.title" required :rules="[requiredRule]"></v-text-field> <!-- Neuer Titel-Input -->
              <ckeditor :editor="EditorClass" :config="EditorClass.defaultConfig" v-model="newCategory.template"></ckeditor>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="newCategoryDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="addNewCategory" :disabled="!isFormValid(newCategory)">Hinzufügen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Edit Category Dialog -->
      <v-dialog v-model="editCategoryDialog" max-width="60%">
        <v-card>
          <v-card-title>
            Abteilung bearbeiten
          </v-card-title>
          <v-card-text>
            <v-form ref="editCategoryForm">
              <v-text-field label="Name" v-model="editedCategory.name" required :rules="[requiredRule]"></v-text-field>
              <v-text-field label="Titel" v-model="editedCategory.title" required :rules="[requiredRule]"></v-text-field> <!-- Neuer Titel-Input -->
              <ckeditor :editor="EditorClass" :config="EditorClass.defaultConfig" v-model="editedCategory.template"></ckeditor>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editCategoryDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="updateCategory">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Delete Category Dialog -->
      <v-dialog v-model="deleteCategoryDialog" max-width="400" persistent>
        <v-card>
          <v-card-title class="headline">Abteilung löschen</v-card-title>
          <v-card-text>
            Möchten Sie die Abteilung "{{ selectedCategory?.name }}" wirklich löschen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteCategoryDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="deleteCategory(selectedCategory)">Löschen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Snackbar -->
      <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
        {{ snackbar.message }}
      </v-snackbar>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import api from '@/api';
  import { Category } from '@/types/Report';
  import EditorClass from '@/ckeditor/ckeditor.js';
  
  export default defineComponent({
    setup() {
      const errorSnackbar = ref<any>({ visible: false, message: '' });
      const categoryHeaders = [
        { title: 'Name', align: 'start', sortable: false, key: 'name' },
        { title: 'Titel', align: 'start', sortable: false, key: 'title' }, // Neuer Titel-Header
        { title: 'Aktionen', key: 'actions', sortable: false }
      ];
  
      const categories = ref<Category[]>([]);
      const newCategoryDialog = ref(false);
      const editCategoryDialog = ref(false);
      const deleteCategoryDialog = ref(false);
      const selectedCategory = ref<Category | null>(null);
  
      const newCategory = ref({
        name: '',
        title: '', // Neuer Titel
        template: ''
      });
  
      const editedCategory = ref<Category>({
        id: 0,
        name: '',
        title: '', // Neuer Titel
        template: ''
      });
  
      const snackbar = ref({
        value: false,
        color: '',
        message: ''
      });
  
      const requiredRule = (value: string) => !!value || 'Dieses Feld ist erforderlich.';
  
      const fetchcategories = async () => {
        try {
          const response = await api.get('report/?action=getCategories');
          categories.value = response.data.map((dep: Category) => ({
            ...dep,
            template: dep.template || ''
          }));
        } catch (error) {
          errorSnackbar.value.message = error.response.data.error;
          errorSnackbar.value.visible = true;
        }
      };
  
      const addNewCategory = async () => {
        try {
          const response = await api.post('report/?action=addCategory', newCategory.value);
          await fetchcategories();
          newCategoryDialog.value = false;
          newCategory.value = { name: '', title: '', template: '' }; // Reset
        } catch (error) {
          errorSnackbar.value.message = error.response.data.error;
          errorSnackbar.value.visible = true;
        }
      };
  
      const openEditCategoryDialog = (category: Category) => {
        editedCategory.value = { ...category };
        editCategoryDialog.value = true;
      };
  
      const updateCategory = async () => {
        try {
          const response = await api.post('report/?action=editCategory', editedCategory.value);
          await fetchcategories();
          editCategoryDialog.value = false;
        } catch (error) {
          errorSnackbar.value.message = error.response.data.error;
          errorSnackbar.value.visible = true;
        }
      };
  
      const openDeleteCategoryDialog = (category: Category) => {
        selectedCategory.value = category;
        deleteCategoryDialog.value = true;
      };
  
      const deleteCategory = async (category: Category) => {
        try {
          const response = await api.post('report/?action=deleteCategory', { id: category.id });
          await fetchcategories();
          deleteCategoryDialog.value = false;
        } catch (error) {
          errorSnackbar.value.message = error.response.data.error;
          errorSnackbar.value.visible = true;
        }
      };
  
      function isFormValid(form: { name: string; title: string; template: string }) {
        return form.name !== '' && form.title !== '' && form.template !== '';
      }
  
      const openNewCategoryDialog = () => {
        newCategory.value = { name: '', title: '', template: '' };
        newCategoryDialog.value = true;
      };
  
      onMounted(async () => {
        await fetchcategories();
      });
  
      return {
        categoryHeaders,
        categories,
        newCategoryDialog,
        newCategory,
        addNewCategory,
        requiredRule,
        editCategoryDialog,
        editedCategory,
        updateCategory,
        deleteCategoryDialog,
        selectedCategory,
        deleteCategory,
        openEditCategoryDialog,
        openDeleteCategoryDialog,
        isFormValid,
        errorSnackbar,
        snackbar,
        EditorClass,
        openNewCategoryDialog
      };
    }
  });
  </script>
  
  <style scoped>
  </style>
  