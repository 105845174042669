<template>
    <v-container fluid>
        <v-row>
            <!-- Navigation Drawer -->
            <v-navigation-drawer app rail permanent>
                <v-list dense>
                    <v-list-item
                        link
                        @click="viewInbox"
                        :active="view == 'inbox'"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-inbox</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item
                        link
                        @click="viewSent"
                        :active="view == 'sent'"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-send</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item
                        link
                        @click="viewFolders"
                        :active="view == 'folders'"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-folder</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item
                        link
                        @click="viewDeleted"
                        :active="view == 'deleted'"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-delete</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>

            <v-main style="padding-left: 30px; padding-right: 30px">
                <v-btn
                    v-if="view !== 'folders'"
                    @click="openNewMessageDialog"
                    class="float-right mb-3"
                    >Neue Nachricht</v-btn
                >

                <!-- Group and Personal Messages Buttons -->
                <v-toolbar v-if="view !== 'folders'" flat>
                    <v-btn
                        @click="filterMessagesByGroup(null)"
                        :active="selectedGroup == null ? true : false"
                    >
                        Eigene Nachrichten
                        {{
                            view == "inbox"
                                ? "(" + getMessagesCountByGroup(null) + ")"
                                : ""
                        }}
                    </v-btn>
                    <v-btn
                        v-for="group in filteredGroups"
                        :key="group.id"
                        @click="filterMessagesByGroup(group.id)"
                        :active="selectedGroup == group.id ? true : false"
                    >
                        {{ group.name }}
                        {{
                            view == "inbox"
                                ? "(" + getMessagesCountByGroup(group.id) + ")"
                                : ""
                        }}
                    </v-btn>
                </v-toolbar>

                <v-data-table
                    v-if="view !== 'folders'"
                    :headers="messageHeaders"
                    :items="filteredMessages"
                    class="elevation-1 mt-2"
                    :search="search"
                    :items-per-page="25"
                    :item-class="getMessageClass"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{
                                view == "inbox"
                                    ? "Posteingang"
                                    : view == "sent"
                                    ? "Postausgang"
                                    : view == "deleted"
                                    ? "Gelöschte Nachrichten"
                                    : "Nachrichtenverwaltung"
                            }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-select
                                v-model="filterFolderSearch"
                                :items="filteredFolders"
                                item-title="name"
                                item-value="id"
                                label="Filter nach Ordner"
                                clearable
                                variant="underlined"
                                class="mx-4 mt-3"
                            />
                            <v-text-field
                                v-model="search"
                                label="Suche"
                                class="mx-4 mt-3"
                                variant="underlined"
                            />
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip
                            :text="
                                item.is_read
                                    ? 'Als ungelesen markieren'
                                    : 'Als gelesen markieren'
                            "
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-if="view == 'inbox'"
                                    size="small"
                                    class="me-2"
                                    @click="markMessageAsRead(item)"
                                    v-bind="props"
                                    >{{
                                        item.is_read
                                            ? "mdi-eye-off"
                                            : "mdi-eye"
                                    }}</v-icon
                                >
                            </template>
                        </v-tooltip>
                        <v-tooltip
                            :text="item.pinned ? 'Entpinnen' : 'Anpinnen'"
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-if="view == 'inbox'"
                                    size="small"
                                    class="me-2"
                                    @click="markMessageAsPinned(item)"
                                    v-bind="props"
                                    >{{
                                        item.pinned
                                            ? "mdi-pin-off"
                                            : "mdi-pin"
                                    }}</v-icon
                                >
                            </template>
                        </v-tooltip>
                        <v-icon
                            v-if="view == 'inbox'"
                            size="small"
                            class="me-2"
                            @click="openDeleteMessageDialog(item)"
                            >mdi-delete</v-icon
                        >
                    </template>
                    <template v-slot:[`item.is_read`]="{ item }">
                        {{
                            item.pinned == 1 && view == "inbox" ? "📌 " : ""
                        }}{{
                            view == "inbox"
                                ? item.is_read == 1
                                    ? "🟢"
                                    : "🔴"
                                : ""
                        }}
                    </template>
                    <template v-slot:[`item.title`]="{ item }">
                        <span
                            @click="openReadMessageDialog(item)"
                            style="cursor: pointer"
                            >{{ item.title }}</span
                        >
                    </template>
                    <template v-slot:[`item.folder_name`]="{ item }">
                        {{
                            view == "inbox"
                                ? item.recipient_folder_name
                                : item.sender_folder_name
                        }}
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        {{ formatDate(item.created_at) }}
                    </template>
                    <template v-slot:[`item.body`]> </template>
                </v-data-table>

                <!-- New Message Dialog -->
                <v-dialog v-model="newMessageDialog" max-width="60%">
                    <v-card>
                        <v-card-title>Neue Nachricht</v-card-title>
                        <v-card-text>
                            <v-form ref="newMessageForm">
                                <v-select
                                    label="Absender"
                                    v-model="newMessage.sender_id"
                                    :items="senderOptions"
                                    item-title="label"
                                    item-value="id"
                                    required
                                ></v-select>
                                <v-autocomplete
                                    label="Empfänger"
                                    multiple
                                    v-model="newMessage.recipient_id"
                                    :items="recipientOptions"
                                    item-title="label"
                                    item-value="id"
                                    :rules="[requiredRule]"
                                    clearable
                                    chips
                                    closable-chips
                                ></v-autocomplete>
                                <v-text-field
                                    label="Betreff"
                                    v-model="newMessage.title"
                                    required
                                    :rules="[requiredRule]"
                                ></v-text-field>
                                <ckeditor
                                    :editor="EditorClass"
                                    :config="EditorClass.defaultConfig"
                                    v-model="newMessage.body"
                                    required
                                    :rules="[requiredRule]"
                                ></ckeditor>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="newMessageDialog = false"
                                >Abbrechen</v-btn
                            >
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="addNewMessage"
                                :disabled="!isFormValid(newMessage)"
                                >Hinzufügen</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- Read Message Dialog -->
                <v-dialog
                    v-model="readMessageDialog"
                    class="readMessageDialog"
                    persistent
                    fullscreen
                >
                    <v-card>
                        <v-card-title>Nachricht lesen</v-card-title>
                        <v-card-text>
                            <v-form ref="readMessageForm">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <div class="mb-4">
                                                <strong>Absender:</strong>
                                                <p>
                                                    {{
                                                        readMessage
                                                            .sender_name
                                                    }}
                                                </p>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <div class="mb-4">
                                                <strong>Empfänger:</strong>
                                                <p>
                                                    {{
                                                        readMessage
                                                            .recipient_name
                                                    }}
                                                </p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-select
                                                v-if="view == 'inbox'"
                                                label="Ordner"
                                                v-model="
                                                    readMessage
                                                        .recipient_folder_id
                                                "
                                                :items="filteredFolders"
                                                item-title="name"
                                                item-value="id"
                                                clearable
                                            ></v-select>
                                            <v-select
                                                v-else-if="view == 'sent'"
                                                label="Ordner"
                                                v-model="
                                                    readMessage.sender_folder_id
                                                "
                                                :items="filteredFolders"
                                                item-title="name"
                                                item-value="id"
                                                clearable
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <strong>Text:</strong>
                                            <div
                                                class="message-body"
                                                v-html="readMessage.body"
                                            ></div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" v-if="view == 'inbox'">
                                            <v-textarea
                                                label="Notiz"
                                                v-model="
                                                    readMessage.recipient_note
                                                "
                                            ></v-textarea>
                                        </v-col>
                                        <v-col cols="12" v-if="view == 'sent'">
                                            <v-textarea
                                                label="Notiz"
                                                v-model="
                                                    readMessage.sender_note
                                                "
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="replyMessage(readMessage)"
                                >Antworten</v-btn
                            >
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="forwardMessage(readMessage)"
                                >Weiterleiten</v-btn
                            >
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="updateMessage"
                                >Aktualisieren</v-btn
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="readMessageDialog = false"
                                >Schließen</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- Delete Message Dialog -->
                <v-dialog
                    v-model="deleteMessageDialog"
                    max-width="400"
                    persistent
                >
                    <v-card>
                        <v-card-title class="headline"
                            >Nachricht löschen</v-card-title
                        >
                        <v-card-text>
                            Möchten Sie die Nachricht "{{
                                selectedMessage?.title
                            }}" wirklich löschen?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteMessageDialog = false"
                                >Abbrechen</v-btn
                            >
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteMessage(selectedMessage)"
                                >Löschen</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- New Folder Dialog -->
                <v-dialog v-model="newFolderDialog" max-width="600">
                    <v-card>
                        <v-card-title>Neuen Ordner erstellen</v-card-title>
                        <v-card-text>
                            <v-form ref="newFolderForm">
                                <v-select
                                    label="Postfach"
                                    v-model="newFolderCategory"
                                    :items="senderOptions"
                                    item-title="label"
                                    item-value="id"
                                    required
                                ></v-select>
                                <v-text-field
                                    label="Ordnername"
                                    v-model="newFolderName"
                                    required
                                ></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="newFolderDialog = false"
                                >Abbrechen</v-btn
                            >
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="addNewFolder"
                                >Hinzufügen</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- Folders View -->
                <div v-if="view === 'folders'">
                    <v-btn @click="openNewFolderDialog" class="float-right mb-3"
                        >Neuen Ordner erstellen</v-btn
                    >

                    <v-toolbar flat>
                        <v-btn
                            @click="filterFoldersByGroup(null)"
                            :active="selectedGroup == null ? true : false"
                        >
                            Eigene Ordner
                        </v-btn>
                        <v-btn
                            v-for="group in filteredGroups"
                            :key="group.id"
                            @click="filterFoldersByGroup(group.id)"
                            :active="selectedGroup == group.id ? true : false"
                        >
                            {{ group.name }}
                        </v-btn>
                    </v-toolbar>
                    <v-data-table
                        :headers="folderHeaders"
                        :items="filteredFolders"
                        class="elevation-1 mt-2"
                        :search="search"
                        :items-per-page="25"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title
                                    >Ordnerverwaltung</v-toolbar-title
                                >
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="search"
                                    label="Suche"
                                    class="mx-4 mt-3"
                                    variant="underlined"
                                />
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                size="small"
                                class="me-2"
                                @click="editFolder(item)"
                                >mdi-pencil</v-icon
                            >
                            <v-icon
                                size="small"
                                class="me-2"
                                @click="deleteFolder(item.id)"
                                >mdi-delete</v-icon
                            >
                        </template>
                    </v-data-table>
                </div>
            </v-main>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import api from '@/api';
import { useStore } from 'vuex';
import { User, Group, Message, Folder } from '@/types/Message';
import EditorClass from "@/ckeditor/ckeditor.js";

export default defineComponent({
    setup() {
        const messageHeaders = [
            { title: "", align: "start", sortable: false, key: "is_read" },
            { title: "Betreff", align: "start", sortable: false, key: "title" },
            { title: "", align: "start", sortable: false, key: "body" },
            {
                title: "Absender",
                align: "start",
                sortable: false,
                key: "sender_name",
            },
            {
                title: "Empfänger",
                align: "start",
                sortable: false,
                key: "recipient_name",
            },
            {
                title: "Ordner",
                align: "start",
                sortable: false,
                key: "folder_name",
            },
            {
                title: "Datum/Uhrzeit",
                align: "start",
                sortable: false,
                key: "created_at",
            },
            { title: "Aktionen", key: "actions", sortable: false },
        ];

        const folderHeaders = [
            { text: "Name", align: "start", sortable: false, key: "name" },
            { text: "Aktionen", key: "actions", sortable: false },
        ];

        const messages = ref<Message[]>([]);
        const users = ref<User[]>([]);
        const groups = ref<Group[]>([]);
        const folders = ref<Folder[]>([]);
        const search = ref("");
        const filterFolderSearch = ref();
        const newMessageDialog = ref(false);
        const readMessageDialog = ref(false);
        const deleteMessageDialog = ref(false);
        const selectedMessage = ref<Message | null>(null);
        const selectedGroup = ref<number | null>(null);
        const view = ref("inbox");
		const store = useStore();


		const socket = ref<WebSocket | null>(null);

		const setupWebSocket = () => {
			const token = store.state.jwt;
			if (!token) return;

			socket.value = new WebSocket(`wss://system.kyuubiddragon.com/restapi/ws/?token=${token}`);

			socket.value.onopen = () => {
				console.log('WebSocket connected');
			};

			socket.value.onmessage = (event) => {
				const data = JSON.parse(event.data);
                if (data.type === 'update' && data.site === 'message') {
                    fetchMessages();
                }
				console.log('WebSocket message received:', data);
				// Sie können hier einen globalen Event-Bus oder Zustand verwenden,
				// um Nachrichten an bestimmte Komponenten zu senden.
			};

			socket.value.onerror = (error) => {
				console.error('WebSocket error:', error);
			};

			socket.value.onclose = () => {
				console.log('WebSocket disconnected');
				// Automatically attempt to reconnect after 5 seconds
				setTimeout(setupWebSocket, 5000);
			};
		};

        const currentUserId = ref<number | null>(null);

        const newMessage = ref<Message>({
            title: "",
            body: "",
            sender_id: "",
            sender_type: "",
            recipient_id: null,
            recipient_type: "",
            sender_folder_id: null,
            recipient_folder_id: null,
            group_id: null,
            is_anonymous: false,
            is_read: false,
            read_at: null,
            sender_note: "",
            recipient_note: "",
            sender_name: "",
            recipient_name: "",
            deleted_by_sender: false,
            deleted_by_recipient: false,
            pinned: false,
        });

        const readMessage = ref<Message>({
            id: 0,
            title: "",
            body: "",
            sender_id: "",
            sender_type: "",
            sender_name: "",
            recipient_id: 0,
            recipient_type: "",
            recipient_name: "",
            sender_folder_id: null,
            recipient_folder_id: null,
            group_id: null,
            is_anonymous: false,
            is_read: false,
            read_at: null,
            sender_note: "",
            recipient_note: "",
            deleted_by_sender: false,
            deleted_by_recipient: false,
            pinned: false,
        });

        const snackbar = ref({
            value: false,
            color: "",
            message: "",
        });

        const requiredRule = (value: string) =>
            !!value || "Dieses Feld ist erforderlich.";

        const newFolderDialog = ref(false);
        const newFolderName = ref("");
        const newFolderCategory = ref("");

        const fetchMessages = async () => {
            try {
                const response = await api.get("message/?action=getMessages");
                messages.value = response.data;
            } catch (error) {
                snackbar.value.message = error.response.data.error;
                snackbar.value.color = "error";
                snackbar.value.value = true;
            }
        };

        const fetchUsers = async () => {
            try {
                const response = await api.get("message/?action=getUsers");
                users.value = response.data;
                currentUserId.value =
                    response.data.find((user) => user.is_current_user)?.id ||
                    null;
            } catch (error) {
                snackbar.value.message = error.response.data.error;
                snackbar.value.color = "error";
                snackbar.value.value = true;
            }
        };

        const fetchGroups = async () => {
            try {
                const response = await api.get("message/?action=getGroups");
                groups.value = response.data;
            } catch (error) {
                snackbar.value.message = error.response.data.error;
                snackbar.value.color = "error";
                snackbar.value.value = true;
            }
        };

        const fetchFolders = async () => {
            try {
                const response = await api.get("message/?action=getFolders");
                folders.value = response.data;
            } catch (error) {
                snackbar.value.message = error.response.data.error;
                snackbar.value.color = "error";
                snackbar.value.value = true;
            }
        };

        const addNewMessage = async () => {
            try {
                const payload = {
                    ...newMessage.value,
                    sender_type:
                        typeof newMessage.value.sender_id === "string" &&
                        newMessage.value.sender_id.startsWith("user_")
                            ? "user"
                            : "group",
                    recipient_type:
                        typeof newMessage.value.recipient_id === "string" &&
                        newMessage.value.recipient_id.startsWith("user_")
                            ? "user"
                            : "group",
                    jwt: store.state.jwt,
                };
                let response = await api.post("message/?action=addMessage", payload);
                if (response.data.success) {
                    await fetchMessages();
                    newMessageDialog.value = false;
                    newMessage.value = {
                        title: "",
                        body: "",
                        sender_id:
                            currentUserId.value && selectedGroup.value == null
                                ? `user_${currentUserId.value}`
                                : `group_${selectedGroup.value}`,
                        sender_type: "",
                        recipient_id: null,
                        recipient_type: "",
                        sender_folder_id: null,
                        recipient_folder_id: null,
                        group_id: null,
                        is_anonymous: false,
                        is_read: false,
                        read_at: null,
                        sender_note: "",
                        recipient_note: "",
                        sender_name: "",
                        recipient_name: "",
                        deleted_by_sender: false,
                        deleted_by_recipient: false,
                        pinned: false,
                    };

                    /*if (socket.value) {
                        const wsMessage = {
                            message_id: response.data.message_id,
                            site: 'message',
                            type: 'update'
                        };
                        socket.value.send(JSON.stringify(wsMessage));
                    }*/
                }
            } catch (error) {
                snackbar.value.message = error.response.data.error;
                snackbar.value.color = "error";
                snackbar.value.value = true;
            }
        };


        const sendWebSocketMessage = (message) => {
            if (!socket.value) {
                setupWebSocket();
            }
            socket.value.send(JSON.stringify(message));
        };

        const openReadMessageDialog = (message: Message) => {
            if (!message.is_read) markMessageAsRead(message);
            readMessage.value = {
                ...message,
                sender_name: message.sender_name,
                recipient_name: message.recipient_name,
            };
            readMessageDialog.value = true;
        };

        const updateMessage = async () => {
            try {
                await api.post(
                    "message/?action=updateMessage",
                    readMessage.value
                );
                await fetchMessages();
                readMessageDialog.value = false;
            } catch (error) {
                snackbar.value.message = error.response.data.error;
                snackbar.value.color = "error";
                snackbar.value.value = true;
            }
        };

        const replyMessage = (message: Message) => {
            newMessage.value = {
                title: `Re: ${message.title}`,
                body: `<br><br>--- Ursprüngliche Nachricht ---<br>Von: ${message.sender_name}<br>Gesendet: ${message.read_at}<br><br>${message.body}`,
                sender_id: currentUserId.value
                    ? `user_${currentUserId.value}`
                    : "",
                sender_type: "user",
                recipient_id: message.sender_type + "_" + message.sender_id,
                recipient_type: message.sender_type,
                sender_folder_id: null,
                recipient_folder_id: null,
                group_id: null,
                is_anonymous: false,
                is_read: false,
                read_at: null,
                sender_note: "",
                recipient_note: "",
                sender_name: "",
                recipient_name: "",
                deleted_by_sender: false,
                deleted_by_recipient: false,
                pinned: false,
            };
            newMessageDialog.value = true;
        };

        const forwardMessage = (message: Message) => {
            newMessage.value = {
                title: `Fwd: ${message.title}`,
                body: `<br><br>--- Ursprüngliche Nachricht ---<br>Von: ${message.sender_name}<br>Gesendet: ${message.read_at}<br><br>${message.body}`,
                sender_id: currentUserId.value
                    ? `user_${currentUserId.value}`
                    : "",
                sender_type: "user",
                recipient_id: null,
                recipient_type: "",
                sender_folder_id: null,
                recipient_folder_id: null,
                group_id: null,
                is_anonymous: false,
                is_read: false,
                read_at: null,
                sender_note: "",
                recipient_note: "",
                sender_name: "",
                recipient_name: "",
                deleted_by_sender: false,
                deleted_by_recipient: false,
                pinned: false,
            };
            newMessageDialog.value = true;
        };

        const openDeleteMessageDialog = (message: Message) => {
            selectedMessage.value = message;
            deleteMessageDialog.value = true;
        };

        const deleteMessage = async (message: Message) => {
            try {
                if (
                    message.sender_id === currentUserId.value &&
                    selectedGroup.value === null
                ) {
                    await api.post("message/?action=deleteMessage", {
                        id: message.id,
                        deleted_by_sender: true,
                    });
                } else if (
                    message.recipient_type === "group" &&
                    selectedGroup.value !== null
                ) {
                    await api.post("message/?action=deleteMessage", {
                        id: message.id,
                        deleted_by_recipient: true,
                    });
                } else {
                    await api.post("message/?action=deleteMessage", {
                        id: message.id,
                        deleted_by_recipient: true,
                    });
                }
                await fetchMessages();
                deleteMessageDialog.value = false;
            } catch (error) {
                snackbar.value.message = error.response.data.error;
                snackbar.value.color = "error";
                snackbar.value.value = true;
            }
        };

        const markMessageAsRead = async (message: Message) => {
            try {
                await api.post("message/?action=markAsRead", {
                    id: message.id,
                });
                await fetchMessages();
            } catch (error) {
                snackbar.value.message = error.response.data.error;
                snackbar.value.color = "error";
                snackbar.value.value = true;
            }
        };

        const markMessageAsPinned = async (message: Message) => {
            try {
                await api.post("message/?action=markAsPinned", {
                    id: message.id,
                });
                await fetchMessages();
            } catch (error) {
                snackbar.value.message = error.response.data.error;
                snackbar.value.color = "error";
                snackbar.value.value = true;
            }
        };

        const filterMessagesByGroup = (groupId: number | null) => {
            filterFolderSearch.value = null;
            selectedGroup.value = groupId;
        };

        const filteredMessages = computed(() => {
            const filterByFolder = (message: Message) => {
                if (!filterFolderSearch.value) return true;
                const folderId =
                    view.value === "inbox"
                        ? message.recipient_folder_id
                        : message.sender_folder_id;
                return folderId === filterFolderSearch.value;
            };

            const pinSort = (a: Message, b: Message) => {
                if (a.pinned && !b.pinned) return -1;
                if (!a.pinned && b.pinned) return 1;
                return 0;
            };

            if (view.value === "deleted") {
                return selectedGroup.value !== null
                    ? messages.value
                            .filter(
                                (message) =>
                                    message.recipient_type === "group" &&
                                    message.recipient_id ===
                                        selectedGroup.value &&
                                    message.deleted_by_recipient == true &&
                                    filterByFolder(message)
                            )
                    : messages.value
                            .filter(
                                (message) =>
                                    message.recipient_type === "user" &&
                                    message.recipient_id ===
                                        currentUserId.value &&
                                    message.deleted_by_recipient == true &&
                                    filterByFolder(message)
                            )
            } else if (view.value === "sent") {
                return selectedGroup.value !== null
                    ? messages.value
                            .filter(
                                (message) =>
                                    message.sender_type === "group" &&
                                    message.sender_id === selectedGroup.value &&
                                    message.deleted_by_sender == false &&
                                    filterByFolder(message)
                            )
                    : messages.value
                            .filter(
                                (message) =>
                                    message.sender_type === "user" &&
                                    message.sender_id === currentUserId.value &&
                                    message.deleted_by_sender == false &&
                                    filterByFolder(message)
                            )
            } else {
                return selectedGroup.value !== null
                    ? messages.value
                            .filter(
                                (message) =>
                                    message.recipient_type === "group" &&
                                    message.recipient_id ===
                                        selectedGroup.value &&
                                    message.deleted_by_recipient == false &&
                                    filterByFolder(message)
                            )
                            .sort(pinSort)
                    : messages.value
                            .filter(
                                (message) =>
                                    message.recipient_type === "user" &&
                                    message.recipient_id ===
                                        currentUserId.value &&
                                    message.deleted_by_recipient == false &&
                                    filterByFolder(message)
                            )
                            .sort(pinSort);
            }
        });

        const getMessagesCountByGroup = (groupId: number | null) => {
            return groupId !== null
                ? messages.value.filter(
                        (message) =>
                            message.recipient_type === "group" &&
                            message.recipient_id === groupId &&
                            message.is_read == false &&
                            message.deleted_by_recipient == false
                  ).length
                : messages.value.filter(
                        (message) =>
                            message.recipient_type === "user" &&
                            message.recipient_id === currentUserId.value &&
                            message.is_read == false &&
                            message.deleted_by_recipient == false
                  ).length;
        };

        const recipientOptions = computed(() => {
            const userOptions = users.value.map((user) => ({
                id: `user_${user.id}`,
                label: `${user.employee}`,
                type: "Benutzer",
            }));
            const groupOptions = groups.value.map((group) => ({
                id: `group_${group.id}`,
                label: `${group.name}`,
                type: "Gruppe",
            }));
            return [...userOptions, ...groupOptions];
        });

        const senderOptions = computed(() => {
            const currentUser = users.value.find(
                (user) => user.id === currentUserId.value
            );
            const groupOptions = groups.value
                .filter((group) => group.is_member)
                .map((group) => ({
                    id: `group_${group.id}`,
                    label: `${group.name} (Gruppe)`,
                }));
            return currentUser
                ? [
                        {
                            id: `user_${currentUser.id}`,
                            label: `${currentUser.username} (Ich)`,
                        },
                        ...groupOptions,
                  ]
                : groupOptions;
        });

        function isFormValid(form: Message) {
            return form.title !== "" && form.body !== "";
        }

        const openNewMessageDialog = () => {
            newMessage.value = {
                title: "",
                body: "",
                sender_id:
                    currentUserId.value && selectedGroup.value == null
                        ? `user_${currentUserId.value}`
                        : `group_${selectedGroup.value}`,
                sender_type: "",
                recipient_id: null,
                recipient_type: "",
                sender_folder_id: null,
                recipient_folder_id: null,
                group_id: null,
                is_anonymous: false,
                is_read: false,
                read_at: null,
                sender_note: "",
                recipient_note: "",
                sender_name: "",
                recipient_name: "",
                deleted_by_sender: false,
                deleted_by_recipient: false,
                pinned: false,
            };
            newMessageDialog.value = true;
        };

        const viewInbox = async () => {
            view.value = "inbox";
            selectedGroup.value = null;
            await fetchMessages();
        };

        const viewSent = async () => {
            view.value = "sent";
            selectedGroup.value = null;
            await fetchMessages();
        };

        const viewFolders = async () => {
            view.value = "folders";
            selectedGroup.value = null;
            await fetchFolders();
        };

        const viewDeleted = async () => {
            view.value = "deleted";
            selectedGroup.value = null;
            await fetchMessages();
        };

        const openNewFolderDialog = () => {
            newFolderName.value = "";
            newFolderCategory.value = "";
            newFolderDialog.value = true;
        };

        const addNewFolder = async () => {
            try {
                const payload = {
                    name: newFolderName.value,
                    user_id: newFolderCategory.value.startsWith("user_")
                        ? parseInt(newFolderCategory.value.split("_")[1], 10)
                        : null,
                    group_id: newFolderCategory.value.startsWith("group_")
                        ? parseInt(newFolderCategory.value.split("_")[1], 10)
                        : null,
                };
                await api.post("message/?action=addFolder", payload);
                await fetchFolders();
                newFolderDialog.value = false;
            } catch (error) {
                snackbar.value.message = error.response.data.error;
                snackbar.value.color = "error";
                snackbar.value.value = true;
            }
        };

        const editFolder = (folder: Folder) => {
            newFolderName.value = folder.name;
            newFolderCategory.value = folder.user_id
                ? `user_${folder.user_id}`
                : folder.group_id
                ? `group_${folder.group_id}`
                : "";
            newFolderDialog.value = true;
        };

        const deleteFolder = async (folderId: number) => {
            try {
                await api.post("message/?action=deleteFolder", {
                    id: folderId,
                });
                await fetchFolders();
            } catch (error) {
                snackbar.value.message = error.response.data.error;
                snackbar.value.color = "error";
                snackbar.value.value = true;
            }
        };

        const filterFoldersByGroup = (groupId: number | null) => {
            selectedGroup.value = groupId;
        };

        const filteredFolders = computed(() => {
            if (selectedGroup.value !== null) {
                return folders.value.filter(
                    (folder) => folder.group_id === selectedGroup.value
                );
            } else {
                return folders.value.filter(
                    (folder) => folder.user_id === currentUserId.value
                );
            }
        });

        const folderCategoryOptions = computed(() => {
            const userOptions = users.value.map((user) => ({
                id: `user_${user.id}`,
                label: `${user.employee}`,
            }));
            const groupOptions = groups.value.map((group) => ({
                id: `group_${group.id}`,
                label: `${group.name}`,
            }));
            return [...userOptions, ...groupOptions];
        });

        const filteredGroups = computed(() => {
            return groups.value.filter((group) => group.is_member);
        });

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            // Überprüfen, ob das Datum gültig ist
            if (isNaN(date.getTime())) {
                return "Ungültiges Datum";
            }
            const day = date.getDate().toString().padStart(2, "0");
            const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Monate beginnen bei 0 in JavaScript
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const seconds = date.getSeconds().toString().padStart(2, "0");
            return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
        };

        onMounted(async () => {
            await fetchMessages();
            await fetchUsers();
            await fetchGroups();
            await fetchFolders();
			setupWebSocket();
        });

        return {
            messageHeaders,
            messages,
            filteredMessages,
            users,
            groups,
            folders,
            filteredFolders,
            folderHeaders,
            search,
            newMessageDialog,
            newMessage,
            addNewMessage,
            requiredRule,
            readMessageDialog,
            readMessage,
            updateMessage,
            deleteMessage,
            replyMessage,
            forwardMessage,
            deleteMessageDialog,
            selectedMessage,
            openReadMessageDialog,
            openDeleteMessageDialog,
            isFormValid,
            snackbar,
            openNewMessageDialog,
            markMessageAsRead,
            filterMessagesByGroup,
            getMessagesCountByGroup,
            recipientOptions,
            senderOptions,
            currentUserId,
            EditorClass,
            viewInbox,
            viewSent,
            viewFolders,
            viewDeleted,
            view,
            newFolderDialog,
            newFolderName,
            newFolderCategory,
            openNewFolderDialog,
            addNewFolder,
            editFolder,
            deleteFolder,
            filterFoldersByGroup,
            folderCategoryOptions,
            selectedGroup,
            filteredGroups,
            formatDate,
            filterFolderSearch,
            markMessageAsPinned,
        };
    }
});
</script>

<style scoped>
.readMessageDialog {
    position: fixed;
    top: 71px; /* Höhe der oberen Navbar */
    left: 256px;
    right: 0;
    bottom: 0;
    width: calc(100% - 256px);
    height: calc(100% - 71px);
    z-index: 1000; /* Stellen Sie sicher, dass der DocumentEditor über anderen Inhalten angezeigt wird */
}
</style>
