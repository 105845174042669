+<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-btn @click="openNewTrainingDialog">Schulung Eintragen</v-btn>
                <v-btn class="ml-2" @click="createTrainingplan">Ausbildungsplan erstellen</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
            <v-text-field
                v-model="search"
                label="Search"
                dark
                outlined
                hide-details
                @input="updateFilteredItems"
            />
            </v-col>
            <v-col cols="6">
                <v-select
                    v-model="selectedRanks"
                    :items="rankOptions"
                    item-title="name"
                    item-value="id"
                    label="Select Rank"
                    multiple
                    chips
                    clearable
                    @update:model-value="updateFilteredItems"
                />
            </v-col>
            <v-col cols="6">
                <v-select
                    v-model="selectedCompanies"
                    :items="companyOptions"
                    item-title="name"
                    item-value="id"
                    label="Select Company"
                    multiple
                    chips
                    clearable
                    @update:model-value="updateFilteredItems"
                />
            </v-col>



        </v-row>

        <v-row v-for="category in categoriesWithTrainings" :key="category.id">
            <v-col cols="12">
                <h3>{{ category.name }}</h3>
                <v-data-table :headers="category.headers" :items="category.employees" class="elevation-1">
                <template v-slot:item="{ item }">
                    <tr>
                    <td>{{ item.name }}</td>
                    <td v-for="header in category.headers.slice(1)" :key="header.key">
                        <div v-if="item.trainingDetails[header.key]">
                            <span>{{ item.trainingDetails[header.key].date }}</span><br>
                            <span>{{ item.trainingDetails[header.key].instructor }}</span>
                        </div>
                    </td>
                    </tr>
                </template>
                </v-data-table>
            </v-col>
        </v-row>
      
        <v-row>
            <v-dialog v-model="editDialog" persistent max-width="600px">
                <v-card>
                <v-card-title>
                    <span class="text-h5">Neue Schulung Eintragen</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                    <v-row>
                        <v-col cols="12">
                        <v-select
                            v-model="newTraining.trainingId"
                            :items="Trainings"
                            item-title="name"
                            item-value="id"
                            label="Schulung"
                            required
                            chips
                            multiple
                        ></v-select>
                        </v-col>
                        <v-col cols="12">
                        <v-select
                            v-model="newTraining.employees"
                            :items="Employees"
                            item-title="name"
                            item-value="id"
                            label="Mitarbeiter"
                            multiple
                            chips
                            required
                        ></v-select>
                        </v-col>
                        <v-col cols="12">
                        <v-text-field
                            v-model="newTraining.date"
                            label="Datum"
                            type="date"
                            required
                        ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                        <v-text-field
                            v-model="newTraining.notes"
                            label="Wer hat die Schulunge gehalten?"
                        ></v-text-field>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="editDialog = false">
                    Abbrechen
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="saveNewTraining">
                    Speichern
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
      

    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed, onMounted } from "vue";
  import api from "@/api";
  import {Training, TrainingAssign, Employee, NewTraining, Category, Rank} from  "@/types/Training"
  
  export default defineComponent({
        components: {
        },
        setup() {
            const errorSnackbar = ref<any>({ visible: false, message: "" });

            const search = ref("");
            const Trainings = ref<Training[]>([]);
            const TrainingAssigns = ref<TrainingAssign[]>([]);
            const Employees = ref<Employee[]>([]);
            const Ranks = ref<Rank[]>([]);
            const categoriesWithTrainings = ref<Category[]>([]);

            const selectedRanks = ref<number[]>([]);
            const selectedCompanies = ref<number[]>([]);
            const rankOptions = ref<{ id: number; name: string }[]>([]);
            const companyOptions = ref<{ id: number; name: string }[]>([]);


            const editDialog = ref(false);
            const newTraining = ref<NewTraining>({
                trainingId: [],
                employees: [],
                date: '',
                notes: '',
            });


            async function fetchTrainings() {
                try {
                    const response = await api.get('training/index.php?action=getTrainings');
                    Trainings.value = response.data.map((item: any) => ({
                        id: item.id,
                        name: item.name,
                        catId: item.catId,
                        cat_name: item.cat_name,
                        cat_short: item.cat_short,
                    }));
                } catch (error) {
                    handleApiError(error);
                }
            }

            async function fetchTrainingAssigns() {
                try {
                    const response = await api.get('training/index.php?action=getTrainingAssigns');
                    TrainingAssigns.value = response.data;
                } catch (error) {
                    handleApiError(error);
                }
            }

            async function fetchRanks() {
                try {
                    const response = await api.get('training/index.php?action=getRanks');
                    Ranks.value = response.data;

                    rankOptions.value = response.data.map((rank: Rank) => ({
                        id: rank.id,
                        name: rank.name,
                    }));
                } catch (error) {
                    handleApiError(error);
                }
            }



            const fetchEmployees = async () => {
                try {
                    const response = await api.get('training/index.php?action=getEmployees');
                    Employees.value = response.data.map((item: any) => ({
                        id: item.id,
                        name: `FD${item.servicenumber} ${item.name}`,
                        servicenumber: item.servicenumber,
                        rank_id: item.rank_id,
                        rank_name: item.rank_name,
                        trainings: [], // Initialisieren der trainings-Eigenschaft
                    }));
                } catch (error) {
                    handleApiError(error);
                }
            };


            const Companies = ref<any[]>([]); // Variable zum Speichern der Unternehmen

            const fetchCompanies = async () => {
                try {
                    const response = await api.get('training/index.php?action=getCompanies');
                    Companies.value = response.data.map((item: any) => ({
                        id: item.id,
                        name: item.name,
                        employee_ids: item.employee_ids || [],
                    }));
                    companyOptions.value = response.data.map((company: any) => ({
                        id: company.id,
                        name: company.name,
                    }));
                } catch (error) {
                    handleApiError(error);
                }
            };



            function handleApiError(error: any) {
                if (error.response && error.response.data && error.response.data.error) {
                    errorSnackbar.value.message = error.response.data.error;
                } else {
                    errorSnackbar.value.message = 'Ein unbekannter Fehler ist aufgetreten.';
                }
                errorSnackbar.value.visible = true;
            }

            const processCategoriesWithTrainings = () => {
                const categoriesMap: { [key: number]: Category } = {};

                // Group trainings by category ID
                Trainings.value.forEach(training => {
                    if (!categoriesMap[training.catId]) {
                        categoriesMap[training.catId] = {
                            id: training.catId,
                            name: training.cat_name,
                            trainings: [],
                            headers: [{ title: 'Mitarbeiter', key: 'name' }],
                            employees: [],
                        };
                    }
                    categoriesMap[training.catId].trainings.push(training);
                    categoriesMap[training.catId].headers.push({ title: training.name, key: training.id.toString() });
                });

                // Process employees and their training assignments
                Employees.value.forEach(employee => {
                    const trainingDetails: { [key: string]: { date: string; instructor: string } } = {};

                    TrainingAssigns.value.forEach(assign => {
                        if (assign.employeeid === employee.id) {
                            trainingDetails[assign.training_id.toString()] = {
                                date: assign.date,
                                instructor: assign.instructor,
                            };
                        }
                    });

                    const employeeWithTrainings = {
                        id: employee.id,
                        name: employee.name,
                        servicenumber: employee.servicenumber,
                        trainings: TrainingAssigns.value
                            .filter(assign => assign.employeeid === employee.id)
                            .map(assign => assign.training_id.toString()),
                        trainingDetails,
                    };

                    // Add employee to each category they belong to
                    Object.values(categoriesMap).forEach(category => {
                        if (category.trainings.some(training => employee.trainings.includes(training.id.toString()))) {
                            category.employees.push(employeeWithTrainings);
                        }
                    });
                });

                // Convert categories map to array and sort by the original order of Trainings
                const sortedCategories = Object.values(categoriesMap).sort((a, b) => {
                    const aIndex = Trainings.value.findIndex(t => t.catId === a.id);
                    const bIndex = Trainings.value.findIndex(t => t.catId === b.id);
                    return aIndex - bIndex;
                });

                categoriesWithTrainings.value = sortedCategories;
            };




        const updateFilteredItems = () => {
            const searchTerm = search.value.toLowerCase();

            categoriesWithTrainings.value.forEach(category => {
                category.employees = Employees.value
                    .filter(employee =>
                        (employee.name.toLowerCase().includes(searchTerm) ||
                            employee.trainings.some(trainingId =>
                                Trainings.value.find(training => training.id.toString() === trainingId)?.name.toLowerCase().includes(searchTerm)
                            )) &&
                        (selectedRanks.value.length === 0 || selectedRanks.value.includes(employee.rank_id)) && // Filterung nach mehreren Rängen
                        (selectedCompanies.value.length === 0 || Companies.value.some(company => company.employee_ids.includes(employee.id) && selectedCompanies.value.includes(company.id))) // Filterung nach Unternehmen
                    )
                    .map(employee => {
                        const trainingDetails: { [key: string]: { date: string; instructor: string } } = {};
                        TrainingAssigns.value.forEach(assign => {
                            if (assign.employeeid === employee.id) {
                                trainingDetails[assign.training_id.toString()] = {
                                    date: assign.date,
                                    instructor: assign.instructor,
                                };
                            }
                        });
                        return {
                            ...employee,
                            trainingDetails,
                        };
                    });
            });
        };



        
            onMounted(async () => {
                await fetchTrainings();
                await fetchTrainingAssigns();
                await fetchEmployees();
                await fetchRanks();
                await fetchCompanies();
                processCategoriesWithTrainings();
                updateFilteredItems();
            });

            function openNewTrainingDialog() {
                newTraining.value = {
                    trainingId: [],
                    employees: [],
                    date: '',
                    notes: '',
                };
                editDialog.value = true;
            }

            async function saveNewTraining() {
                try {
                    const response = await api.post('training/index.php?action=saveTraining', newTraining.value);
                    // Handle successful save
                    editDialog.value = false;
                    await fetchTrainingAssigns();
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible = true;
                }
            }


            async function createTrainingplan() {
                const headerTemplate = (categoryName: string) => `
                <tr>
                    <td style="background-color:rgb(255, 0, 51); white-space:nowrap"><span style="color:#FFFFFF"><strong>${categoryName}</strong></span></td>
                    <td style="background-color:rgb(255, 0, 51); white-space:nowrap"><span style="color:#FFFFFF"><strong>Datum/ Uhrzeit</strong></span></td>
                    <td style="background-color:rgb(255, 0, 51); white-space:nowrap"><span style="color:#FFFFFF"><strong>Dozent</strong></span></td>
                    <td style="background-color:rgb(255, 0, 51); white-space:nowrap"><span style="color:#FFFFFF"><strong>Module benötigt (ändert Academy)</strong></span></td>
                    <td style="background-color:rgb(255, 0, 51); white-space:nowrap"><span style="color:#FFFFFF"><strong>Teilnahme an Schulung</strong></span></td>
                    <td style="background-color:rgb(255, 0, 51); white-space:nowrap"><span style="color:#FFFFFF"><strong>Mind. Teilnehmer</strong></span></td>
                    <td style="background-color:rgb(255, 0, 51); white-space:nowrap"><span style="color:#FFFFFF"><strong>Bemerkungen</strong></span></td>
                    <td style="background-color:rgb(255, 0, 51); white-space:nowrap"><span style="color:#FFFFFF"><strong>Ort</strong></span></td>
                </tr>`;

                let body = '';

                const relevantCategories = categoriesWithTrainings.value.filter(category => 
                    ['Ground Training', 'Advanced Training', 'Engine Training', 'Ladder Training', 'Rescue Training'].includes(category.name)
                );

                relevantCategories.forEach(category => {
                    body += headerTemplate(category.name);

                    category.trainings.forEach(training => {
                        let employeesWithoutTraining = [];

                        if (category.name === 'Engine Training') {
                            const engineCompany = Companies.value.find(company => company.name === 'Engine Company');
                            if (engineCompany) {
                                employeesWithoutTraining = Employees.value.filter(employee =>
                                    engineCompany.employee_ids.includes(employee.id) &&
                                    !TrainingAssigns.value.some(assign => assign.training_id === training.id && assign.servicenumber === employee.servicenumber)
                                );
                            }
                        } else if (category.name === 'Ladder Training') {
                            const ladderCompany = Companies.value.find(company => company.name === 'Ladder Company');
                            if (ladderCompany) {
                                employeesWithoutTraining = Employees.value.filter(employee =>
                                    ladderCompany.employee_ids.includes(employee.id) &&
                                    !TrainingAssigns.value.some(assign => assign.training_id === training.id && assign.servicenumber === employee.servicenumber)
                                );
                            }
                        } else if (category.name === 'Rescue Training') {
                            const rescueCompany = Companies.value.find(company => company.name === 'Rescue Company');
                            if (rescueCompany) {
                                employeesWithoutTraining = Employees.value.filter(employee =>
                                    rescueCompany.employee_ids.includes(employee.id) &&
                                    !TrainingAssigns.value.some(assign => assign.training_id === training.id && assign.servicenumber === employee.servicenumber)
                                );
                            }
                        } else {
                            employeesWithoutTraining = Employees.value.filter(employee =>
                                !TrainingAssigns.value.some(assign => assign.training_id === training.id && assign.servicenumber === employee.servicenumber)
                            );
                        }

                        const employeeServiceNumbers = employeesWithoutTraining.map(employee => `FD${employee.servicenumber}`).join(', ');

                        body += `
                        <tr>
                            <td style="white-space:nowrap; width:200px">${training.name}</td>
                            <td style="white-space:nowrap; width:180px">&nbsp;</td>
                            <td style="white-space:nowrap; width:100px">&nbsp;</td>
                            <td style="white-space:nowrap; width:240px">${employeeServiceNumbers}</td>
                            <td style="white-space:nowrap; width:240px">&nbsp;</td>
                            <td style="white-space:nowrap">&nbsp;</td>
                            <td style="white-space:nowrap">Unterlagen durchlesen &amp; verinnerlichen</td>
                            <td style="white-space:nowrap; width:120px">&nbsp;</td>
                        </tr>`;
                    });
                });

                const footer = `
                    </tbody>
                </table>`;

                const trainingPlanHTML = `
                <table border="1" cellpadding="1" cellspacing="1" style="width:1180px">
                    <tbody>${body}${footer}`;

                try {
                    await navigator.clipboard.writeText(trainingPlanHTML);
                    errorSnackbar.value.message = 'Ausbildungsplan erfolgreich kopiert.';
                    errorSnackbar.value.visible = true;
                    errorSnackbar.value.color = 'green';
                } catch (err) {
                    errorSnackbar.value.message = 'Ausbildungsplan nicht erfolgreich kopiert.';
                    errorSnackbar.value.visible = true;
                }
            }








            return {
                search,
                errorSnackbar,
                Trainings,
                TrainingAssigns,
                Employees,
                editDialog,
                newTraining,
                openNewTrainingDialog,
                saveNewTraining,
                categoriesWithTrainings,
                updateFilteredItems,
                rankOptions,
                companyOptions,
                selectedRanks,
                selectedCompanies,
                createTrainingplan,
            };
        },
  });
  </script>
  <style scoped>
  :deep() .v-table .v-table__wrapper > table > thead > tr > th:not(:last-child) {
    border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
  }
  :deep() .v-table .v-table__wrapper > table > tbody > tr > td:not(:last-child), .v-table .v-table__wrapper > table > tbody > tr > th:not(:last-child) { 
    border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
  }
</style>