<template>
    <v-container>
      <v-text-field
        append-inner-icon="mdi-content-save"
        density="compact"
        label="Feuerlöschernummer"
        variant="solo"
        hide-details
        v-model="extinguisherNr"
        style="width:300px"
        class="float-left"
        @click:append-inner="updateFireExtinguisherNr"
      ></v-text-field>
      <v-btn @click="openNewCompanyDialog" class="float-right">Neue Firma</v-btn>
      <v-data-table
        :headers="companyHeaders"
        :items="filteredCompanies"
        class="elevation-1 mt-12"
        :search="search"
        :items-per-page="25"
        :group-by="groupBy"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Firmenverwaltung</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-select
              class="mx-4 mt-3"
              v-model="filters.expiryFilter"
              :items="expiryFilterOptions"
              label="Filter nach Ablaufdatum"
              variant="underlined"
              clearable
            ></v-select>
            <v-text-field v-model="search" label="Suche" class="mx-4 mt-3" variant="underlined" />
          </v-toolbar>
        </template>
        <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
          <tr>
            <td :colspan="columns.length">
              <VBtn
                :icon="isGroupOpen(item) ? '$expand' : '$next'"
                size="small"
                variant="text"
                @click="toggleGroup(item)"
              ></VBtn>
              {{ item.value }}
            </td>
          </tr>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon size="small" class="me-2" @click="openEditCompanyDialog(item)">mdi-pencil</v-icon>
          <v-icon size="small" class="me-2" @click="openDeleteCompanyDialog(item)">mdi-delete</v-icon>
          <v-icon size="small" class="me-2" @click="viewCompanyDetails(item)">mdi-eye</v-icon>
        </template>
        <template v-slot:[`item.last_fire_protection_inspection`]="{ item }">
          {{ formatDate(item.last_fire_protection_inspection) }}
        </template>
        <template v-slot:[`item.fire_protection_inspection_valid_until`]="{ item }">
          {{ formatDate(item.fire_protection_inspection_valid_until) }}
        </template>
      </v-data-table>
  
      <!-- New Company Dialog -->
      <v-dialog v-model="newCompanyDialog" fullscreen persistent>
        <v-card>
          <v-card-title>
            Neue Firma
          </v-card-title>
          <v-card-text>
            <v-form ref="newCompanyForm">
                <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="5">
                            <v-text-field label="Name" v-model="newCompany.name" required :rules="[requiredRule]"></v-text-field>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field label="Standort" v-model="newCompany.location" required :rules="[requiredRule]"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field label="Leitstellennummer" v-model="newCompany.phonenumber" required :rules="[requiredRule]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field label="Venture Mail" v-model="newCompany.email"></v-text-field>
                    <v-row>
                        <v-col cols="6">
                        <v-text-field label="CEO" v-model="newCompany.ceo" required :rules="[requiredRule]"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                        <v-text-field label="Co-CEO" v-model="newCompany.co_ceo"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-select label="Typ" v-model="newCompany.type_id" :items="companyTypes" item-title="name" item-value="id" required :rules="[requiredRule]"></v-select>
                    <v-text-field label="Ansprechpartner & Nummer" v-model="newCompany.contact_person" required :rules="[requiredRule]"></v-text-field>
                    <v-row>
                        <v-col cols="6">
                        <v-text-field label="Letzte Brandschutzbegehung" v-model="newCompany.last_fire_protection_inspection" type="date"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                        <v-text-field label="Brandschutzbegehung gültig bis" v-model="newCompany.fire_protection_inspection_valid_until" type="date"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-checkbox v-model="newCompany.contract_available" label="Vertrag verfügbar"></v-checkbox>
                    <ckeditor :editor="EditorClass" :config="EditorClass.defaultConfig" v-model="newCompany.description"></ckeditor>
                </v-col>
                </v-row>
            </v-form>
            </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="newCompanyDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="addNewCompany">Hinzufügen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Edit Company Dialog -->
      <v-dialog v-model="editCompanyDialog" fullscreen persistent>
        <v-card>
          <v-card-title>
            Firma bearbeiten
          </v-card-title>
          <v-card-text>
            <v-form ref="editCompanyForm">
              <v-row>
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="5">
                            <v-text-field label="Name" v-model="editedCompany.name" required :rules="[requiredRule]"></v-text-field>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field label="Standort" v-model="editedCompany.location" required :rules="[requiredRule]"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field label="Leitstellennummer" v-model="editedCompany.phonenumber" required :rules="[requiredRule]"></v-text-field>
                        </v-col>
                    </v-row>
                  <v-text-field label="Venture Mail" v-model="editedCompany.email"></v-text-field>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="CEO" v-model="editedCompany.ceo" required :rules="[requiredRule]"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Co-CEO" v-model="editedCompany.co_ceo"></v-text-field>
                        </v-col>
                    </v-row>
                  <v-select label="Typ" v-model="editedCompany.type_id" :items="companyTypes" item-title="name" item-value="id" required :rules="[requiredRule]"></v-select>
                  <v-text-field label="Ansprechpartner & Nummer" v-model="editedCompany.contact_person" required :rules="[requiredRule]"></v-text-field>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Letzte Brandschutzbegehung" v-model="editedCompany.last_fire_protection_inspection" required :rules="[requiredRule]" type="date"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Brandschutzbegehung gültig bis" v-model="editedCompany.fire_protection_inspection_valid_until" type="date"></v-text-field>
                        </v-col>
                    </v-row>
                  <v-checkbox v-model="editedCompany.contract_available" label="Vertrag verfügbar"></v-checkbox>
                  <v-file-input label="Bild" v-model="editedCompany.image_file" accept="image/*" @change="validateImage"></v-file-input>
                  <v-img
                    :src="editedCompany.image"
                    max-width="100"
                    class="mt-3"
                    v-if="editedCompany.image"
                    @click="openImagePreview(editedCompany.image)"
                    ></v-img>
                </v-col>
                <v-col cols="12" md="6">
                  <ckeditor :editor="EditorClass" :config="EditorClass.defaultConfig" v-model="editedCompany.description"></ckeditor>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editCompanyDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="updateCompany">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Delete Company Dialog -->
      <v-dialog v-model="deleteCompanyDialog" max-width="400" persistent>
        <v-card>
          <v-card-title class="headline">Firma löschen</v-card-title>
          <v-card-text>
            Möchten Sie die Firma "{{ selectedCompany.name }}" wirklich löschen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteCompanyDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="deleteCompany(selectedCompany)">Löschen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Company Details Dialog -->
      <v-dialog v-model="companyDetailsDialog" fullscreen persistent>
        <v-card>
          <v-card-title>
            Firma Details
            <v-spacer></v-spacer>
            <v-btn @click="openNewExtinguisherDialog" class="float-right">Neuer Feuerlöscher</v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                    <v-col cols="5">
                        <v-text-field label="Name" v-model="selectedCompany.name" readonly></v-text-field>
                    </v-col>
                    <v-col cols="5">
                        <v-text-field label="Standort" v-model="selectedCompany.location" readonly></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="Leitstellennummer" v-model="selectedCompany.phonenumber" readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-text-field label="Venture Mail" v-model="selectedCompany.email" readonly></v-text-field>
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="CEO" v-model="selectedCompany.ceo" readonly></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field label="Co-CEO" v-model="selectedCompany.co_ceo" readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-text-field label="Typ" v-model="selectedCompany.type_name" readonly></v-text-field>
                <v-text-field label="Ansprechpartner & Nummer" v-model="selectedCompany.contact_person" readonly></v-text-field>
                <v-row>
                    <v-col cols="6">
                        <v-text-field label="Letzte Brandschutzbegehung" v-model="selectedCompany.last_fire_protection_inspection" readonly type="date"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field label="Brandschutzbegehung gültig bis" v-model="selectedCompany.fire_protection_inspection_valid_until" readonly type="date"></v-text-field>
                    </v-col>
                </v-row>
                
                <v-checkbox v-model="selectedCompany.contract_available" label="Vertrag verfügbar" readonly></v-checkbox>
                <v-img
                    :src="selectedCompany.image"
                    max-width="100"
                    class="mt-3"
                    v-if="selectedCompany.image"
                    @click="openImagePreview(selectedCompany.image)"
                ></v-img>
              </v-col>
              <v-col cols="12" md="6">
                <v-data-table
                  :headers="extinguisherHeaders"
                  :items="extinguishers"
                  class="elevation-1"
                  :search="extinguisherSearch"
                  :items-per-page="5"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Feuerlöscher</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-text-field v-model="extinguisherSearch" label="Suche" class="mx-4 mt-3" variant="underlined" />
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon size="small" class="me-2" @click="openEditExtinguisherDialog(item)">mdi-pencil</v-icon>
                    <v-icon size="small" class="me-2" @click="openDeleteExtinguisherDialog(item)">mdi-delete</v-icon>
                  </template>
                  <template v-slot:[`item.last_inspection`]="{ item }">
                    {{ formatDate(item.last_inspection) }}
                  </template>
                  <template v-slot:[`item.registration_date`]="{ item }">
                    {{ formatDate(item.registration_date) }}
                  </template>
                </v-data-table>
                <h3 class="mt-5">Description</h3>
                <div v-html="selectedCompany.description"></div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="viewCompanyHistory(selectedCompany)">Verlauf anzeigen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="companyDetailsDialog = false">Schließen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="imagePreviewDialog" max-width="800px">
        <v-card>
            <v-card-title class="headline">Bildvorschau</v-card-title>
            <v-card-text>
            <v-img :src="imagePreviewUrl" max-width="100%"></v-img>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="imagePreviewDialog = false">Schließen</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

  
      <!-- New Extinguisher Dialog -->
      <v-dialog v-model="newExtinguisherDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">Neuer Feuerlöscher</v-card-title>
          <v-card-text>
            <v-form ref="newExtinguisherForm">
              <v-text-field label="Kennung" v-model="newExtinguisher.identifier" required :rules="[requiredRule]"></v-text-field>
              <v-text-field label="Standort" v-model="newExtinguisher.location"></v-text-field>
              <v-text-field label="Registrierungsdatum" v-model="newExtinguisher.registration_date" type="date"></v-text-field>
              <v-text-field label="Letzte Prüfung" v-model="newExtinguisher.last_inspection" type="date"></v-text-field>
              <v-text-field label="Geprüft von" v-model="newExtinguisher.inspected_by"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="newExtinguisherDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="addNewExtinguisher" :disabled="!isFormValid(newExtinguisher)">Hinzufügen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Edit Extinguisher Dialog -->
      <v-dialog v-model="editExtinguisherDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">Feuerlöscher bearbeiten</v-card-title>
          <v-card-text>
            <v-form ref="editExtinguisherForm">
              <v-text-field label="Kennung" v-model="editedExtinguisher.identifier" required :rules="[requiredRule]"></v-text-field>
              <v-text-field label="Standort" v-model="editedExtinguisher.location"></v-text-field>
              <v-text-field label="Registrierungsdatum" v-model="editedExtinguisher.registration_date" type="date"></v-text-field>
              <v-text-field label="Letzte Prüfung" v-model="editedExtinguisher.last_inspection" type="date"></v-text-field>
              <v-text-field label="Geprüft von" v-model="editedExtinguisher.inspected_by"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editExtinguisherDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="updateExtinguisher">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Delete Extinguisher Dialog -->
      <v-dialog v-model="deleteExtinguisherDialog" max-width="400" persistent>
        <v-card>
          <v-card-title class="headline">Feuerlöscher löschen</v-card-title>
          <v-card-text>
            Möchten Sie den Feuerlöscher "{{ selectedExtinguisher.identifier }}" wirklich löschen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteExtinguisherDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="deleteExtinguisher(selectedExtinguisher)">Löschen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Snackbar -->
      <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
        {{ snackbar.message }}
      </v-snackbar>
    </v-container>
  </template>
  
  <script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import api from '@/apiWithoutHeaders';
import { Company, CompanyType, Extinguisher, CompanyHistory } from '@/types/Company';
import { useStore } from 'vuex';
import EditorClass from '@/ckeditor/ckeditor.js';

export default defineComponent({
  setup() {
    const store = useStore();
    const errorSnackbar = ref<any>({ visible: false, message: '' });
    const imagePreviewDialog = ref(false);
    const imagePreviewUrl = ref('');
    const extinguisherNr = ref('');

    const companyHeaders = [
      { title: 'Name', align: 'start', sortable: false, key: 'name' },
      { title: 'Leitstellennummer', align: 'start', sortable: false, key: 'phonenumber' },
      { title: 'CEO', align: 'start', sortable: false, key: 'ceo' },
      { title: 'Anzahl Feuerlöscher', align: 'start', sortable: false, key: 'extinguisher_count' },
      { title: 'Letzte BSB', align: 'start', sortable: false, key: 'last_fire_protection_inspection' },
      { title: 'Nächste BSB', align: 'start', sortable: false, key: 'fire_protection_inspection_valid_until' },
      { title: 'Aktionen', key: 'actions', sortable: false },
    ];

    const extinguisherHeaders = [
      { title: 'Kennung', align: 'start', sortable: false, key: 'identifier' },
      { title: 'Registrierungsdatum', align: 'start', sortable: false, key: 'registration_date' },
      { title: 'Standort', align: 'start', sortable: false, key: 'location' },
      { title: 'Letzte Prüfung', align: 'start', sortable: false, key: 'last_inspection' },
      { title: 'Geprüft von', align: 'start', sortable: false, key: 'inspected_by' },
      { title: 'Aktionen', key: 'actions', sortable: false },
    ];

    const groupBy = [
      {
        key: 'type_name',
        order: 'asc',
      },
    ];

    const companies = ref<Company[]>([]);
    const companyTypes = ref<CompanyType[]>([]);
    const extinguishers = ref<Extinguisher[]>([]);
    const companyHistories = ref<CompanyHistory[]>([]);
    const newCompanyDialog = ref(false);
    const editCompanyDialog = ref(false);
    const deleteCompanyDialog = ref(false);
    const companyDetailsDialog = ref(false);
    const newExtinguisherDialog = ref(false);
    const editExtinguisherDialog = ref(false);
    const deleteExtinguisherDialog = ref(false);
    const selectedCompany = ref<Company | null>(null);
    const selectedExtinguisher = ref<Extinguisher | null>(null);
    const newCompany = ref<Company>({
      id: 0,
      name: '',
      location: '',
      email: '',
      ceo: '',
      co_ceo: '',
      type_id: 0,
      contact_person: '',
      phonenumber: '',
      last_fire_protection_inspection: '',
      fire_protection_inspection_valid_until: '',
      contract_available: false,
      description: '',
    });
    const editedCompany = ref<Company>({
      id: 0,
      name: '',
      location: '',
      email: '',
      ceo: '',
      co_ceo: '',
      type_id: 0,
      contact_person: '',
      phonenumber: '',
      last_fire_protection_inspection: '',
      fire_protection_inspection_valid_until: '',
      contract_available: false,
      image: '',
      image_file: null,
      description: '',
    });
    const newExtinguisher = ref<Extinguisher>({
      id: 0,
      identifier: '',
      company_id: 0,
      registration_date: '',
      last_inspection: '',
      inspected_by: '',
      location: '',
    });
    const editedExtinguisher = ref<Extinguisher>({
      id: 0,
      identifier: '',
      company_id: 0,
      registration_date: '',
      last_inspection: '',
      inspected_by: '',
      location: '',
    });
    const search = ref('');
    const extinguisherSearch = ref('');
    const snackbar = ref({
      value: false,
      color: '',
      message: '',
    });

    const requiredRule = (value: string) => !!value || 'Dieses Feld ist erforderlich.';

    const fetchCompanies = async () => {
      try {
          const response = await api.get('company/?action=getCompanies');
          companies.value = response.data.map(entry => ({
              ...entry,
              contract_available: entry.contract_available == '1' ? true : false,
          }));
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const filters = ref({
      expiryFilter: null as string | null,
    });

    const expiryFilterOptions = [
      { title: 'Nächsten 30 Tage', value: 'next-30-days' },
      { title: 'Letzten 30 Tage', value: 'last-30-days' },
      { title: 'Nicht verfügbar', value: 'not-available' },
    ];

    const filteredCompanies = computed(() => {
      return companies.value
        .filter(company => {
          const matchesSearch = search.value ? company.name.toLowerCase().includes(search.value.toLowerCase()) : true;
          const matchesExpiryFilter = matchExpiryFilter(company);
          return matchesSearch && matchesExpiryFilter;
        });
    });

    const matchExpiryFilter = (company: Company) => {
      const today = new Date();
      const dateValidUntil = new Date(company.fire_protection_inspection_valid_until);
      switch (filters.value.expiryFilter) {
        case 'next-30-days':
          return dateValidUntil >= today && dateValidUntil <= new Date(today.setDate(today.getDate() + 30));
        case 'last-30-days':
          return dateValidUntil < today && dateValidUntil >= new Date(today.setDate(today.getDate() - 30));
        case 'not-available':
          return company.fire_protection_inspection_valid_until === '0000-00-00';
        default:
          return true;
      }
    };

    const fetchCompanyTypes = async () => {
      try {
        const response = await api.get('company/?action=getCompanyTypes');
        companyTypes.value = response.data;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const fetchExtinguisherNr = async () => {
      try {
        const response = await api.get('company/?action=getFireExtinguisherNr');
        extinguisherNr.value = response.data.extinguisherNr;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const fetchExtinguishers = async (companyId: number) => {
      try {
        const response = await api.get(`company/?action=getExtinguishers&company_id=${companyId}`);
        extinguishers.value = response.data;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const fetchCompanyHistory = async (companyId: number) => {
      try {
        const response = await api.get(`company/?action=getCompanyHistory&company_id=${companyId}`);
      companyHistories.value = response.data;
    } catch (error) {
      errorSnackbar.value.message = error.response.data.error;
      errorSnackbar.value.visible = true;
    }
  };

  const addNewCompany = async () => {
    try {
      const formData = new FormData();
      for (const key in newCompany.value) {
        if (Object.prototype.hasOwnProperty.call(newCompany.value, key)) {
          formData.append(key, newCompany.value[key]);
        }
      }
      await api.post('company/?action=addCompany', formData);
      await fetchCompanies();
      newCompanyDialog.value = false;
      newCompany.value = {
        id: 0,
        name: '',
        location: '',
        email: '',
        ceo: '',
        co_ceo: '',
        type_id: 0,
        contact_person: '',
        phonenumber: '',
        last_fire_protection_inspection: '',
        fire_protection_inspection_valid_until: '',
        contract_available: false,
        description: '',
      };
    } catch (error) {
      errorSnackbar.value.message = error.response.data.error;
      errorSnackbar.value.visible = true;
    }
  };

  const updateCompany = async () => {
    try {
        const formData = new FormData();
        for (const key in editedCompany.value) {
            if (Object.prototype.hasOwnProperty.call(editedCompany.value, key)) {
                formData.append(key, editedCompany.value[key]);
            }
        }
        if (editedCompany.value.image_file && editedCompany.value.image_file[0] instanceof File) {
            formData.append('image_file', editedCompany.value.image_file[0]);
        }

        await api.post('company/?action=editCompany', formData);
        await fetchCompanies();
        editCompanyDialog.value = false;
    } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
    }
};





  const deleteCompany = async (company: Company) => {
    try {
      await api.post('company/?action=deleteCompany', { id: company.id });
      await fetchCompanies();
      deleteCompanyDialog.value = false;
    } catch (error) {
      errorSnackbar.value.message = error.response.data.error;
      errorSnackbar.value.visible = true;
    }
  };

  const addNewExtinguisher = async () => {
    try {
      newExtinguisher.value.company_id = selectedCompany.value?.id || 0;
      await api.post('company/?action=addExtinguisher', newExtinguisher.value);
      await fetchExtinguishers(newExtinguisher.value.company_id);
      newExtinguisherDialog.value = false;
      newExtinguisher.value = {
        id: 0,
        identifier: '',
        company_id: 0,
        registration_date: '',
        last_inspection: '',
        inspected_by: '',
        location: '',
      };
    } catch (error) {
      errorSnackbar.value.message = error.response.data.error;
      errorSnackbar.value.visible = true;
    }
  };

  const updateExtinguisher = async () => {
    try {
      await api.post('company/?action=editExtinguisher', editedExtinguisher.value);
      await fetchExtinguishers(editedExtinguisher.value.company_id);
      editExtinguisherDialog.value = false;
    } catch (error) {
      errorSnackbar.value.message = error.response.data.error;
      errorSnackbar.value.visible = true;
    }
  };

  const updateFireExtinguisherNr = async () => {
    try {
      await api.post('company/?action=setFireExtinguisherNr', { extinguisherNr: extinguisherNr.value });
      await fetchExtinguisherNr();
    } catch (error) {
      errorSnackbar.value.message = error.response.data.error;
      errorSnackbar.value.visible = true;
    }
  };

  const deleteExtinguisher = async (extinguisher: Extinguisher) => {
    try {
      await api.post('company/?action=deleteExtinguisher', { id: extinguisher.id });
      await fetchExtinguishers(extinguisher.company_id);
      deleteExtinguisherDialog.value = false;
    } catch (error) {
      errorSnackbar.value.message = error.response.data.error;
      errorSnackbar.value.visible = true;
    }
  };

  const openNewCompanyDialog = () => {
    newCompanyDialog.value = true;
  };

  const openEditCompanyDialog = (company: Company) => {
    editedCompany.value = { ...company };
    editCompanyDialog.value = true;
  };

  const openDeleteCompanyDialog = (company: Company) => {
    selectedCompany.value = company;
    deleteCompanyDialog.value = true;
  };

  const viewCompanyDetails = async (company: Company) => {
    selectedCompany.value = { ...company };
    await fetchExtinguishers(company.id);
    companyDetailsDialog.value = true;
  };

  const openNewExtinguisherDialog = () => {
    newExtinguisherDialog.value = true;
  };

  const openEditExtinguisherDialog = (extinguisher: Extinguisher) => {
    editedExtinguisher.value = { ...extinguisher };
    editExtinguisherDialog.value = true;
  };

  const openDeleteExtinguisherDialog = (extinguisher: Extinguisher) => {
    selectedExtinguisher.value = extinguisher;
    deleteExtinguisherDialog.value = true;
  };

  const viewCompanyHistory = (company: Company) => {
    fetchCompanyHistory(company.id);
    // Show history in a new dialog or component
  };

  const openImagePreview = (url: string) => {
    imagePreviewUrl.value = url;
    imagePreviewDialog.value = true;
  };

  function isFormValid(form: any) {
    for (const key in form) {
      if (form[key] === '' || form[key] === null) return false;
    }
    return true;
  }

  const validateImage = (event: Event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file && !file.type.startsWith('image/')) {
      snackbar.value = {
        value: true,
        color: 'error',
        message: 'Nur Bilddateien sind erlaubt.',
      };
      editedCompany.value.image_file = null;
    }
  };

  function formatDate(date: any) {
    if (!date) return "";
    const [datePart] = date.split(" ");
    const [year, month, day] = datePart.split("-");
    return `${day}.${month}.${year}`;
  }

  onMounted(async () => {
    await fetchCompanies();
    await fetchCompanyTypes();
    await fetchExtinguisherNr();
  });

  return {
    companyHeaders,
    extinguisherHeaders,
    companies,
    companyTypes,
    extinguishers,
    companyHistories,
    newCompanyDialog,
    editCompanyDialog,
    deleteCompanyDialog,
    companyDetailsDialog,
    newExtinguisherDialog,
    editExtinguisherDialog,
    deleteExtinguisherDialog,
    imagePreviewDialog,
    imagePreviewUrl,
    selectedCompany,
    selectedExtinguisher,
    newCompany,
    editedCompany,
    newExtinguisher,
    editedExtinguisher,
    search,
    extinguisherSearch,
    snackbar,
    requiredRule,
    fetchCompanies,
    fetchExtinguishers,
    fetchCompanyHistory,
    addNewCompany,
    updateCompany,
    deleteCompany,
    addNewExtinguisher,
    updateExtinguisher,
    deleteExtinguisher,
    openNewCompanyDialog,
    openEditCompanyDialog,
    openDeleteCompanyDialog,
    viewCompanyDetails,
    openNewExtinguisherDialog,
    openEditExtinguisherDialog,
    openDeleteExtinguisherDialog,
    viewCompanyHistory,
    openImagePreview,
    isFormValid,
    validateImage,
    EditorClass,
    formatDate,
    filters,
    expiryFilterOptions,
    filteredCompanies,
    matchExpiryFilter,
    groupBy,
    extinguisherNr,
    updateFireExtinguisherNr
  };
},
});
</script>

<style scoped>
.float-right {
  float: right;
}
</style>

