import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ckeditor = _resolveComponent("ckeditor")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_btn, {
        onClick: _ctx.openCategorySelectionDialog,
        class: "float-right"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Neuer Bericht")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_v_btn, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterReports('all'))),
        class: "mr-2"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Alle (" + _toDisplayString(_ctx.reportCounts.all) + ")", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_btn, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterReports('own'))),
        class: "mr-2"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Eigene (" + _toDisplayString(_ctx.reportCounts.own) + ")", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_btn, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filterReports('missing'))),
        class: "mr-2"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Fehlende Berichtergänzungen (" + _toDisplayString(_ctx.reportCounts.missing) + ")", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_btn, {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.filterReports('incomplete'))),
        class: "mr-2"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Alle nichtvollständige (" + _toDisplayString(_ctx.reportCounts.incomplete) + ")", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_btn, {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.filterReports('open'))),
        class: "mr-2"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Offene Berichte (" + _toDisplayString(_ctx.reportCounts.open) + ")", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_btn, {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.filterReports('pending'))),
        class: "mr-2"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Ausstehende Prüfungen (" + _toDisplayString(_ctx.reportCounts.pending) + ")", 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_data_table, {
        headers: _ctx.reportHeaders,
        items: _ctx.filteredReports,
        class: "elevation-1 mt-2",
        search: _ctx.search,
        "items-per-page": 5
      }, {
        top: _withCtx(() => [
          _createVNode(_component_v_toolbar, { flat: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Berichtverwaltung")
                ]),
                _: 1
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.search) = $event)),
                label: "Suche",
                class: "mx-4 mt-3",
                variant: "underlined"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_v_select, {
                modelValue: _ctx.filters.category_id,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filters.category_id) = $event)),
                items: _ctx.categories,
                "item-title": "name",
                "item-value": "id",
                label: "Filter nach Abteilung",
                clearable: "",
                variant: "underlined",
                class: "mx-4 mt-3"
              }, null, 8, ["modelValue", "items"]),
              _createVNode(_component_v_select, {
                modelValue: _ctx.filters.creator,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filters.creator) = $event)),
                items: _ctx.employees,
                "item-title": "display_name",
                "item-value": "id",
                label: "Filter nach Ersteller",
                clearable: "",
                variant: "underlined",
                class: "mx-4 mt-3"
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          })
        ]),
        [`item.report_info`]: _withCtx(({ item }) => [
          _createElementVNode("span", {
            size: "small",
            class: "me-2",
            onClick: ($event: any) => (_ctx.openEditReportDialog(item)),
            style: {"cursor":"pointer"}
          }, [
            _createTextVNode(_toDisplayString(item.title ? item.title : _ctx.formatDate(item.report_date) + ' | ' + item.report_code_name + ' | ' + item.location) + " ", 1),
            (item.missing_employees.length > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(fehlende Aussagen: " + _toDisplayString(item.missing_employees.length) + ")", 1))
              : _createCommentVNode("", true)
          ], 8, _hoisted_1)
        ]),
        [`item.approved`]: _withCtx(({ item }) => [
          _createTextVNode(_toDisplayString(item.pinned == 1 ? '📌 ' : '') + _toDisplayString(item.approved == 0 ? '🔴' : '🟢'), 1)
        ]),
        [`item.actions`]: _withCtx(({ item }) => [
          _createVNode(_component_v_icon, {
            size: "small",
            class: "me-2",
            onClick: ($event: any) => (_ctx.openEditReportDialog(item))
          }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-pencil")
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_v_icon, {
            size: "small",
            class: "me-2",
            onClick: ($event: any) => (_ctx.openDeleteReportDialog(item))
          }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-delete")
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_v_icon, {
            size: "small",
            class: "me-2",
            onClick: ($event: any) => (_ctx.downloadReportPdf(item.id))
          }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-download")
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_v_icon, {
            size: "small",
            class: "me-2",
            onClick: ($event: any) => (_ctx.pinReport(item.id))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.pinned == 1 ? 'mdi-pin-off' : 'mdi-pin'), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 2
      }, 1032, ["headers", "items", "search"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.categorySelectionDialog,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.categorySelectionDialog) = $event)),
        "max-width": "400"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Kategorie auswählen")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    label: "Kategorie",
                    modelValue: _ctx.selectedCategory,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedCategory) = $event)),
                    items: _ctx.categories,
                    "item-title": "name",
                    "item-value": "id",
                    required: "",
                    rules: [_ctx.requiredRule]
                  }, null, 8, ["modelValue", "items", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.categorySelectionDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _ctx.createNewReport
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Weiter")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.newReportDialog,
        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.newReportDialog) = $event)),
        fullscreen: "",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.newReport.title ? _ctx.newReport.title : "Neuer Bericht"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, { ref: "newReportForm" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                label: "Ersteller",
                                modelValue: _ctx.newReport.creator,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.newReport.creator) = $event)),
                                items: _ctx.employees,
                                "item-title": "display_name",
                                "item-value": "id",
                                required: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "items", "rules"]),
                              _createVNode(_component_v_select, {
                                label: "Kategorie",
                                modelValue: _ctx.newReport.category_id,
                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.newReport.category_id) = $event)),
                                items: _ctx.categories,
                                "item-title": "name",
                                "item-value": "id",
                                required: "",
                                rules: [_ctx.requiredRule],
                                disabled: true
                              }, null, 8, ["modelValue", "items", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                label: "Datum",
                                modelValue: _ctx.newReport.report_date,
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.newReport.report_date) = $event)),
                                required: "",
                                rules: [_ctx.requiredRule],
                                type: "datetime-local"
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                label: "Report Code",
                                modelValue: _ctx.newReport.report_code_id,
                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.newReport.report_code_id) = $event)),
                                items: _ctx.reportCodes,
                                "item-title": "code",
                                "item-value": "id",
                                required: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "items", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                label: "Standort",
                                modelValue: _ctx.newReport.location,
                                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.newReport.location) = $event)),
                                required: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                label: "Titel",
                                modelValue: _ctx.newReport.title,
                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.newReport.title) = $event)),
                                required: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ckeditor, {
                                editor: _ctx.EditorClass,
                                config: _ctx.EditorClass.defaultConfig,
                                modelValue: _ctx.newReport.text,
                                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.newReport.text) = $event))
                              }, null, 8, ["editor", "config", "modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_textarea, {
                                label: "Beschreibung",
                                rows: "3",
                                modelValue: _ctx.newReport.description,
                                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.newReport.description) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                modelValue: _ctx.newReport.missing_employees,
                                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.newReport.missing_employees) = $event)),
                                items: _ctx.employees,
                                "item-title": "display_name",
                                "item-value": "id",
                                label: "Fehlende Berichtergänzungen",
                                multiple: "",
                                chips: "",
                                "closable-chips": ""
                              }, null, 8, ["modelValue", "items"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_checkbox, {
                                modelValue: _ctx.newReport.approved,
                                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.newReport.approved) = $event)),
                                label: "Freigegeben"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.newReportDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _ctx.addNewReport,
                    disabled: !_ctx.isFormValid(_ctx.newReport)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Hinzufügen")
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.editReportDialog,
        "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.editReportDialog) = $event)),
        fullscreen: "",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Bericht bearbeiten "),
                  _createVNode(_component_v_spacer)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, { ref: "editReportForm" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                label: "Ersteller",
                                modelValue: _ctx.editedReport.creator,
                                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editedReport.creator) = $event)),
                                items: _ctx.employees,
                                "item-title": "display_name",
                                "item-value": "id",
                                required: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "items", "rules"]),
                              _createVNode(_component_v_select, {
                                label: "Kategorie",
                                modelValue: _ctx.editedReport.category_id,
                                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.editedReport.category_id) = $event)),
                                items: _ctx.categories,
                                "item-title": "name",
                                "item-value": "id",
                                required: "",
                                rules: [_ctx.requiredRule],
                                disabled: true
                              }, null, 8, ["modelValue", "items", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                label: "Datum",
                                modelValue: _ctx.editedReport.report_date,
                                "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.editedReport.report_date) = $event)),
                                required: "",
                                rules: [_ctx.requiredRule],
                                type: "datetime-local"
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                label: "Report Code",
                                modelValue: _ctx.editedReport.report_code_id,
                                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.editedReport.report_code_id) = $event)),
                                items: _ctx.reportCodes,
                                "item-title": "code",
                                "item-value": "id",
                                required: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "items", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "4" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                label: "Standort",
                                modelValue: _ctx.editedReport.location,
                                "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.editedReport.location) = $event)),
                                required: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                label: "Titel",
                                modelValue: _ctx.editedReport.title,
                                "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.editedReport.title) = $event)),
                                required: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ckeditor, {
                                editor: _ctx.EditorClass,
                                config: _ctx.EditorClass.defaultConfig,
                                modelValue: _ctx.editedReport.text,
                                "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.editedReport.text) = $event))
                              }, null, 8, ["editor", "config", "modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_textarea, {
                                label: "Beschreibung",
                                rows: "3",
                                modelValue: _ctx.editedReport.description,
                                "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.editedReport.description) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                modelValue: _ctx.editedReport.missing_employees,
                                "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.editedReport.missing_employees) = $event)),
                                items: _ctx.employees,
                                "item-title": "display_name",
                                "item-value": "id",
                                label: "Fehlende Berichtergänzungen",
                                multiple: "",
                                chips: "",
                                "closable-chips": ""
                              }, null, 8, ["modelValue", "items"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_checkbox, {
                                modelValue: _ctx.editedReport.approved,
                                "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.editedReport.approved) = $event)),
                                label: "Freigegeben"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.editReportDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _ctx.updateReport
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Speichern")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.deleteReportDialog,
        "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.deleteReportDialog) = $event)),
        "max-width": "400",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Bericht löschen")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Möchten Sie den Bericht \"" + _toDisplayString(_ctx.selectedReport.title) + "\" wirklich löschen? ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[36] || (_cache[36] = ($event: any) => (_ctx.deleteReportDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[37] || (_cache[37] = ($event: any) => (_ctx.deleteReport(_ctx.selectedReport)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Löschen")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.snackbar.value,
        "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.snackbar.value) = $event)),
        color: _ctx.snackbar.color,
        timeout: 4000
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.snackbar.message), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }))
}