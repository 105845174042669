<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
        <v-row>
            <v-col cols="auto">
                <v-btn @click="openAddQuestionDialog()">Frage hinzufügen</v-btn>
                <v-btn @click="generateRandomTest()" class="ml-3">Test ausgeben</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="questionsAndAnswers"
            class="elevation-1 mt-3"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Test</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>
            <template v-slot:[`item.question`]="{ item }">
                <span>{{ item.question }}</span>
            </template>
            <template v-slot:[`item.isKO`]="{ item }">
                <v-icon v-if="item.isKO"> {{ item.isKO == 'true' ? 'mdi-check-bold' : item.isKO != '' ? '' : '' }}</v-icon>
            </template>

            <template v-slot:[`item.answer1`]="{ item }">
                <span>{{ item.answer1 }} {{ item.answer1_result == 'true' ? '🟢' : item.answer1 != '' ? '🔴' : '' }}</span>
            </template>
            <template v-slot:[`item.answer2`]="{ item }">
                <span>{{ item.answer2 }} {{ item.answer2_result == 'true' ? '🟢' : item.answer2 != '' ? '🔴' : '' }}</span>
            </template>
            <template v-slot:[`item.answer3`]="{ item }">
                <span>{{ item.answer3 }} {{ item.answer3_result == 'true' ? '🟢' : item.answer3 != '' ? '🔴' : '' }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    size="small"
                    class="me-2"
                    @click="openDeleteConfirmationDialog(item.id)"
                >
                    mdi-delete
                </v-icon>
                <v-icon
                    size="small"
                    class="me-2"
                    @click="openEditQuestionDialog(item)"
                >
                    mdi-pencil
                </v-icon>
            </template>
        </v-data-table>

        <v-dialog v-model="addQuestionDialog" width="600">
            <v-card>
                <v-card-title>Neue Frage hinzufügen</v-card-title>
                <v-card-text>
                    <v-form ref="addQuestionForm">
                        <v-row>
                            <v-col cols="12" md="9">
                                <v-text-field label="Frage" required v-model="newQuestion.question"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-checkbox label="K.O. Frage?" v-model="newQuestion.isKO"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-text-field label="Antwort 1" required v-model="newQuestion.answer1"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-checkbox label="Richtig?" v-model="newQuestion.answer1_result"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-text-field label="Antwort 2" required v-model="newQuestion.answer2"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-checkbox label="Richtig?" v-model="newQuestion.answer2_result"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-text-field label="Antwort 3" required v-model="newQuestion.answer3"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-checkbox label="Richtig?" v-model="newQuestion.answer3_result"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="addQuestionDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="submitNewQuestion">Hinzufügen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteConfirmationDialog" width="500">
            <v-card>
                <v-card-title>Frage löschen</v-card-title>
                <v-card-text>
                    Bist du sicher, dass du die Frage löschen willst?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text="true" @click="deleteConfirmationDialog = false">Nein</v-btn>
                    <v-btn color="error" text="true" @click="deleteQuestion">Ja</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editQuestionDialog" width="600">
            <v-card>
                <v-card-title>Bearbeite Frage</v-card-title>
                <v-card-text>
                    <v-form ref="editQuestionForm">
                        <v-row>
                            <v-col cols="12" md="9">
                                <v-text-field label="Frage" required v-model="editingQuestion.question"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-checkbox label="K.O. Frage?" v-model="editingQuestion.isKO"></v-checkbox>
                            </v-col>


                            <v-col cols="12" md="9">
                                <v-text-field label="Antwort 1" required v-model="editingQuestion.answer1"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-checkbox label="Richtig?" v-model="editingQuestion.answer1_result"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-text-field label="Antwort 2" required v-model="editingQuestion.answer2"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-checkbox label="Richtig?" v-model="editingQuestion.answer2_result"></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-text-field label="Antwort 3" required v-model="editingQuestion.answer3"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-checkbox label="Richtig?" v-model="editingQuestion.answer3_result"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editQuestionDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="submitEditedQuestion">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-btn v-if="testQuestions.length > 0" @click="copySolutionsWithoutUnicode()" class="mat-6 mt-3">Lösungen ohne Unicode kopieren</v-btn>
        <v-btn v-if="testQuestions.length > 0" @click="copySolutions()" class="mat-6 ml-3  mt-3">Lösungen kopieren</v-btn>
        <v-btn v-if="testQuestions.length > 0" @click="copyTest()" class="mat-6 ml-3  mt-3">Test kopieren</v-btn>

        <v-container v-if="testQuestions.length > 0" class="pat-6 mt-3" style="background-color: #212121;">
            <v-row v-for="(question, index) in testQuestions" :key="index">
                <v-col>
                    <p>{{ index + 1 }}. {{ question.question }}</p>
                    <div v-for="(answer, aIndex) in question.answers" :key="aIndex">
                        <span v-if="answer.result == '1'">🟢</span>
                        <span v-else>🔴</span>
                        {{ answer.answer }}
                    </div>
                </v-col>
            </v-row>
        </v-container>




    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref, Ref, computed, watch } from 'vue';
  import api from '@/api';
  import { useRoute } from 'vue-router';
  import store from '@/store';
  
  export default defineComponent({
    components: {
    },
    setup() {
        const route = useRoute();
        const addQuestionDialog = ref(false);

        const questionsAndAnswers = ref([]);



        const headers = [
            { title: 'Frage', align: 'start', sortable: true, key: 'question' },
            { title: 'K.O.', align: 'start', sortable: true, key: 'isKO' },
            { title: 'Antwort 1', align: 'start', sortable: true, key: 'answer1' },
            { title: 'Antwort 2', align: 'start', sortable: false, key: 'answer2' },
            { title: 'Antwort 3', align: 'start', sortable: false, key: 'answer3' },
            { title: 'Actions', key: 'actions', sortable: false }
        ];

        const errorSnackbar = ref<any>({ visible: false, message: "" });



        async function fetchQuestionsAndAnswers() {
            try {
                const response = await api.post("test/?action=getQuestionsAndAnswers");
                questionsAndAnswers.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        const newQuestion = ref({
            question: '',
            answer1: '',
            answer1_result: false,
            answer2: '',
            answer2_result: false,
            answer3: '',
            answer3_result: false,
            isKO: false
        });

        function openAddQuestionDialog() {
            addQuestionDialog.value = true;
        }

        async function submitNewQuestion() {
            try {
                // Anpassen des Objekts, um es an die API zu senden

                let response = await api.post("test/?action=addQuestion", newQuestion.value);

                if (response.data.success) {
                    fetchQuestionsAndAnswers(); // Aktualisieren der Fragen und Antworten nach dem Hinzufügen der neuen Frage
                    addQuestionDialog.value = false;

                    // Zurücksetzen des newQuestion-Objekts
                    newQuestion.value = {
                        question: '',
                        answer1: '',
                        answer1_result: false,
                        answer2: '',
                        answer2_result: false,
                        answer3: '',
                        answer3_result: false,
                        isKO : false
                    };
                } else {
                    errorSnackbar.value.message = response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        const editQuestionDialog = ref(false);
        const editingQuestion = ref({
            id: null,
            question: '',
            answer1: '',
            answer1_result: false,
            answer2: '',
            answer2_result: false,
            answer3: '',
            answer3_result: false,
            isKO: false
        });

        function openEditQuestionDialog(item) {
            editingQuestion.value = {
                id: item.id, // Stellen Sie sicher, dass die ID hier enthalten ist
                question: item.question,
                answer1: item.answer1,
                answer1_result: item.answer1_result === 'true',
                answer2: item.answer2,
                answer2_result: item.answer2_result === 'true',
                answer3: item.answer3,
                answer3_result: item.answer3_result === 'true',
                isKO: item.isKO === 'true'
            };
            editQuestionDialog.value = true;
        }

        async function submitEditedQuestion() {
            try {
                let response = await api.post("test/?action=editQuestion", editingQuestion.value);

                if (response.data.success) {
                    fetchQuestionsAndAnswers();
                    editQuestionDialog.value = false;
                } else {
                    errorSnackbar.value.message = response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }


        const deleteConfirmationDialog = ref(false);
        const questionToDeleteId = ref(null);

        function openDeleteConfirmationDialog(id) {
            questionToDeleteId.value = id;
            deleteConfirmationDialog.value = true;
        }

        async function deleteQuestion() {
            if (questionToDeleteId.value) {
                try {
                    let response = await api.post("test/?action=deleteQuestion", { id: questionToDeleteId.value });

                    if (response.data.success) {
                        fetchQuestionsAndAnswers();
                        deleteConfirmationDialog.value = false;
                        questionToDeleteId.value = null;
                    } else {
                        errorSnackbar.value.message = response.data.error;
                        errorSnackbar.value.visible  = true;
                    }
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
        }

        const testQuestions = ref([]);

        async function generateRandomTest() {
            try {
                let response = await api.post("test/?action=generateRandomTest");

                if (response.data) {
                    testQuestions.value = response.data;
                    // Sie können hier auch Logik hinzufügen, um zwischen den Ansichten umzuschalten
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }



        function formatSolutionsForCopy() {
            return testQuestions.value.map((q, index) => {
                const answers = q.answers.map(a => `${a.result == '1' ? '🟢' : '🔴'} ${a.answer}`).join('\n');
                return `${index + 1}. ${q.question}\n${answers}`;
            }).join('\n\n');
        }

        function formatSolutionsForCopyWithoutUnicode() {
            return testQuestions.value.map((q, index) => {
                const answers = q.answers.map(a => `${a.result == '1' ? '(x)' : '( )'} ${a.answer}`).join('\n');
                return `${index + 1}. ${q.question}\n${answers}`;
            }).join('\n\n');
        }

        function formatTestForCopy() {
            return testQuestions.value.map((q, index) => {
                const answers = q.answers.map(a => `( ) ${a.answer}`).join('\n');
                return `${index + 1}. ${q.question}\n${answers}`;
            }).join('\n\n');
        }

        async function copySolutions() {
            try {
                const textToCopy = formatSolutionsForCopy();
                await navigator.clipboard.writeText(textToCopy);
                errorSnackbar.value.message = 'Lösungen erfolgreich kopiert.';
                errorSnackbar.value.visible  = true;
                errorSnackbar.value.color  = 'green';
            } catch (err) {
                errorSnackbar.value.message = 'Lösungen konnten nicht kopiert werden.';
                errorSnackbar.value.visible  = true;
            }
        }

        async function copySolutionsWithoutUnicode() {
            try {
                const textToCopy = formatSolutionsForCopyWithoutUnicode();
                await navigator.clipboard.writeText(textToCopy);
                errorSnackbar.value.message = 'Lösungen erfolgreich kopiert.';
                errorSnackbar.value.visible  = true;
                errorSnackbar.value.color  = 'green';
            } catch (err) {
                errorSnackbar.value.message = 'Lösungen konnten nicht kopiert werden.';
                errorSnackbar.value.visible  = true;
            }
        }

        async function copyTest() {
            try {
                const textToCopy = formatTestForCopy();
                await navigator.clipboard.writeText(textToCopy);
                errorSnackbar.value.message = 'Prüfung erfolgreich kopiert.';
                errorSnackbar.value.visible  = true;
                errorSnackbar.value.color  = 'green';
            } catch (err) {
                errorSnackbar.value.message = 'Prüfung konnte nicht kopiert werden.';
                errorSnackbar.value.visible  = true;
            }
        }

        onMounted(() => {
            fetchQuestionsAndAnswers();
        });



        return{
            headers,
            errorSnackbar,
            deleteConfirmationDialog,
            addQuestionDialog,
            questionsAndAnswers,
            newQuestion,
            openAddQuestionDialog,
            submitNewQuestion,
            openEditQuestionDialog,
            submitEditedQuestion,
            editQuestionDialog,
            editingQuestion,
            openDeleteConfirmationDialog,
            deleteQuestion,
            generateRandomTest,
            testQuestions,
            copySolutions,
            copyTest,
            formatSolutionsForCopyWithoutUnicode,
            copySolutionsWithoutUnicode
        }
    }
  });
  </script>
  <style>



</style>