<template>
  <error-snackbar v-model:snackbar="errorSnackbar" />
  <v-container fluid>
    <v-row>
      <v-col>
        <h3>Map Categories</h3>
        <v-btn @click="openNewCategoryDialog">Add New Category</v-btn>
        <v-data-table :headers="categoryHeaders" :items="categories" :item-key="'id'" class="mt-4">
          <template v-slot:[`item.icon`]="{ item }">
            <img :src="`/img/mapIcons/${item.icon}.png`" width="20" height="20" />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="openEditCategoryDialog(item)">mdi-pencil</v-icon>
            <v-icon small @click="confirmDelete(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="selectedItem.name" label="Name" required></v-text-field>
              </v-col>
              <v-col cols="12">
                  <v-select
                  v-model="selectedItem.icon"
                  :items="availableIcons"
                  label="Icon"
                  required
                  >
                  <template v-slot:item="{ item }">
                      <v-list-item @click="selectedItem.icon = item.raw.name">
                      <template v-slot:prepend>
                          <v-avatar>
                          <img :src="`/img/mapIcons/${item.raw.filename}`" width="20" height="20" />
                          </v-avatar>
                      </template>
                      <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                      </v-list-item>
                  </template>

                  <template v-slot:selection="{ item }">
                      <v-chip class="ma-2" v-if="selectedItem.icon">
                      <v-avatar left>
                          <img :src="`/img/mapIcons/${item.raw}.png`" width="20" height="20" />
                      </v-avatar>
                      {{ item.raw }}
                      </v-chip>
                  </template>
                  </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="saveItem">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" persistent max-width="300px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Löschen bestätigen</span>
        </v-card-title>
        <v-card-text>Willst du das wirklich löschen?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="proceedWithDelete">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import api from '@/api';
import axios from 'axios';
import { Categories } from '@/types/Map';

export default defineComponent({
  setup() {
      const errorSnackbar = ref<any>({ visible: false, message: "" });
      const categories = ref<Categories[]>([]);

      const fetchCategories = async () => {
          try {
              const response = await api.get('admin/map/?action=getCategories');
              categories.value = response.data;
          } catch (error) {
              errorSnackbar.value.message = error.response.data.error;
              errorSnackbar.value.visible  = true;
          }
      };

  const editDialog = ref(false);
  const selectedItem = ref(null);
  const formTitle = ref('');

  const categoryHeaders = [
      { title: 'ID', key: 'id', align: 'start' },
      { title: 'Name', key: 'name' },
      { title: 'Aktuelle Elemente', key: 'marker_count' },
      { title: 'Icon', key: 'icon' },
      { title: 'Actions', key: 'actions' },
  ];

  const openEditCategoryDialog = (category) => {
      selectedItem.value = category;
      formTitle.value = 'Kategorie bearbeiten';
      editDialog.value = true;
  };

  const saveItem = async () => {
      try {
          let response;
          response = await api.post("admin/map/?action=saveCategory", selectedItem.value);
          console.log('Saving category:', selectedItem.value);
          editDialog.value = false;
          await fetchCategories();
      } catch (error) {
          errorSnackbar.value.message = error.response.data.error;
          errorSnackbar.value.visible  = true;
      }
  };

  const proceedWithDelete = async () => {
      try {
          const response = await api.post("admin/map/?action=deleteCategory", { id: itemToDelete.value });
          console.log('Deleting category:', itemToDelete.value);
          confirmDialog.value = false;
          await fetchCategories();
      } catch (error) {
          errorSnackbar.value.message = error.response.data.error;
          errorSnackbar.value.visible  = true;
      }
  };


  const openNewCategoryDialog = () => {
    selectedItem.value = { name: '', icon: '' };
    formTitle.value = 'Neue Kategorie hinzufügen';
    editDialog.value = true;
  };

  const confirmDialog = ref(false);
  const itemToDelete = ref(null);

  const confirmDelete = (item) => {
    itemToDelete.value = item;
    confirmDialog.value = true;
  };

  const availableIcons = ref([]);

  const fetchAvailableIconsProd = async () => {
      try {
          const response = await axios.get('/mapIcons.php');
          availableIcons.value = response.data;
      } catch (error) {
          console.error('Error fetching available icons:', error);
      }
  };

  const fetchAvailableIconsDev = async () => {
      try {
          const response = await axios.get('/img/mapIcons/');
          availableIcons.value = response.data
          .filter((filename) => /\.png$/i.test(filename))
          .map((filename) => {
              const fileInfo = filename.split('.');
              const name = fileInfo[0];
              return { name, filename };
          });
      } catch (error) {
          console.error('Error fetching available icons:', error);
      }
  };

  const fetchAvailableIcons = () => {
      if (process.env.NODE_ENV == 'development') {
          fetchAvailableIconsDev();
      } else {
          fetchAvailableIconsProd();
      }
  };


  onMounted(async () => {
    await fetchCategories();
    await fetchAvailableIcons();
  });

  return {
    categories,
    categoryHeaders,
    editDialog,
    selectedItem,
    formTitle,
    openEditCategoryDialog,
    saveItem,
    openNewCategoryDialog,
    confirmDialog,
    itemToDelete,
    confirmDelete,
    proceedWithDelete,
    availableIcons,
    errorSnackbar,
  };
},
});
</script>

<style scoped>
</style>
