import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["src"]
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_data_table, {
          headers: _ctx.headers,
          items: _ctx.users,
          class: "elevation-1"
        }, {
          top: _withCtx(() => [
            _createVNode(_component_v_toolbar, { flat: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("User Management")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider, {
                  class: "mx-4",
                  inset: "",
                  vertical: ""
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newUserDialog = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Neuer Benutzer")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          [`item.actions`]: _withCtx(({ item }) => [
            _createVNode(_component_v_icon, {
              size: "small",
              class: "me-2",
              onClick: ($event: any) => (_ctx.openEditUserDialog(item))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" mdi-pencil ")
              ]),
              _: 2
            }, 1032, ["onClick"]),
            (item.banned == 0)
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 0,
                  size: "small",
                  onClick: ($event: any) => (_ctx.openBannDialog(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-account-cancel ")
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : (_openBlock(), _createBlock(_component_v_icon, {
                  key: 1,
                  size: "small",
                  onClick: ($event: any) => (_ctx.openUnbannDialog(item))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-lock-open-variant ")
                  ]),
                  _: 2
                }, 1032, ["onClick"])),
            _createVNode(_component_v_icon, {
              size: "small",
              class: "me-2",
              onClick: ($event: any) => (_ctx.openResetPasswordDialog(item))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" mdi-key ")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          [`item.mail_header`]: _withCtx(({ item }) => [
            (item.mail_header)
              ? (_openBlock(), _createBlock(_component_v_tooltip, { key: 0 }, {
                  activator: _withCtx(({ props }) => [
                    _createElementVNode("span", _mergeProps(props, {
                      style: {"cursor":"pointer","text-decoration":"underline"},
                      onClick: ($event: any) => (_ctx.copyLinkToClipboard(item.mail_header))
                    }), " Header ", 16, _hoisted_1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: item.mail_header,
                      alt: "Mail Header"
                    }, null, 8, _hoisted_2)
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "-"))
          ]),
          [`item.mail_footer`]: _withCtx(({ item }) => [
            (item.mail_footer)
              ? (_openBlock(), _createBlock(_component_v_tooltip, { key: 0 }, {
                  activator: _withCtx(({ props }) => [
                    _createElementVNode("span", _mergeProps(props, {
                      style: {"cursor":"pointer","text-decoration":"underline"},
                      onClick: ($event: any) => (_ctx.copyLinkToClipboard(item.mail_footer))
                    }), " Footer ", 16, _hoisted_4)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: item.mail_footer,
                      alt: "Mail Footer"
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, "-"))
          ]),
          [`item.mail_header_neutral`]: _withCtx(({ item }) => [
            (item.mail_header_neutral)
              ? (_openBlock(), _createBlock(_component_v_tooltip, { key: 0 }, {
                  activator: _withCtx(({ props }) => [
                    _createElementVNode("span", _mergeProps(props, {
                      style: {"cursor":"pointer","text-decoration":"underline"},
                      onClick: ($event: any) => (_ctx.copyLinkToClipboard(item.mail_header_neutral))
                    }), " Header ", 16, _hoisted_7)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: item.mail_header_neutral,
                      alt: "Mail Header Neutral"
                    }, null, 8, _hoisted_8)
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createElementBlock("span", _hoisted_9, "-"))
          ]),
          [`item.mail_footer_neutral`]: _withCtx(({ item }) => [
            (item.mail_footer_neutral)
              ? (_openBlock(), _createBlock(_component_v_tooltip, { key: 0 }, {
                  activator: _withCtx(({ props }) => [
                    _createElementVNode("span", _mergeProps(props, {
                      style: {"cursor":"pointer","text-decoration":"underline"},
                      onClick: ($event: any) => (_ctx.copyLinkToClipboard(item.mail_footer_neutral))
                    }), " Footer ", 16, _hoisted_10)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: item.mail_footer_neutral,
                      alt: "Mail Footer Neutral"
                    }, null, 8, _hoisted_11)
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createElementBlock("span", _hoisted_12, "-"))
          ]),
          [`item.signature`]: _withCtx(({ item }) => [
            (item.signature)
              ? (_openBlock(), _createBlock(_component_v_tooltip, { key: 0 }, {
                  activator: _withCtx(({ props }) => [
                    _createElementVNode("span", _mergeProps(props, {
                      style: {"cursor":"pointer","text-decoration":"underline"},
                      onClick: ($event: any) => (_ctx.copyLinkToClipboard(item.signature))
                    }), " Signature ", 16, _hoisted_13)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: item.signature,
                      alt: "signature"
                    }, null, 8, _hoisted_14)
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createElementBlock("span", _hoisted_15, "-"))
          ]),
          _: 2
        }, 1032, ["headers", "items"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.bannDialog,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.bannDialog) = $event)),
          "max-width": "400",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Benutzer bannen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Möchtest du den Benutzer \"" + _toDisplayString(_ctx.selectedUser.username) + "\" wirklich bannen? ", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.bannDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.bannUser(_ctx.selectedUser)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Bannen")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.unbannDialog,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.unbannDialog) = $event)),
          "max-width": "400",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Benutzer entbannen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Möchtest du den Benutzer \"" + _toDisplayString(_ctx.selectedUser.username) + "\" wirklich entbannen? ", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.unbannDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.unbannUser(_ctx.selectedUser)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Entbannen")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.newUserDialog,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.newUserDialog) = $event)),
          "max-width": "500",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Neuer Benutzer")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "newUserForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: "Benutzername",
                          modelValue: _ctx.newUser.username,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newUser.username) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          label: "E-Mail",
                          modelValue: _ctx.newUser.email,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.newUser.email) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_select, {
                          label: "Gruppen",
                          modelValue: _ctx.newUser.groups,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.newUser.groups) = $event)),
                          items: _ctx.groups,
                          "item-title": "name",
                          "item-value": "id",
                          multiple: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "items", "rules"]),
                        _createVNode(_component_v_text_field, {
                          label: "Passwort",
                          modelValue: _ctx.newUser.password,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.newUser.password) = $event)),
                          type: "password",
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"])
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.newUserDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.addNewUser,
                      disabled: !_ctx.isFormValid()
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Hinzufügen")
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.editUserDialog,
          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editUserDialog) = $event)),
          "max-width": "500",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Benutzer bearbeiten")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "editUserForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: "Benutzername",
                          modelValue: _ctx.editedUser.username,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editedUser.username) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          label: "E-Mail",
                          modelValue: _ctx.editedUser.email,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editedUser.email) = $event)),
                          required: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_select, {
                          label: "Mitarbeiter",
                          modelValue: _ctx.editedUser.linked_employee,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editedUser.linked_employee) = $event)),
                          items: _ctx.Employees,
                          "item-title": "name",
                          "item-value": "id"
                        }, null, 8, ["modelValue", "items"]),
                        _createVNode(_component_v_select, {
                          label: "Gruppen",
                          modelValue: _ctx.editedUser.allGroups,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editedUser.allGroups) = $event)),
                          items: _ctx.groups,
                          "item-title": "name",
                          "item-value": "id",
                          multiple: "",
                          rules: [_ctx.requiredRule]
                        }, null, 8, ["modelValue", "items", "rules"]),
                        _createVNode(_component_v_text_field, {
                          label: "Mail Header",
                          modelValue: _ctx.editedUser.mail_header,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editedUser.mail_header) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_text_field, {
                          label: "Mail Footer",
                          modelValue: _ctx.editedUser.mail_footer,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editedUser.mail_footer) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_text_field, {
                          label: "Mail Header Neutral",
                          modelValue: _ctx.editedUser.mail_header_neutral,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editedUser.mail_header_neutral) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_text_field, {
                          label: "Mail Footer Neutral",
                          modelValue: _ctx.editedUser.mail_footer_neutral,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.editedUser.mail_footer_neutral) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_text_field, {
                          label: "signature",
                          modelValue: _ctx.editedUser.signature,
                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.editedUser.signature) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.editUserDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.updateUser
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Speichern")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.resetPasswordDialog,
          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.resetPasswordDialog) = $event)),
          "max-width": "500",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Passwort zurücksetzen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "editUserForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          label: "Benutzername",
                          modelValue: _ctx.editedUser.username,
                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.editedUser.username) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_text_field, {
                          type: "password",
                          label: "Password",
                          modelValue: _ctx.passwordReset.password,
                          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.passwordReset.password) = $event)),
                          required: "",
                          rules: [_ctx.passwordRules.required, _ctx.passwordRules.minLength]
                        }, null, 8, ["modelValue", "rules"]),
                        _createVNode(_component_v_text_field, {
                          type: "password",
                          label: "Password wiederholen",
                          modelValue: _ctx.passwordReset.passwordRetry,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.passwordReset.passwordRetry) = $event)),
                          required: "",
                          rules: [_ctx.passwordRules.required, _ctx.passwordRules.matchPassword, _ctx.passwordRules.minLength]
                        }, null, 8, ["modelValue", "rules"])
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.resetPasswordDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.resetPassword
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Speichern")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_snackbar, {
          modelValue: _ctx.snackbar.value,
          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.snackbar.value) = $event)),
          color: _ctx.snackbar.color,
          timeout: 4000
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.snackbar.message), 1)
          ]),
          _: 1
        }, 8, ["modelValue", "color"])
      ]),
      _: 1
    })
  ], 64))
}