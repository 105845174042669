<template>
    <v-snackbar v-model="localSnackbar.visible" top :color="snackbarColor" right>
      {{ localSnackbar.message }}
    </v-snackbar>
  </template>
  
  <script lang="ts">
  import { ref, computed, defineComponent } from 'vue';
  
  export default defineComponent({
    props: {
      snackbar: {
        type: Object,
        default: () => ({ visible: false, message: '', color: '' }),
      },
    },
    setup(props, {emit}) {
      const localSnackbar = computed({
        get: () => props.snackbar,
        set: (value) => emit('update:snackbar', value),
      });
  
      const snackbarColor = computed(() => {
        return localSnackbar.value.color || 'red';
      });
  
      return { localSnackbar, snackbarColor };
    },
  });
  </script>
  