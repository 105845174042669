<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Trainings</h3>
          <v-btn @click="openNewTrainingDialog">Add New Training</v-btn>
          <v-data-table
            :headers="trainingHeaders"
            :items="trainings"
            :item-key="'id'"
            class="mt-4"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="openEditTrainingDialog(item)">mdi-pencil</v-icon>
              <v-icon small @click="confirmDelete(item, 'training')">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
  
        <v-col>
          <h3>Kategorien</h3>
          <v-btn @click="openNewCategoryDialog">Add New Category</v-btn>
          <v-data-table
            :headers="categoryHeaders"
            :items="categories"
            :item-key="'id'"
            class="mt-4"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="openEditCategoryDialog(item)">mdi-pencil</v-icon>
              <v-icon small @click="confirmDelete(item, 'category')">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
  
      <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ itemType === 'training' ? 'Training bearbeiten' : 'Kategorie bearbeiten' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="selectedItem.name" label="Name" required></v-text-field>
              </v-col>
              <v-col cols="12" v-if="itemType === 'category'">
                <v-text-field v-model="selectedItem.short" label="Kürzel" required></v-text-field>
              </v-col>
              <v-col cols="12" v-if="itemType === 'training'">
                <v-select
                  v-model="selectedItem.catId"
                  :items="categories"
                  item-title="name"
                  item-value="id"
                  label="Kategorie"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="selectedItem.sort_order" label="Sortierung" type="number" required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="saveItem">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>

    <v-dialog v-model="confirmDialog" persistent max-width="300px">
        <v-card>
            <v-card-title>
            <span class="text-h5">Löschen bestätigen</span>
            </v-card-title>
            <v-card-text>
            Willst du das wirklich löschen?
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="confirmDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="proceedWithDelete">Löschen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import { EmployeeTraining, EmployeeTrainingCat } from '@/types/Members';
  import api from '@/api';
  
  export default defineComponent({
    components: {  },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const trainings = ref<EmployeeTraining[]>([]);
        const categories = ref<EmployeeTrainingCat[]>([]);
  
        const fetchTrainings = async () => {
            try {
                const response = await api.post("admin/training/?action=getTrainings");
                trainings.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };
        const fetchCategories = async () => {
            try {
                const response = await api.post("admin/training/?action=getCategories");
                categories.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };
  
        const editDialog = ref(false);
        const selectedItem = ref(null);
        const itemType = ref('');
    
        const trainingHeaders = [
            { title: 'ID', key: 'id', align: 'start' },
            { title: 'Name', key: 'name' },
            { title: 'Kategorie', key: 'cat_short', align: 'start' },
            { title: 'Sort Order', key: 'sort_order' },
            { title: 'Actions', key: 'actions' },
        ];
    
        const categoryHeaders = [
            { title: 'ID', key: 'id', align: 'start' },
            { title: 'Name', key: 'name' },
            { title: 'Short', key: 'short' },
            { title: 'Sort Order', key: 'sort_order' },
            { title: 'Actions', key: 'actions' },
        ];
    
        const openEditTrainingDialog = (training) => {
            selectedItem.value = training;
            itemType.value = 'training';
            editDialog.value = true;
        };

        const openEditCategoryDialog = (category) => {
            selectedItem.value = category;
            itemType.value = 'category';
            editDialog.value = true;
        };

        const saveItem = async () => {
            try {
                if (itemType.value === 'training') {
                    const response = await api.post("admin/training/?action=saveTrainings", selectedItem.value);
                } else if (itemType.value === 'category') {
                    const response = await api.post("admin/training/?action=saveCategories", selectedItem.value);
                }
                editDialog.value = false;
                await fetchTrainings();
                await fetchCategories();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const deleteTraining = async (training) => {
            try {
                const response = await api.post("admin/training/?action=deleteTrainings", { id: training.id });
                await fetchTrainings();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const deleteCategory = async (category) => {
            try {
                const response = await api.post("admin/training/?action=deleteCategories", { id: category.id });
                await fetchCategories();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };



    const openNewTrainingDialog = () => {
        selectedItem.value = { name: '', sort_order: 0 };
        itemType.value = 'training';
        editDialog.value = true;
    };

    const openNewCategoryDialog = () => {
        selectedItem.value = { name: '', sort_order: 0 };
        itemType.value = 'category';
        editDialog.value = true;
    };

    onMounted(async () => {
        await fetchTrainings();
        await fetchCategories();
    });


    const confirmDialog = ref(false);
    const itemToDelete = ref(null);

    const confirmDelete = (item, type) => {
        itemToDelete.value = { item, type };
        confirmDialog.value = true;
    };

    const proceedWithDelete = async () => {
        try {
            if (itemToDelete.value.type === 'training') {
                await deleteTraining(itemToDelete.value.item);
            } else if (itemToDelete.value.type === 'category') {
                await deleteCategory(itemToDelete.value.item);
            }
            confirmDialog.value = false;
        } catch (error) {
            console.error("Error deleting item:", error);
        }
    };

    return {
        trainings,
        categories,
        trainingHeaders,
        categoryHeaders,
        editDialog,
        selectedItem,
        itemType,
        openEditTrainingDialog,
        openEditCategoryDialog,
        deleteTraining,
        deleteCategory,
        saveItem,
        openNewTrainingDialog,
        openNewCategoryDialog,
        confirmDialog,
        itemToDelete,
        confirmDelete,
        proceedWithDelete,
        errorSnackbar,
    };
  },
});
</script>

<style scoped>
</style>
