<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Companies</h3>
          <v-btn @click="openNewCompanyDialog">Add New Company</v-btn>
          <v-data-table
            :headers="companyHeaders"
            :items="companies"
            :item-key="'id'"
            class="mt-4"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="openEditCompanyDialog(item)">mdi-pencil</v-icon>
              <v-icon small @click="confirmDelete(item, 'company')">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
  
        <v-col>
          <h3>Departments</h3>
          <v-btn @click="openNewDepartmentDialog">Add New Department</v-btn>
          <v-data-table
            :headers="departmentHeaders"
            :items="departments"
            :item-key="'id'"
            class="mt-4"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="openEditDepartmentDialog(item)">mdi-pencil</v-icon>
              <v-icon small @click="confirmDelete(item, 'department')">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
  
      <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ itemType === 'company' ? 'Company bearbeiten' : 'Department bearbeiten' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="selectedItem.name" label="Name" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="selectedItem.sort_order" label="Sortierung" type="number" required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="saveItem">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>

    <v-dialog v-model="confirmDialog" persistent max-width="300px">
        <v-card>
            <v-card-title>
            <span class="text-h5">Löschen bestätigen</span>
            </v-card-title>
            <v-card-text>
            Willst du das wirklich löschen?
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="confirmDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="proceedWithDelete">Löschen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import { EmployeeCompany, EmployeeDepartment } from '@/types/Members';
  import api from '@/api';
  
  export default defineComponent({
    components: {  },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const companies = ref<EmployeeCompany[]>([]);
        const departments = ref<EmployeeDepartment[]>([]);
  
        const fetchCompanies = async () => {
            try {
                const response = await api.post("admin/employee/?action=getCompanies");
                companies.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };
        const fetchDepartments = async () => {
            try {
                const response = await api.post("admin/employee/?action=getDepartments");
                departments.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };
  
        const editDialog = ref(false);
        const selectedItem = ref(null);
        const itemType = ref('');
    
        const companyHeaders = [
            { title: 'ID', key: 'id', align: 'start' },
            { title: 'Name', key: 'name' },
            { title: 'Sort Order', key: 'sort_order' },
            { title: 'Actions', key: 'actions' },
        ];
    
        const departmentHeaders = [
            { title: 'ID', key: 'id', align: 'start' },
            { title: 'Name', key: 'name' },
            { title: 'Sort Order', key: 'sort_order' },
            { title: 'Actions', key: 'actions' },
        ];
    
        const openEditCompanyDialog = (company) => {
            selectedItem.value = company;
            itemType.value = 'company';
            editDialog.value = true;
        };

        const openEditDepartmentDialog = (department) => {
            selectedItem.value = department;
            itemType.value = 'department';
            editDialog.value = true;
        };

        const saveItem = async () => {
            try {
                if (itemType.value === 'company') {
                    const response = await api.post("admin/employee/?action=saveCompany", selectedItem.value);
                } else if (itemType.value === 'department') {
                    const response = await api.post("admin/employee/?action=saveDepartment", selectedItem.value);
                }
                editDialog.value = false;
                await fetchCompanies();
                await fetchDepartments();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const deleteCompany = async (company) => {
            try {
                const response = await api.post("admin/employee/?action=deleteCompany", { id: company.id });
                await fetchCompanies();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const deleteDepartment = async (department) => {
            try {
                const response = await api.post("admin/employee/?action=deleteDepartment", { id: department.id });
                await fetchDepartments();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };



    const openNewCompanyDialog = () => {
        selectedItem.value = { name: '', sort_order: 0 };
        itemType.value = 'company';
        editDialog.value = true;
    };

    const openNewDepartmentDialog = () => {
        selectedItem.value = { name: '', sort_order: 0 };
        itemType.value = 'department';
        editDialog.value = true;
    };

    onMounted(async () => {
        await fetchCompanies();
        await fetchDepartments();
    });


    const confirmDialog = ref(false);
    const itemToDelete = ref(null);

    const confirmDelete = (item, type) => {
        itemToDelete.value = { item, type };
        confirmDialog.value = true;
    };

    const proceedWithDelete = async () => {
        try {
            if (itemToDelete.value.type === 'company') {
                await deleteCompany(itemToDelete.value.item);
            } else if (itemToDelete.value.type === 'department') {
                await deleteDepartment(itemToDelete.value.item);
            }
            confirmDialog.value = false;
        } catch (error) {
            console.error("Error deleting item:", error);
        }
    };

    return {
        companies,
        departments,
        companyHeaders,
        departmentHeaders,
        editDialog,
        selectedItem,
        itemType,
        openEditCompanyDialog,
        openEditDepartmentDialog,
        deleteCompany,
        deleteDepartment,
        saveItem,
        openNewCompanyDialog,
        openNewDepartmentDialog,
        confirmDialog,
        itemToDelete,
        confirmDelete,
        proceedWithDelete,
        errorSnackbar,
    };
  },
});
</script>

<style scoped>
</style>
