<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />

        <v-col
          cols="3"
          sm="3"
          md="3"
          lg="3"
          xl="3"
          v-if="!showRecentReturns && !showRecentVacations && currentVacation || 
          (showRecentReturns && recentlyReturnedFromVacation.length > 0 && !currentVacation) ||
          (showRecentVacations && upcomingVacation.length > 0 && !currentVacation && !showRecentReturns)"
          >
          <v-card :dark="true" :style="{ 'background-color': member.is_terminated == true && !showIsTerminated ? '#551a1a87' : currentVacation? 'rgba(33, 85, 26, 0.53)':'' }">
            <v-row no-gutters class="d-flex align-items-start">
              <v-col cols="10">
                <v-card-title style="cursor:pointer;">
                  [{{ member.servicenumber }}] {{ member.name }}
                </v-card-title>
                <v-card-subtitle>
                  {{ member.rank }} {{ member.is_terminated && !showIsTerminated ? formatDate(member.leavedate) : '' }}
                </v-card-subtitle>
                <v-card-subtitle>
                  <template v-for="(company, index) in member.companies" :key="index">
                    {{ company.name }}
                  </template>
                  <template v-for="(department, index) in member.departments" :key="index">
                    | {{ department.name }}
                  </template>
                </v-card-subtitle>
                <v-card-subtitle class="mb-2">
                  <span v-if="currentVacation">
                    {{ currentVacation.reason }}:<br>
                    {{ formatDate(currentVacation.start) }} -
                    {{ formatDate(currentVacation.end) }}
                  </span>
                  <span v-else-if="showRecentReturns">
                    {{ lastVacation.reason }}:<br>
                    {{ formatDate(lastVacation.start) }} -
                    {{ formatDate(lastVacation.end) }}
                  </span>
                  <span v-else-if="showRecentVacations && upcomingVacation.length > 0">
                    {{ upcomingVacation[0].reason }}:<br>
                    {{ formatDate(upcomingVacation[0].start) }} -
                    {{ formatDate(upcomingVacation[0].end) }}
                  </span>
                </v-card-subtitle>
              </v-col>
              <v-col cols="2" class="d-flex justify-end align-top">
                <v-img
                  :src="getImage(logoURL)"
                  width="64"
                  height="64"
                  class="d-inline-block mt-2 mr-2"
                ></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

  </template>
  
  
  <script lang="ts">
  import { defineComponent, ref, computed } from "vue";
  import { License, Employee } from "@/types/Members";
  import api from "@/api";

  interface Vacation {
    id: number;
    reason: string;
    start: string;
    end: string;
    reported: boolean;
    other: string;
    employee_id: number; // Assuming vacation has an employee_id to reference the employee
    }
  
  export default defineComponent({
    components: {
        
    },
    props: ["member", "logoURL", "companies", "departments", "ranks", "licenses", "showPreview", "showIsTerminated", "showRecentReturns", "showRecentVacations", "filterdays"],
    setup(props, {emit}) {
        const expandedIndex = ref(-1);
        const editMode = ref(false);
        const errorSnackbar = ref<any>({ visible: false, message: "", color: "red"});
  
        const licensesArray = computed(() => {
            if (props.member.licenses && typeof props.member.licenses === "object") {
                return Object.values(props.member.licenses);
            }
            return [];
        });
      
        const getImage = (path:string) => {
            try{
                if(path != null){
                    return require('../../assets/ranks/' + path)
                }else{
                    return require('../../assets/logo.png')
                }
            }catch {
                return require('@/assets/logo.png')
            }
        }
  
        const newVacation = ref({ type: '', start: '', end: '', reported: false, other: '' });

        const promotionDialog = ref(false);
        const promotionsArray = computed(() => {
            if (props.member.promotions && typeof props.member.promotions === "object") {
                return Object.values(props.member.promotions);
            }
            return [];
        });
        const showPromotionsDialog = () => {
            promotionDialog.value = true;
        };

        const vacationDialog = ref(false);
        const vacationsArray = computed<Vacation[]>(() => {
            if (props.member.vacations && typeof props.member.vacations === "object") {
                return Object.values(props.member.vacations) as Vacation[];
            }
            return [];
        });

        const currentVacation = computed<Vacation | undefined>(() => {
        const today = new Date();
        return vacationsArray.value.find(vacation => {
                const startDate = new Date(vacation.start);
                const endDate = new Date(vacation.end);
                return today >= startDate && today <= endDate;
            });
        });
        const showVacationsDialog = () => {
            vacationDialog.value = true;
        };

        const lastVacation = computed<Vacation | undefined>(() => {
          return getLastVacation(vacationsArray.value);
        });

        function getLastVacation(vacations: Vacation[]): Vacation | undefined {
          const sortedVacations = [...vacations].sort((a, b) => new Date(b.end).getTime() - new Date(a.end).getTime());
          return sortedVacations[0];
        }
        const newVacationDialog = ref(false);


        const recentlyReturnedFromVacation = computed(() => {
          const today = new Date();
          const sevenDaysAgo = new Date(today);
          sevenDaysAgo.setDate(today.getDate() - props.filterdays);

          return vacationsArray.value.filter(vacation => {
            const endDate = new Date(vacation.end);
            return endDate >= sevenDaysAgo && endDate <= today;
          });
        });


        function formatDate(date: any) {
            if (!date) return "";
            const [datePart] = date.split(" ");
            const [year, month, day] = datePart.split("-");
            return `${day}.${month}.${year}`;
        }

        const upcomingVacation = computed(() => {
          const today = new Date();
          const sevenDaysLater = new Date(today);
          sevenDaysLater.setDate(today.getDate() + props.filterdays);

          return vacationsArray.value.filter(vacation => {
            const startDate = new Date(vacation.start);
            return startDate >= today && startDate <= sevenDaysLater;
          });
        });





        function openNewVacationDialog(member) {
            newVacationDialog.value = true;
        }



        //Preview File System

        const content = ref(null);
        function resetSelectedDocument(){
            content.value = null;
        }

        //Preview File System

        //Employee File Notes
        const selectedDocumentEmployeeNotes = ref<Employee | null>(null);
        function resetSelectedDocumentEmployeeNotes(){
            selectedDocumentEmployeeNotes.value = null;
        }

        const updateSelectedDocumentEmployeeNotes = async (updatedEmployee: any) => {
            if (updatedEmployee.id != -1) {
                try {
                    const response = await api.post('employee/?action=updateNotes', { id: updatedEmployee.id, notes: updatedEmployee.notes});
                    if(response.data.success){
                        emit('updateNotes', { id: updatedEmployee.id, notes: updatedEmployee.notes });
                    }
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
        };

        async function editNotes(member: Employee) {
            try {
                selectedDocumentEmployeeNotes.value = member;
            } catch (err) {
                errorSnackbar.value.message = 'Fehler beim bearbeiten.';
                errorSnackbar.value.visible  = true;
            }
        }

        async function addVacation(member: Employee) {
            try {
                selectedDocumentEmployeeNotes.value = member;
            } catch (err) {
                errorSnackbar.value.message = 'Fehler beim bearbeiten.';
                errorSnackbar.value.visible  = true;
            }
        }

        //Employee File Notes

  
      return {
        expandedIndex,
        getImage,
        editMode,
        promotionDialog,
        vacationDialog,
        showPromotionsDialog,
        promotionsArray,
        formatDate,
        errorSnackbar,
        content,
        resetSelectedDocument,
        resetSelectedDocumentEmployeeNotes,
        selectedDocumentEmployeeNotes,
        updateSelectedDocumentEmployeeNotes,
        editNotes,
        showVacationsDialog,
        vacationsArray,
        currentVacation,
        addVacation,
        newVacationDialog,
        newVacation,
        openNewVacationDialog,
        recentlyReturnedFromVacation,
        lastVacation,
        upcomingVacation
      };
    },
  });
  </script>
  