<template>
    <v-container>
        <v-btn @click="openCategorySelectionDialog" class="float-right">Neuer Bericht</v-btn>
        <v-btn @click="filterReports('all')" class="mr-2">Alle ({{ reportCounts.all }})</v-btn>
        <v-btn @click="filterReports('own')" class="mr-2">Eigene ({{ reportCounts.own }})</v-btn>
        <v-btn @click="filterReports('missing')" class="mr-2">Fehlende Berichtergänzungen ({{ reportCounts.missing }})</v-btn>
        <v-btn @click="filterReports('incomplete')" class="mr-2">Alle nichtvollständige ({{ reportCounts.incomplete }})</v-btn>
        <v-btn @click="filterReports('open')" class="mr-2">Offene Berichte ({{ reportCounts.open }})</v-btn>
        <v-btn @click="filterReports('pending')" class="mr-2">Ausstehende Prüfungen ({{ reportCounts.pending }})</v-btn>
        <v-data-table
            :headers="reportHeaders"
            :items="filteredReports"
            class="elevation-1 mt-2"
            :search="search"
            :items-per-page="5"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Berichtverwaltung</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" label="Suche" class="mx-4 mt-3" variant="underlined" />
                    <v-select v-model="filters.category_id" :items="categories" item-title="name" item-value="id" label="Filter nach Abteilung" clearable variant="underlined" class="mx-4 mt-3" />
                    <v-select v-model="filters.creator" :items="employees" item-title="display_name" item-value="id" label="Filter nach Ersteller" clearable variant="underlined" class="mx-4 mt-3" />
                </v-toolbar>
            </template>
            <template v-slot:[`item.report_info`]="{ item }">
                <span size="small" class="me-2" @click="openEditReportDialog(item)" style="cursor:pointer">
                    {{ item.title ? item.title : formatDate(item.report_date) + ' | ' + item.report_code_name + ' | ' + item.location }}
                    <span v-if="item.missing_employees.length > 0">(fehlende Aussagen: {{ item.missing_employees.length }})</span>
                </span>
            </template>
            <template v-slot:[`item.approved`]="{ item }">
                {{ item.pinned == 1 ? '📌 ' : '' }}{{ item.approved == 0 ? '🔴' : '🟢' }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon size="small" class="me-2" @click="openEditReportDialog(item)">mdi-pencil</v-icon>
                <v-icon size="small" class="me-2" @click="openDeleteReportDialog(item)">mdi-delete</v-icon>
                <v-icon size="small" class="me-2" @click="downloadReportPdf(item.id)">mdi-download</v-icon>
                <v-icon size="small" class="me-2" @click="pinReport(item.id)">{{ item.pinned == 1 ? 'mdi-pin-off' : 'mdi-pin' }}</v-icon>
            </template>
        </v-data-table>

        <!-- Category Selection Dialog -->
        <v-dialog v-model="categorySelectionDialog" max-width="400">
            <v-card>
                <v-card-title class="headline">Kategorie auswählen</v-card-title>
                <v-card-text>
                    <v-select label="Kategorie" v-model="selectedCategory" :items="categories" item-title="name" item-value="id" required :rules="[requiredRule]"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="categorySelectionDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="createNewReport">Weiter</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- New Report Dialog -->
        <v-dialog v-model="newReportDialog" fullscreen persistent>
            <v-card>
                <v-card-title>
                    {{ newReport.title ? newReport.title : "Neuer Bericht" }}
                </v-card-title>
                <v-card-text>
                    <v-form ref="newReportForm">
                        <v-row>
                            <v-col cols="12">
                                <v-select label="Ersteller" v-model="newReport.creator" :items="employees" item-title="display_name" item-value="id" required :rules="[requiredRule]"></v-select>
                                <v-select label="Kategorie" v-model="newReport.category_id" :items="categories" item-title="name" item-value="id" required :rules="[requiredRule]" :disabled="true"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label="Datum" v-model="newReport.report_date" required :rules="[requiredRule]" type="datetime-local"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-select label="Report Code" v-model="newReport.report_code_id" :items="reportCodes" item-title="code" item-value="id" required :rules="[requiredRule]"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label="Standort" v-model="newReport.location" required :rules="[requiredRule]"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Titel" v-model="newReport.title" required :rules="[requiredRule]"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <ckeditor :editor="EditorClass" :config="EditorClass.defaultConfig" v-model="newReport.text"></ckeditor>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea label="Beschreibung" rows="3" v-model="newReport.description"></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="newReport.missing_employees"
                                    :items="employees"
                                    item-title="display_name"
                                    item-value="id"
                                    label="Fehlende Berichtergänzungen"
                                    multiple
                                    chips
                                    closable-chips
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox v-model="newReport.approved" label="Freigegeben"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="newReportDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="addNewReport" :disabled="!isFormValid(newReport)">Hinzufügen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Edit Report Dialog -->
        <v-dialog v-model="editReportDialog" fullscreen persistent>
            <v-card>
                <v-card-title>
                    Bericht bearbeiten
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-form ref="editReportForm">
                        <v-row>
                            <v-col cols="12">
                                <v-select label="Ersteller" v-model="editedReport.creator" :items="employees" item-title="display_name" item-value="id" required :rules="[requiredRule]"></v-select>
                                <v-select label="Kategorie" v-model="editedReport.category_id" :items="categories" item-title="name" item-value="id" required :rules="[requiredRule]" :disabled="true"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label="Datum" v-model="editedReport.report_date" required :rules="[requiredRule]" type="datetime-local"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-select label="Report Code" v-model="editedReport.report_code_id" :items="reportCodes" item-title="code" item-value="id" required :rules="[requiredRule]"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label="Standort" v-model="editedReport.location" required :rules="[requiredRule]"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Titel" v-model="editedReport.title" required :rules="[requiredRule]"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <ckeditor :editor="EditorClass" :config="EditorClass.defaultConfig" v-model="editedReport.text"></ckeditor>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea label="Beschreibung" rows="3" v-model="editedReport.description"></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="editedReport.missing_employees"
                                    :items="employees"
                                    item-title="display_name"
                                    item-value="id"
                                    label="Fehlende Berichtergänzungen"
                                    multiple
                                    chips
                                    closable-chips
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox v-model="editedReport.approved" label="Freigegeben"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="editReportDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="updateReport">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete Report Dialog -->
        <v-dialog v-model="deleteReportDialog" max-width="400" persistent>
            <v-card>
                <v-card-title class="headline">Bericht löschen</v-card-title>
                <v-card-text>
                    Möchten Sie den Bericht "{{ selectedReport.title }}" wirklich löschen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="deleteReportDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteReport(selectedReport)">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Snackbar -->
        <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import api from '@/api';
import { Report, Category, Employee, ReportCode } from '@/types/Report';
import EditorClass from '@/ckeditor/ckeditor.js';
import { useStore } from "vuex";

export default defineComponent({
    setup() {
        const store = useStore();
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const reportHeaders = [
            { title: '', align: 'start', sortable: false, key: 'approved' },
            { title: 'Titel', align: 'start', sortable: false, key: 'report_info' },
            { title: 'Kategorie', align: 'start', sortable: false, key: 'cat_name' },
            { title: 'Ersteller', align: 'start', sortable: false, key: 'emp_name' },
            { title: 'Erstellt am', align: 'start', sortable: false, key: 'created_at' },
            { title: 'Letzte Änderung', align: 'start', sortable: false, key: 'updated_at' },
            { title: 'Aktionen', key: 'actions', sortable: false }
        ];

        const reports = ref<Report[]>([]);
        const categories = ref<Category[]>([]);
        const employees = ref<Employee[]>([]);
        const reportCodes = ref<ReportCode[]>([]);
        const newReportDialog = ref(false);
        const editReportDialog = ref(false);
        const deleteReportDialog = ref(false);
        const categorySelectionDialog = ref(false);
        const selectedReport = ref<Report | null>(null);
        const selectedCategory = ref(null);

        const search = ref('');
        const filters = ref({
            category_id: null,
            creator: null,
            filterType: 'all'
        });

        const reportCounts = computed(() => {
            return {
                all: reports.value.length,
                own: reports.value.filter(report => report.creator === getCurrentUserId()).length,
                missing: reports.value.filter(report => report.missing_employees.includes(getCurrentUserId())).length,
                incomplete: reports.value.filter(report => report.missing_employees.length > 0).length,
                open: reports.value.filter(report => report.approved === false).length,
                pending: reports.value.filter(report => report.missing_employees.length === 0 && report.approved === false).length
            };
        });
        const filteredReports = computed(() => {
            const sortedReports = reports.value
                .map(report => {
                    const reportCode = reportCodes.value.find(code => code.id === report.report_code_id);
                    const reportCodeName = reportCode ? reportCode.code : '';
                    return {
                        ...report,
                        report_code_name: reportCodeName,
                        formatted_report_date: formatDate(report.report_date),
                        title: report.title || `${formatDate(report.report_date)} | ${reportCodeName} | ${report.location}`,
                        pinned: report.pinned == "1" ? true : false // Ensure pinned is treated as a number
                    };
                })
                .filter(report => {
                    const matchesSearch = search.value ? report.title.toLowerCase().includes(search.value.toLowerCase()) : true;
                    const matchesCategory = filters.value.category_id ? report.category_id === filters.value.category_id : true;
                    const matchesCreator = filters.value.creator ? report.creator === filters.value.creator : true;
                    const matchesFilterType = matchFilterType(report);
                    return matchesSearch && matchesCategory && matchesCreator && matchesFilterType;
                })
                .sort((a, b) => {
                    // Sortiere zuerst nach `pinned` Status
                    if (a.pinned && !b.pinned) return -1;
                    if (!a.pinned && b.pinned) return 1;
                    return 0;
                });
            
            console.log(sortedReports); // Ausgabe der sortierten Berichte
            return sortedReports;
        });



        const matchFilterType = (report: Report) => {
            switch (filters.value.filterType) {
                case 'own':
                    return report.creator == getCurrentUserId();
                case 'missing':
                    return report.missing_employees.includes(getCurrentUserId());
                case 'incomplete':
                    return report.missing_employees.length > 0;
                case 'open':
                    return report.approved === false;
                case 'pending':
                    return report.missing_employees.length === 0 && report.approved === false;
                default:
                    return true;
            }
        };

        const getCurrentUserId = () => {
            return store.state.user.linked_employee;
        };

        const newReport = ref({
            date: new Date().toISOString().slice(0, 16),
            report_code_id: '',
            location: '',
            title: '',
            text: '',
            description: '',
            creator: 0,
            approved: false,
            category_id: null,
            missing_employees: []
        });

        const editedReport = ref({
            id: '',
            date: '',
            report_code_id: '',
            location: '',
            title: '',
            text: '',
            description: '',
            creator: 0,
            approved: false,
            category_id: null,
            missing_employees: []
        });

        const snackbar = ref({
            value: false,
            color: '',
            message: '',
        });

        const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

        const fetchReports = async () => {
            try {
                const response = await api.get('report/?action=getReports');
                reports.value = response.data.map(entry => ({
                    ...entry,
                    creator: Number(entry.creator),
                    approved: entry.approved == '1' ? true : false,
                    missing_employees: entry.missing_employees ? entry.missing_employees.map(Number) : []
                }));
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await api.get('report/?action=getCategories');
                categories.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const fetchEmployees = async () => {
            try {
                const response = await api.get('report/?action=getEmployees');
                employees.value = response.data.map((emp: Employee) => ({
                    ...emp,
                    display_name: `[${emp.servicenumber}] ${emp.name}`
                }));
                const linkedEmployee = employees.value.find(emp => emp.linked_employee);
                if (linkedEmployee) {
                    newReport.value.creator = linkedEmployee.id;
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const fetchReportCodes = async () => {
            try {
                const response = await api.get('report/?action=getCodes');
                reportCodes.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const createNewReport = () => {
            const category = categories.value.find(dep => dep.id === selectedCategory.value);
            newReport.value.category_id = category.id;
            newReport.value.text = category.template || ''; // Set the template text
            newReport.value.title = category.title || ''; // Set the template text
            newReportDialog.value = true;
            categorySelectionDialog.value = false;
        };

        const addNewReport = async () => {
            try {
                const response = await api.post("report/?action=addReport", newReport.value);
                await fetchReports();
                newReportDialog.value = false;
                newReport.value = { date: '', report_code_id: '', location: '', title: '', text: '', description: '', creator: 0, approved: false, category_id: null, missing_employees: [] };
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const openEditReportDialog = (report) => {
            editedReport.value = { 
                ...report,
                date: report.report_date
            };
            editReportDialog.value = true;
        };

        const updateReport = async () => {
            try {
                const response = await api.post("report/?action=editReport", editedReport.value);
                await fetchReports();
                editReportDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const openDeleteReportDialog = (report: Report) => {
            selectedReport.value = report;
            deleteReportDialog.value = true;
        };

        const deleteReport = async (report: Report) => {
            try {
                const response = await api.post("report/?action=deleteReport", { id: report.id });
                await fetchReports();
                deleteReportDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };

        const pinReport = async (reportId) => {
            try {
                const response = await api.post("report/?action=pinReport", { id: reportId });
                await fetchReports();
                deleteReportDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        };


        const filterReports = (filterType: string) => {
            filters.value.filterType = filterType;
        };

        function isFormValid(form: Report) {
            return form.report_date !== '' && form.report_code_id !== 0 && form.location !== '' && form.category_id !== null;
        }

        const openCategorySelectionDialog = () => {
            selectedCategory.value = null;
            categorySelectionDialog.value = true;
        };

        const formatDate = (date: string) => {
            const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
            return new Date(date).toLocaleDateString('de-DE', options);
        };

        const downloadReportPdf = async (reportId: number) => {
            try {
                const response = await api.get(`report/?action=getReportPdf&report_id=${reportId}`, { responseType: 'blob' });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `report_${reportId}.pdf`);
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                errorSnackbar.value.message = 'Fehler beim Herunterladen des PDFs';
                errorSnackbar.value.visible = true;
            }
        };

        onMounted(async () => {
            await fetchReports();
            await fetchCategories();
            await fetchEmployees();
            await fetchReportCodes();
        });

        return {
            reportHeaders,
            reports,
            filteredReports,
            newReportDialog,
            newReport,
            addNewReport,
            requiredRule,
            editReportDialog,
            editedReport,
            updateReport,
            deleteReportDialog,
            selectedReport,
            deleteReport,
            openEditReportDialog,
            openDeleteReportDialog,
            isFormValid,
            errorSnackbar,
            snackbar,
            categories,
            EditorClass,
            categorySelectionDialog,
            selectedCategory,
            openCategorySelectionDialog,
            createNewReport,
            employees,
            search,
            filters,
            filterReports,
            reportCounts,
            reportCodes,
            formatDate,
            downloadReportPdf,
            pinReport
        };
    }
});
</script>
