<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <v-card style="min-height: 200px;" class="pt-5"> 
            <v-row>
                <v-col cols="4">
                    <v-img src="../assets/logo.png" height="150px"></v-img>
                </v-col>
                <v-col cols="4">
                    <v-card-title>{{ store.state.user.username }}</v-card-title>
                    <v-card-subtitle>{{ lastLogin }}</v-card-subtitle>
                    <v-card-text>
                    <div v-for="(user, index) in store.state.user.roles" :key="index">{{ user }}</div>
                    </v-card-text>
                </v-col>
            </v-row>
          </v-card>
          <v-divider></v-divider>
            <v-card class="mt-4">
                <v-card-title>Allgemeine Einstellungen</v-card-title>
                <v-card-text>
                    <v-select
                        label="Dokumenteansicht"
                        :items="documentType"
                        item-value="key"
                        item-title="name"
                        v-model="selectedDocumentView"
                    ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" variant="tonal" text @click="saveSettings()">Speichern</v-btn>
                </v-card-actions>
            </v-card>
          <v-card class="mt-4">
            <v-card-title>Password ändern</v-card-title>
            <v-card-text>
                <v-form ref="passwordForm" v-model="isPasswordFormValid">
                <v-text-field
                    v-model="passwordChange.oldPassword"
                    label="Altes Passwort"
                    required
                    type="password"
                ></v-text-field>
                <v-text-field
                    v-model="passwordChange.password"
                    label="Neues Passwort"
                    :rules="[passwordRules.required, passwordRules.minLength]"
                    required
                    type="password"
                ></v-text-field>
                <v-text-field
                    v-model="passwordChange.passwordRetry"
                    label="Neues Passwort wiederholen"
                    :rules="[passwordRules.required, passwordRules.matchPassword, passwordRules.minLength]"
                    type="password"
                ></v-text-field>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="darken-1"
                    variant="tonal"
                    text
                    :disabled="!isPasswordFormValid"
                    @click="changePassword"
                    >
                    Speichern
                    </v-btn>
                </v-card-actions>
                </v-form>
                <v-alert v-if="passwordMessage" :type="passwordMessage.type" class="mt-3">
                    {{ passwordMessage.text }}
                </v-alert>
            </v-card-text>
            </v-card>

        </v-col>
        <v-col cols="4">
            <v-card>
            <v-card-title>Template Images</v-card-title>
            <v-card-text>
                <v-form>
                    <div class="text-caption pt-5">Header Vorschau</div>
                    <v-img :src="headerImage"></v-img>
                    <v-text-field v-model="headerImage" label="Header für Templates"></v-text-field>
                    <v-divider></v-divider>
                    <div class="text-caption pt-5">Footer Vorschau</div>
                    <v-img :src="footerImage"></v-img>
                    <v-text-field v-model="footerImage" label="Footer für Templates"></v-text-field>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" variant="tonal" text @click="saveTemplateImages(false)">Speichern</v-btn>
                    </v-card-actions>
                </v-form>
                <v-alert v-if="templateMessage" :type="templateMessage.type" class="mt-3">
                    {{ templateMessage.text }}
                </v-alert>
            </v-card-text>
          </v-card>

            <v-card class="mt-5">
                <v-card-title>Neutrale Template Images</v-card-title>
                <v-card-text>
                    <v-form>
                        <div class="text-caption pt-5">Neutraler Header Vorschau</div>
                        <v-img :src="headerImageNeutral"></v-img>
                        <v-text-field v-model="headerImageNeutral" label="Neutraler Header für Templates"></v-text-field>
                        <v-divider></v-divider>
                        <div class="text-caption pt-5">Neutraler Footer Vorschau</div>
                        <v-img :src="footerImageNeutral"></v-img>
                        <v-text-field v-model="footerImageNeutral" label="Neutraler Footer für Templates"></v-text-field>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="darken-1" variant="tonal" text @click="saveTemplateImages(true)">Speichern</v-btn>
                        </v-card-actions>
                    </v-form>
                    <v-alert v-if="templateMessageNeutral" :type="templateMessageNeutral.type" class="mt-3">
                        {{ templateMessageNeutral.text }}
                    </v-alert>
                </v-card-text>
            </v-card>
            <v-card class="mt-5">
            <v-card-title>Unterschrift für Templates</v-card-title>
            <v-card-text>
                <v-form>
                    <div class="text-caption pt-5">Unterschrift Vorschau</div>
                    <v-img :src="signature" style="width:auto;"></v-img>
                    <v-text-field v-model="signature" label="Unterschrift für Templates"></v-text-field>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" variant="tonal" text @click="saveSignature()">Speichern</v-btn>
                    </v-card-actions>
                </v-form>
                <v-alert v-if="templateMessageSignature" :type="templateMessageSignature.type" class="mt-3">
                    {{ templateMessageSignature.text }}
                </v-alert>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from "vue";
  import api from "@/api";
  import { useStore } from "vuex";
  
  export default defineComponent({
    components: {},
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const store = useStore();
        const lastLogin = ref("2023-04-28 09:00");
        const groups = ref(["Group A", "Group B", "Group C"]);
        const headerImage = ref("");
        const footerImage = ref("");
        const headerImageNeutral = ref("");
        const footerImageNeutral = ref("");
        const email = ref("");
        const signature = ref("");
        const selectedDocumentView = ref("");

        const documentType = [
            { name: 'Kachel', key: 'tiles' },
            { name: 'Tabelle', key: 'table' }
        ];


        const passwordChange = ref({
            password: '',
            passwordRetry: '',
            oldPassword: ''
        });

        const passwordForm = ref(null);
        const isPasswordFormValid = ref(false);
        const passwordMessage = ref(null);

        const passwordRules = {
            required: (value) => !!value || "Erforderlich",
            minLength: (value) => value.length >= 8 || "Mindestlänge von 8 Zeichen erforderlich",
            matchPassword: (value) =>
                value === passwordChange.value.password || "Passwörter stimmen nicht überein",
        };

        
        const changePassword = async () => {
            try {
                const response = await api.post('/account/?action=changePassword', passwordChange.value)
                if (response.data.success) {
                    passwordMessage.value = {
                        type: "success",
                        text: "Passwort wurde erfolgreich geändert",
                    };
                    passwordForm.value.reset();
                } else {
                    passwordMessage.value = {
                        type: "error",
                        text: response.data.message || "Ein Fehler ist aufgetreten",
                    };
                }
            } catch (error) {
                passwordMessage.value = {
                    type: "error",
                    text: "Ein Fehler ist aufgetreten",
                };
            }
        };

        const templateMessage = ref(null);
        const templateMessageNeutral = ref(null);
        const templateMessageSignature = ref(null);
        const saveTemplateImages = async (neutral: boolean) => {
            try {
                // Send an API request to update the header and footer images on the server
                const response = await api.post("/account/?action=updateTemplateImages", {
                    headerImage: headerImage.value,
                    footerImage: footerImage.value,
                    headerImageNeutral: headerImageNeutral.value,
                    footerImageNeutral: footerImageNeutral.value,
                    neutral: neutral,
                });

                if (response.data.success) {
                    // Update the Vuex store
                    if(neutral){
                        store.commit("setNeutralHeaderImage", headerImageNeutral.value);
                        store.commit("setNeutralFooterImage", footerImageNeutral.value);
                        templateMessageNeutral.value = {
                            type: "success",
                            text: "Template Header und Footer wurden erfolgreich geändert.",
                        };
                    }else {
                        store.commit("setHeaderImage", headerImage.value);
                        store.commit("setFooterImage", footerImage.value);
                        templateMessage.value = {
                            type: "success",
                            text: "Template Header und Footer wurden erfolgreich geändert.",
                        };
                    }
                } else {
                    if(neutral){
                        templateMessageNeutral.value = {
                            type: "error",
                            text: response.data.message || "Ein Fehler ist aufgetreten",
                        };
                    }else{
                        templateMessage.value = {
                            type: "error",
                            text: response.data.message || "Ein Fehler ist aufgetreten",
                        };
                    }
                }
            } catch (error) {
                templateMessage.value = {
                    type: "error",
                    text: "Ein Fehler ist aufgetreten",
                };
            }
        };

        const saveSignature = async () => {
            try {
                // Send an API request to update the header and footer images on the server
                const response = await api.post("/account/?action=updateSignature", {
                    signature: signature.value,
                });

                if (response.data.success) {
                    // Update the Vuex store
                        store.commit("setSignature", signature.value);
                        templateMessageSignature.value = {
                            type: "success",
                            text: "Template Signature wurden erfolgreich geändert.",
                        }
                } else {
                    templateMessageSignature.value = {
                        type: "error",
                        text: response.data.message || "Ein Fehler ist aufgetreten",
                    }
                }
            } catch (error) {
                templateMessage.value = {
                    type: "error",
                    text: "Ein Fehler ist aufgetreten",
                };
            }
        };

        const saveSettings = async () => {
            try {
            const response = await api.post("/account/?action=updateSettings", {
                documentView: selectedDocumentView.value,
            });

            if (response.data.success) {
                // Update the Vuex store
                store.commit("setDocumentView", selectedDocumentView.value);
                errorSnackbar.value.message = "Einstellungen gespeichert.";
                errorSnackbar.value.visible  = true;
                errorSnackbar.value.color = "green";
            } else {
                errorSnackbar.value.message = response.data.message || "Ein Fehler ist aufgetreten";
                    errorSnackbar.value.visible  = true;
                    errorSnackbar.value.color = "red";
            }
            } catch (error) {
                errorSnackbar.value.message = "Ein Fehler ist aufgetreten";
                errorSnackbar.value.visible  = true;
                errorSnackbar.value.color = "red";
            }
        };

        onMounted(async () => {
            headerImage.value = store.state.user.mail_header;
            footerImage.value = store.state.user.mail_footer;
            headerImageNeutral.value = store.state.user.mail_header_neutral;
            footerImageNeutral.value = store.state.user.mail_footer_neutral;
            signature.value = store.state.user.signature;
            selectedDocumentView.value = store.state.user.documentView;
        });
        


      return {
        store,
        lastLogin,
        groups,
        headerImage,
        headerImageNeutral,
        footerImage,
        footerImageNeutral,
        email,
        changePassword,
        passwordChange,
        passwordForm,
        isPasswordFormValid,
        passwordRules,
        passwordMessage,
        saveTemplateImages,
        templateMessage,
        templateMessageNeutral,
        templateMessageSignature,
        errorSnackbar,
        saveSignature,
        signature,
        documentType,
        selectedDocumentView,
        saveSettings,
      };
    },
  });
  </script>
  