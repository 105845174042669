    <template>
        <error-snackbar v-model:snackbar="errorSnackbar" />
        <v-container fluid>
            <v-data-table
            :headers="headers"
            :items="users"
            class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>User Management</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-btn @click="newUserDialog = true">Neuer Benutzer</v-btn>
                        <!-- Add your dialog components for editing/creating users here -->
                    </v-toolbar>
                </template>
                <!-- @ts-ignore -->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        size="small"
                        class="me-2"
                        @click="openEditUserDialog(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon v-if="item.banned == 0"
                        size="small"
                        @click="openBannDialog(item)"
                    >
                        mdi-account-cancel
                    </v-icon>
                    <v-icon v-else
                        size="small"
                        @click="openUnbannDialog(item)"
                    >
                        mdi-lock-open-variant
                    </v-icon>
                    <v-icon
                        size="small"
                        class="me-2"
                        @click="openResetPasswordDialog(item)"
                    >
                        mdi-key
                    </v-icon>
                </template>

                <template v-slot:[`item.mail_header`]="{ item }">
                    <v-tooltip v-if="item.mail_header">
                        <template v-slot:activator="{ props }">
                            <span
                                v-bind="props"
                                style="cursor: pointer; text-decoration: underline;"
                                @click="copyLinkToClipboard(item.mail_header)"
                            >
                                Header
                            </span>
                        </template>
                        <img :src="item.mail_header" alt="Mail Header" />
                    </v-tooltip>
                    <span v-else>-</span>
                </template>

                <template v-slot:[`item.mail_footer`]="{ item }">
                    <v-tooltip v-if="item.mail_footer">
                        <template v-slot:activator="{ props }">
                            <span
                                v-bind="props"
                                style="cursor: pointer; text-decoration: underline;"
                                @click="copyLinkToClipboard(item.mail_footer)"
                            >
                                Footer
                            </span>
                        </template>
                        <img :src="item.mail_footer" alt="Mail Footer" />
                    </v-tooltip>
                    <span v-else>-</span>
                </template>

                <template v-slot:[`item.mail_header_neutral`]="{ item }">
                    <v-tooltip v-if="item.mail_header_neutral">
                        <template v-slot:activator="{ props }">
                            <span
                                v-bind="props"
                                style="cursor: pointer; text-decoration: underline;"
                                @click="copyLinkToClipboard(item.mail_header_neutral)"
                            >
                                Header
                            </span>
                        </template>
                        <img :src="item.mail_header_neutral" alt="Mail Header Neutral" />
                    </v-tooltip>
                    <span v-else>-</span>
                </template>

                <template v-slot:[`item.mail_footer_neutral`]="{ item }">
                    <v-tooltip v-if="item.mail_footer_neutral">
                        <template v-slot:activator="{ props }">
                            <span
                                v-bind="props"
                                style="cursor: pointer; text-decoration: underline;"
                                @click="copyLinkToClipboard(item.mail_footer_neutral)"
                            >
                                Footer
                            </span>
                        </template>
                        <img :src="item.mail_footer_neutral" alt="Mail Footer Neutral" />
                    </v-tooltip>
                    <span v-else>-</span>
                </template>

                <template v-slot:[`item.signature`]="{ item }">
                    <v-tooltip v-if="item.signature">
                        <template v-slot:activator="{ props }">
                            <span
                                v-bind="props"
                                style="cursor: pointer; text-decoration: underline;"
                                @click="copyLinkToClipboard(item.signature)"
                            >
                                Signature
                            </span>
                        </template>
                        <img :src="item.signature" alt="signature" />
                    </v-tooltip>
                    <span v-else>-</span>
                </template>


            </v-data-table>


            <v-dialog v-model="bannDialog" max-width="400" persistent>
                <v-card>
                <v-card-title class="headline">Benutzer bannen</v-card-title>
                <v-card-text>
                    Möchtest du den Benutzer "{{ selectedUser.username }}" wirklich bannen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="bannDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="bannUser(selectedUser)">Bannen</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="unbannDialog" max-width="400" persistent>
                <v-card>
                <v-card-title class="headline">Benutzer entbannen</v-card-title>
                <v-card-text>
                    Möchtest du den Benutzer "{{ selectedUser.username }}" wirklich entbannen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="unbannDialog = false">Abbrechen</v-btn>
                    <v-btn color="blue darken-1" text @click="unbannUser(selectedUser)">Entbannen</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="newUserDialog" max-width="500" persistent>
                <v-card>
                    <v-card-title class="headline">Neuer Benutzer</v-card-title>
                    <v-card-text>
                        <v-form ref="newUserForm">
                            <v-text-field label="Benutzername" v-model="newUser.username" required :rules="[requiredRule]"></v-text-field>
                            <v-text-field label="E-Mail" v-model="newUser.email" required :rules="[requiredRule]"></v-text-field>
                            <v-select
                                label="Gruppen"
                                v-model="newUser.groups"
                                :items="groups"
                                item-title="name"
                                item-value="id"
                                multiple
                                :rules="[requiredRule]"
                            ></v-select>
                            <v-text-field label="Passwort" v-model="newUser.password" type="password" required :rules="[requiredRule]"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="newUserDialog = false">Abbrechen</v-btn>
                        <v-btn color="blue darken-1" text @click="addNewUser" :disabled="!isFormValid()">Hinzufügen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="editUserDialog" max-width="500" persistent>
                <v-card>
                    <v-card-title class="headline">Benutzer bearbeiten</v-card-title>
                    <v-card-text>
                        <v-form ref="editUserForm">
                            <v-text-field label="Benutzername" v-model="editedUser.username" required :rules="[requiredRule]"></v-text-field>
                            <v-text-field label="E-Mail" v-model="editedUser.email" required :rules="[requiredRule]"></v-text-field>
                            <v-select
                                label="Mitarbeiter"
                                v-model="editedUser.linked_employee"
                                :items="Employees"
                                item-title="name"
                                item-value="id"
                            ></v-select>
                            <v-select
                                label="Gruppen"
                                v-model="editedUser.allGroups"
                                :items="groups"
                                item-title="name"
                                item-value="id"
                                multiple
                                :rules="[requiredRule]"
                            ></v-select>
                            <v-text-field label="Mail Header" v-model="editedUser.mail_header"></v-text-field>
                            <v-text-field label="Mail Footer" v-model="editedUser.mail_footer"></v-text-field>
                            <v-text-field label="Mail Header Neutral" v-model="editedUser.mail_header_neutral"></v-text-field>
                            <v-text-field label="Mail Footer Neutral" v-model="editedUser.mail_footer_neutral"></v-text-field>
                            <v-text-field label="signature" v-model="editedUser.signature"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="editUserDialog = false">Abbrechen</v-btn>
                        <v-btn color="blue darken-1" text @click="updateUser">Speichern</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            
            <v-dialog v-model="resetPasswordDialog" max-width="500" persistent>
                <v-card>
                    <v-card-title class="headline">Passwort zurücksetzen</v-card-title>
                    <v-card-text>
                        <v-form ref="editUserForm">
                            <v-text-field label="Benutzername" v-model="editedUser.username"></v-text-field>
                            <v-text-field type="password" label="Password" v-model="passwordReset.password" required :rules="[passwordRules.required, passwordRules.minLength]"></v-text-field>
                            <v-text-field type="password" label="Password wiederholen" v-model="passwordReset.passwordRetry" required :rules="[passwordRules.required, passwordRules.matchPassword, passwordRules.minLength]"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="resetPasswordDialog = false">Abbrechen</v-btn>
                        <v-btn color="blue darken-1" text @click="resetPassword">Speichern</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>




            <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
                {{ snackbar.message }}
            </v-snackbar>
        </v-container>


    </template>
  
  
  
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import api from '@/api';
  import { Users, Groups } from '@/types/User';
  import { EmployeeOnly } from  "@/types/Training"
  
  export default defineComponent({
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const headers = [
            { title: 'Username', align: 'start', sortable: false, key: 'username' },
            { title: 'Email', align: 'start', sortable: false, key: 'email' },
            { title: 'Mitarbeiter', align: 'start', sortable: false, key: 'name' },
            { title: 'Gruppen', align: 'start', sortable: false, key: 'groups' },
            { title: 'Mail Header', align: 'start', sortable: false, key: 'mail_header' },
            { title: 'Mail Footer', align: 'start', sortable: false, key: 'mail_footer', },
            { title: 'Mail Header Neutral', align: 'start', sortable: false, key: 'mail_header_neutral' },
            { title: 'Mail Footer Neutral', align: 'start', sortable: false, key: 'mail_footer_neutral' },
            { title: 'Signature', align: 'start', sortable: false, key: 'signature' },
            { title: 'Letzter Login', align: 'start', sortable: false, key: 'last_login' },
            { title: 'Actions', key: 'actions', sortable: false }
        ];

        const users = ref<Users[]>([]);
        const bannDialog = ref(false);
        const unbannDialog = ref(false);
        const selectedUser = ref<Users | null>(null);

        const Employees = ref<EmployeeOnly[]>([]);

        const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

        const openBannDialog = (user) => {
            selectedUser.value = user;
            bannDialog.value = true;
        };

        const openUnbannDialog = (user) => {
            selectedUser.value = user;
            unbannDialog.value = true;
        };

        const snackbar = ref({
            value: false,
            color: '',
            message: '',
        });



  
        const fetchUsers = async () => {
            try {
                const response = await api.get('admin/user/?action=getUserOverview');
                users.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const fetchGroups = async () => {
            try {
                const response = await api.get('admin/user/?action=getGroups');
                groups.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const fetchEmployees = async () => {
            try {
                const response = await api.get('admin/employee/index.php?action=getEmployees');
                Employees.value = response.data.map((item: any) => ({
                    id: item.id,
                    name: `FD${item.servicenumber} ${item.name}`,
                    servicenumber: item.servicenumber,
                }));
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };


        const bannUser = async (user) => {
            console.log('Bann user:', user.id);
            let userId = user.id;
            try {
                const response = await api.post("admin/user/?action=bannUser", { id: userId });
                
                await fetchUsers();
    
                bannDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const unbannUser = async (user) => {
            console.log('Bann user:', user.id);
            let userId = user.id;
            try {
                const response = await api.post("admin/user/?action=unbannUser", { id: userId });
    
                await fetchUsers();
    
                unbannDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const copyLinkToClipboard = (text) => {
            navigator.clipboard.writeText(text).then(
                () => {
                    errorSnackbar.value.message = "Link erfolgreich kopiert";
                    errorSnackbar.value.visible  = true;
                    errorSnackbar.value.color  = "green";
                },
                (err) => {
                    errorSnackbar.value.message = "Ein Fehler ist aufgetreten";
                    errorSnackbar.value.visible  = true;
                    errorSnackbar.value.color  = "red";
                }
            );
        };


        const newUserDialog = ref(false);
        const newUser = ref({
            username: '',
            email: '',
            groups: [],
            password: '',
        });

        function isFormValid() {
            return (
                newUser.value.username !== '' &&
                newUser.value.email !== '' &&
                newUser.value.groups.length !== 0 &&
                newUser.value.password !== ''
            );
        }

        const groups = ref<Groups[]>([]);

        const addNewUser = async () => {
            try {
                const response = await api.post("admin/user/?action=addNewUser", newUser.value);
                await fetchUsers();

                if(response.data.error){
                    snackbar.value.value = true;
                    snackbar.value.color = 'error';
                    snackbar.value.message = response.data.error;
                }else{
                    newUserDialog.value = false;
                    newUser.value = {username: '', email: '', groups: [], password: ''};
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };



        const editUserDialog = ref(false);
        const editedUser = ref({
            id: '',
            username: '',
            email: '',
            groups: '',
            allGroups: [],
            mail_header: '',
            mail_footer: '',
            mail_header_neutral: '',
            mail_footer_neutral: '',
            signature: '',
            linked_employee: '',
        });

        const openEditUserDialog = (user) => {
            editedUser.value = { ...user };
            editUserDialog.value = true;
        };

        const updateUser = async () => {
            try {
                const response = await api.post("admin/user/?action=updateUser", editedUser.value);
                await fetchUsers();

                editUserDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const passwordRules = {
            required: (value) => !!value || "Erforderlich",
            minLength: (value) => value.length >= 8 || "Mindestlänge von 8 Zeichen erforderlich",
            matchPassword: (value) =>
                value === passwordReset.value.password || "Passwörter stimmen nicht überein",
        };

        
        const resetPasswordDialog = ref(false);
        const passwordReset = ref({
            password: '',
            passwordRetry: ''
        });
        const openResetPasswordDialog = (user) => {
            editedUser.value = { ...user };
            resetPasswordDialog.value = true;
        }

        const resetPassword = async () => {
            try {
                const response = await api.post("admin/user/?action=resetPassword", { id: editedUser.value.id, password: passwordReset.value.password });
                await fetchUsers();

                resetPasswordDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };



        onMounted(async () => {
            await fetchUsers();
            await fetchGroups();
            await fetchEmployees();
        });
  

  
        return {
            headers,
            users,
            bannUser,
            openBannDialog, // Add the openBannDialog method to the return object
            bannDialog,
            unbannUser,
            openUnbannDialog,
            unbannDialog,
            selectedUser,
            newUserDialog,
            newUser,
            groups,
            addNewUser,
            requiredRule,
            isFormValid,
            snackbar,
            editUserDialog,
            editedUser,
            openEditUserDialog,
            updateUser,
            errorSnackbar,
            copyLinkToClipboard,
            passwordReset,
            passwordRules,
            resetPassword,
            resetPasswordDialog,
            openResetPasswordDialog,
            Employees
        };
    },
  });
  </script>
  