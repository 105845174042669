<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid class="outer-container">
        <v-container fluid class="map-container">
            <v-row>
                <v-col cols="12">
                    <v-btn @click="changeMapStyle('styleAtlas')">Atlas</v-btn>
                    <v-btn @click="changeMapStyle('styleSatelite')">Satelite</v-btn>
                    <v-btn @click="changeMapStyle('styleGrid')">Grid</v-btn>
                </v-col>
            </v-row>
            <v-row style="height: 100%;">
                <v-col cols="12">
                    <!-- @ts-ignore -->
                    <l-map
                    ref="map"
                    class="map-container"
                    :zoom="4"
                    :center="center"
                    :options="mapOptions"
                    >

                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                        <l-marker v-for="marker in filteredMarkers" :key="marker.id" :lat-lng="[marker.y_coordinate, marker.x_coordinate]"
                        :icon="customIcon(marker.icon)">
                            <l-popup style="min-width:125px">
                                <div v-if="marker.category_id == 1" style="min-width:125px">
                                    <h3>{{ marker.name }}</h3>
                                    <p><strong>Standort:</strong> {{ marker.location }}</p>
                                </div>
                                <div v-else>
                                    <h3>{{ marker.name }}</h3>
                                    <p><strong>CEO:</strong> {{ marker.ceo }}</p>
                                    <p><strong>Phone:</strong> {{ marker.phonenumber }}</p>
                                    <p><strong>Standort:</strong> {{ marker.location }}</p>
                                </div>
                            </l-popup>
                        </l-marker>

                    </l-map>
                </v-col>

                <!--<v-col cols="3">
                    <v-container fluid>
                        <v-select
                            :items="categories"
                            item-title="name"
                            item-value="id"
                            label="Kategorie"
                            v-model="selectedCategories"
                            multiple
                            chips
                            filter
                            dense
                        />
                        <v-text-field label="Search" v-model="search" />
                        <v-expansion-panels>
                            <v-expansion-panel 
                                v-for="(category, index) in categories" 
                                :key="index"
                                :title="category.name"
                                >
                                <v-expansion-panel-text>
                                    <v-list dense>
                                        <v-list-item
                                        v-for="marker in filteredMarkersByCategory(category.id)"
                                        :key="marker.id"
                                        @click="focusOnMarker(marker)"
                                        >
                                        <v-list-item-title>{{ marker.name }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ marker.location }}</v-list-item-subtitle>
                                        </v-list-item>
                                    </v-list>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>



                    </v-container>

                </v-col>-->
            </v-row>
        <v-dialog v-model="showDialog" persistent max-width="600px">
            <v-card>
            <v-card-title>
                <span class="headline">Add Marker</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                <v-row>
                    <v-col cols="12">
                    <v-text-field label="Name" v-model="markerName" />
                    </v-col>
                    <v-col cols="12">
                    <v-text-field label="CEO" v-model="ceo" />
                    </v-col>
                    <v-col cols="12">
                    <v-text-field label="Phonenumber" v-model="phonenumber" />
                    </v-col>
                    <v-col cols="12">
                    <v-text-field label="Standort" v-model="location" />
                    </v-col>
                    <v-col cols="12">
                    <v-select
                        :items="categories"
                        item-title="name"
                        item-value="id"
                        label="Category"
                        v-model="selectedCategory"
                    />
                    </v-col>
                </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text="true" @click="showDialog = false">
                Close
                </v-btn>
                <v-btn color="blue darken-1" text="true" @click="addMarker">
                Save
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editDialog" persistent max-width="600px">
    <v-card>
        <v-card-title>
        <span class="headline">Edit Marker</span>
        </v-card-title>
        <v-card-text>
        <v-container>
            <v-row>
            <v-col cols="12">
                <v-text-field label="Name" v-model="markerName" />
            </v-col>
            <v-col cols="12">
                <v-text-field label="CEO" v-model="ceo" />
            </v-col>
            <v-col cols="12">
                <v-text-field label="Phonenumber" v-model="phonenumber" />
            </v-col>
            <v-col cols="12">
                <v-text-field label="Standort" v-model="location" />
            </v-col>
            <v-col cols="12">
                <v-select
                :items="categories"
                item-title="name"
                item-value="id"
                label="Category"
                v-model="selectedCategory"
                />
            </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text="true" @click="editDialog = false">
            Close
        </v-btn>
        <v-btn color="blue darken-1" text="true" @click="updateMarker">
            Save
        </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>

        </v-container>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, computed, watch } from "vue";
  import { LMap, LTileLayer, LMarker, LPopup } from "@vue-leaflet/vue-leaflet";
  import L, { LatLngExpression, bounds, latLngBounds, marker } from "leaflet";
  import "leaflet/dist/leaflet.css";
  import api from "@/api";
  import { Categories, Markers } from "@/types/Map";
  
  export default defineComponent({
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
    },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const showDialog = ref(false);
        const markerName = ref("");
        const ceo = ref("");
        const location = ref("");
        const phonenumber = ref("");
        const selectedCategory = ref(null);
        const clickedLatLng = ref(null);

        const selectedCategories = ref([]);

        const mapInstance = computed(() => map.value);
        const map = ref<any>(null);
    
        const zoom = ref(4);
        const center = ref([0, 0]);
        const url = ref(`/mapStyles/styleAtlas/{z}/{x}/{y}.jpg`);
        const attribution = ref("LuckyV");

        const activeStyle = ref("styleAtlas");

        const categoryLayers = ref({});
    
        const changeMapStyle = (style) => {
            if(activeStyle.value == 'styleSatelite') mapInstance.value.leafletObject.removeLayer(SateliteStyle);
            else if(activeStyle.value == 'styleAtlas') mapInstance.value.leafletObject.removeLayer(AtlasStyle);
            else if(activeStyle.value == 'styleGrid') mapInstance.value.leafletObject.removeLayer(GridStyle);

            activeStyle.value = style;

            if (style === 'styleSatelite') {
                mapInstance.value.leafletObject.addLayer(SateliteStyle);
            } else if (style === 'styleAtlas') {
                mapInstance.value.leafletObject.addLayer(AtlasStyle);
            } else if (style === 'styleGrid') {
                mapInstance.value.leafletObject.addLayer(GridStyle);
            }
        };

        const center_x = 117.3;
        const center_y = 172.8;
        const scale_x = 0.02072;
        const scale_y = 0.0205;

        const transformation = new L.Transformation(
            scale_x, 
            center_x, 
            -scale_y, 
            center_y
        );

        const CUSTOM_CRS = L.extend({}, L.CRS.Simple, {
            projection: L.Projection.LonLat,
            transformation: transformation,
            scale: function (zoom) {
                return Math.pow(2, zoom);
            },
            zoom: function (sc) {
                return Math.log(sc) / 0.6931471805599453;
            },
            distance: function (pos1, pos2) {
                var x_difference = pos2.lng - pos1.lng;
                var y_difference = pos2.lat - pos1.lat;
                return Math.sqrt(
                    x_difference * x_difference + y_difference * y_difference
                );
            },
            infinite: true,
        });


        function customTileLayer(urlTemplate, options) {
            return new L.TileLayer(urlTemplate, {
                ...options,
                getTileUrl: function (coords) {
                const zoom = this._getZoomForUrl();
                if (zoom > options.maxNativeZoom) {
                    coords.z = options.maxNativeZoom;
                } else {
                    coords.z = zoom;
                }
                return L.Util.template(urlTemplate, L.extend(coords, this.options));
                },
            });
        }

        const AtlasStyle = customTileLayer('mapStyles/styleAtlas/{z}/{x}/{y}.jpg', {
            minZoom: 0,
            maxZoom: 8,
            maxNativeZoom: 5,
            noWrap: false,
            attribution: '',
            id: 'styleAtlas map',
        });

        const SateliteStyle = customTileLayer('mapStyles/styleSatelite/{z}/{x}/{y}.jpg', {
            minZoom: 0,
            maxZoom: 8,
            maxNativeZoom: 5,
            noWrap: false,
            attribution: '',
            id: 'styleSatelite map',
        });

        const GridStyle = customTileLayer('mapStyles/styleGrid/{z}/{x}/{y}.png', {
            minZoom: 0,
            maxZoom: 8,
            maxNativeZoom: 5,
            noWrap: false,
            attribution: '',
            id: 'styleGrid map',
        });

        const mapOptions = {
            crs: CUSTOM_CRS,
            minZoom: 2,
            maxZoom: 8,
            preferCanvas: true,
            noWrap: true,
            zoom: 2,
            maxNativeZoom: 5,
            doubleClickZoom: false,
        };


        const onMapClick = (event) => {
            const latLng = mapInstance.value.leafletObject.containerPointToLatLng(L.point(event.layerX, event.layerY));
            clickedLatLng.value = latLng;
            showDialog.value = true;
        };







        const customIcon = (icon) => {
            return L.icon({
                iconUrl: `/img/mapIcons/${icon}.png`,
                iconSize: [20, 20],
                iconAnchor: [10, 10],
                popupAnchor: [0, -10],
            });
        };

        const categories = ref<Categories[]>([]);

        const fetchCategories = async () => {
            try {
                const response = await api.get('mapOnly/?action=getCategories');
                categories.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        };

        const markers = ref<Markers[]>([]);

        const fetchMarker = async () => {
            try {
                const response = await api.get('mapOnly/?action=getMarker');
                markers.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        };



        onMounted(async () => {
            await fetchCategories();
            await fetchMarker();
            selectedCategories.value = categories.value
                .filter((category) => category.name == 'Hydrant')
                .map((category) => category.id);

            mapInstance.value.leafletObject.addLayer(AtlasStyle);
        });







        const search = ref("");
        const filteredMarkers = computed(() => {
            if (search.value === "") {
                return markers.value.filter((marker) =>
                    selectedCategories.value.includes(marker.category_id)
                );
            }
            return markers.value.filter((marker) =>
                selectedCategories.value.includes(marker.category_id) &&
                (marker.name.toLowerCase().includes(search.value.toLowerCase()) || marker.location.toLowerCase().includes(search.value.toLowerCase()))
            );
        });


        const focusOnMarker = (marker) => {
            console.log(marker);
            const newZoomLevel = 5; // Setzen Sie hier den gewünschten Zoom-Level
            mapInstance.value.leafletObject.flyTo(
                [marker.y_coordinate, marker.x_coordinate],
                newZoomLevel
            );
        };


        const filteredMarkersByCategory = (categoryId) => {
            return filteredMarkers.value.filter(marker => marker.category_id === categoryId);
        };


        return {
            map,
            changeMapStyle,
            zoom,
            center,
            url,
            attribution,
            mapOptions,
            showDialog,
            markerName,
            selectedCategory,
            clickedLatLng,
            categories,
            onMapClick,
            ceo,
            phonenumber,
            markers,
            customIcon,
            search,
            filteredMarkers,
            focusOnMarker,
            selectedCategories,
            filteredMarkersByCategory,
            errorSnackbar,
        };
    },
  });
  </script>
  
  <style scoped>
    .map-container {
      height: 95%;
      width: 100%;
    }
    .outer-container {
        height: 100%;
    }
    .list-container {
        max-height: 70vh; /* Setze die maximale Höhe entsprechend der Bildschirmhöhe */
        overflow-y: auto; /* Aktiviert den Scrollbalken, wenn der Inhalt die maximale Höhe überschreitet */
    }

  </style>
  