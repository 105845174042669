<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
      <v-data-table :headers="headers" :items="roles" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Rollen</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn @click="openDialog(null)">Neue Rolle</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="openDialog(item ?? null)">mdi-pencil</v-icon>
            <!-- Add delete functionality if required -->
        </template>

      </v-data-table>
  
      <v-dialog v-model="dialog" max-width="800px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field label="Role Name" v-model="editedRole.name"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field label="Power" v-model="editedRole.power"></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field label="Description" v-model="editedRole.description"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field label="Sort Order" v-model="editedRole.sort_order"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" v-for="(sitePermissions, site) in groupedPermissions" :key="site">
                    <h5>{{ site }}</h5>
                    <v-checkbox
                        class="checkbox-spacing"
                        v-for="permission in (sitePermissions as Permission[])"
                        :key="permission.id"
                        :label="permission.name"
                        :value="permission"
                        v-model="editedRole.permissions"
                    ></v-checkbox>

                </v-col>
                </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref, computed, reactive,watch } from 'vue';
  import api from '@/api';
  import { Permission, Role, RolePermission } from '@/types/Roles';
  
  export default defineComponent({
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
      const headers = [
            { title: 'Role Name', align: 'start', sortable: false, key: 'name' },
            { title: 'Description', align: 'start', sortable: false, key: 'description' },
            { title: 'Power', align: 'start', sortable: false, key: 'power' },
            { title: 'Sort Order', align: 'start', sortable: false, key: 'sort_order' },
            { title: 'Actions', align: 'start', sortable: false, key: 'actions' }
        ];

        const permissions = ref<any[]>([]);
        const roles = ref<Role[]>([]);
        const rolePermissions = ref<RolePermission[]>([]);
        const dialog = ref(false);
        const editedRole = reactive<Partial<Role>>({
            name: '',
            description: '',
            sort_order: 0,
            power: 0,
            permissions: [],
        });


        const fetchPermissions = async () => {
            try {
                const response = await api.post("admin/roles/?action=getPermissions");
                permissions.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const fetchRoles = async () => {
            try {
                const response = await api.post("admin/roles/?action=getRoles");
                roles.value = response.data;
                console.log(roles);
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const fetchRolePermissions = async () => {
            try {
                const response = await api.post("admin/roles/?action=getRolePermissions");
                rolePermissions.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const openDialog = (proxyRole) => {
            const role = proxyRole ? proxyRole : null;
            if (role) {
                editedRole.id = role.id;
                editedRole.name = role.name;
                editedRole.description = role.description;
                editedRole.sort_order = role.sort_order;
                editedRole.power = role.power;

                // Find permissions assigned to the selected role
                const assignedPermissionIds = rolePermissions.value
                .filter((rp) => rp.role_id === role.id)
                .map((rp) => rp.permission_id);

                // Get the permission objects for the assigned permissions
                const assignedPermissions = permissions.value.filter((p) =>
                assignedPermissionIds.includes(p.id)
                );

                editedRole.permissions = assignedPermissions;
            } else {
                editedRole.id = undefined;
                editedRole.name = '';
                editedRole.description = '';
                editedRole.sort_order = 0;
                editedRole.power = 0;
                editedRole.permissions = [];
            }
            dialog.value = true;
        };




        const close = () => {
            dialog.value = false;
        };

        const save = async () => {
            if (editedRole.id) {
                // Update role
                try {
                    await api.post("admin/roles/?action=updateRole", editedRole);
                    await fetchPermissions();
                    await fetchRoles();
                    await fetchRolePermissions();
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            } else {
                // Create new role
                try {
                    await api.post("admin/roles/?action=createRole", editedRole);
                    await fetchPermissions();
                    await fetchRoles();
                    await fetchRolePermissions();
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
            close();
        };

        const groupedPermissions = ref({});

        const updateGroupedPermissions = () => {
            const sortPrefixOrder = ['VIEW_','ADMIN_VIEW_', 'READ_', 'ADMIN_READ_', 'ADMIN_WRITE_', 'WRITE_', 'CREATE_','DELETE_'];

            const sortedPermissions = permissions.value.slice().sort((a, b) => {
                const aPrefix = sortPrefixOrder.findIndex((prefix) => a.name.startsWith(prefix));
                const bPrefix = sortPrefixOrder.findIndex((prefix) => b.name.startsWith(prefix));
                
                if (aPrefix !== bPrefix) {
                return aPrefix - bPrefix;
                }

                return a.name.localeCompare(b.name);
            });

            const newGroupedPermissions = sortedPermissions.reduce((groups, permission) => {
                const site = permission.site;
                if (!groups[site]) {
                groups[site] = [];
                }
                groups[site].push(permission);
                return groups;
            }, {});

            groupedPermissions.value = newGroupedPermissions;
        };

        watch(permissions, updateGroupedPermissions, { immediate: true });



        onMounted(async () => {
            await fetchPermissions();
            await fetchRoles();
            await fetchRolePermissions();
        });

    return {
      headers,
      roles,
      permissions,
      rolePermissions,
      dialog,
      editedRole,
      fetchPermissions,
      fetchRoles,
      openDialog,
      close,
      save,
      groupedPermissions,
      errorSnackbar,
      dialogTitle: computed(() => (editedRole.id ? 'Edit Role' : 'Add Role')),
    };
  },
});
</script>
<style scoped>
    .checkbox-spacing {
        margin-bottom: -40px;
    }
  </style>