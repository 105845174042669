<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
        <v-row>
            <v-col cols="5" xl="4" xxl="3">
                <v-row>
                    <v-col cols="6">
                        <v-card style="height: calc(92vh - 64px); overflow-y: auto;">
                            <v-card-title>Mitarbeiter</v-card-title>
                            <v-card-text>
                                <draggable v-model="employees" item-key="id" group="employees" @end="onEmployeeDrop">
                                    <template #item="{ element }">
                                        <v-card class="mb-2" :data-id="element.id">
                                            <v-card-text><span style="opacity: 0.7;">[{{ element.servicenumber }}] {{ element.name }}</span><br>{{ element.rank_name }}</v-card-text>
                                        </v-card>
                                    </template>
                                </draggable>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card style="height: calc(92vh - 64px); overflow-y: auto;">
                            <v-card-title>Fahrzeuge</v-card-title>
                            <v-card-text>
                                <draggable v-model="vehicles" item-key="id" group="vehicles" @end="onVehicleDrop" style="min-height:500px">
                                    <template #item="{ element }">
                                        <v-card class="mb-2" :data-id="element.id">
                                            <v-card-text><span style="opacity: 0.7;">{{ element.title }} ({{ element.numberplate }})</span><br> Ab Rang: {{ element.rank }}</v-card-text>
                                        </v-card>
                                    </template>
                                </draggable>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="7" xl="8" xxl="9">
                <v-row>
                    <v-col v-for="dispatch in dispatches" :key="dispatch.id" cols="5" xl="3" xxl="2">
                        <v-card :data-id="dispatch.id">
                            <v-card-title>{{ dispatch.name }}</v-card-title>
                            <v-card-text>
                                <draggable v-model="dispatch.employees" item-key="id" group="employees" @end="onEmployeeDrop" style="min-height: 200px; border-radius: 4px; border: 1px solid #333232;">
                                    <template #item="{ element }">
                                        <v-card class="mb-2" :data-id="element.id">
                                            <v-card-text><span style="opacity: 0.7;">[{{ element.servicenumber }}] {{ element.name }}</span><br>{{ element.rank_name }}</v-card-text>
                                        </v-card>
                                    </template>
                                </draggable>
                                <draggable v-model="dispatch.vehicles" item-key="id" group="vehicles" @end="onVehicleDrop" style="min-height: 75px; border-radius: 4px; border: 1px solid #333232;" class="mt-2">
                                    <template #item="{ element }">
                                        <v-card class="mb-2" :data-id="element.id">
                                            <v-card-text><span style="opacity: 0.7;">{{ element.title }} ({{ element.numberplate }})</span><br> Ab Rang: {{ element.rank }}</v-card-text>
                                        </v-card>
                                    </template>
                                </draggable>
                            </v-card-text>
                            <v-card-subtitle>
                                <v-text-field
                                    v-model="dispatch.status"
                                    append-inner-icon="mdi-content-save"
                                    density="compact"
                                    label="Status"
                                    variant="solo"
                                    @click:append-inner="saveDispatch(dispatch)"
                                ></v-text-field>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import api from '@/api';
import draggable from 'vuedraggable';
import { useStore } from 'vuex';

export default defineComponent({
    components: {
        draggable,
    },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const employees = ref([]);
        const vehicles = ref([]);
        const dispatches = ref([]);
        const socket = ref<WebSocket | null>(null);
        const store = useStore();
        

        onMounted(() => {
            fetchEmployees();
            fetchVehicles();
            fetchDispatches();
            setupWebSocket();
        });

        function setupWebSocket() {
            const token = store.state.jwt;
			if (!token) return;

			socket.value = new WebSocket(`wss://system.kyuubiddragon.com/restapi/ws/?token=${token}`);

            socket.value.onopen = () => {
                console.log('WebSocket connected');
            };

            socket.value.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === 'update' && data.site === 'dispatch') {
                    fetchEmployees();
                    fetchVehicles();
                    fetchDispatches();
                }
            };

            socket.value.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            socket.value.onclose = () => {
                console.log('WebSocket disconnected');
                // Automatically attempt to reconnect after 5 seconds
                setTimeout(setupWebSocket, 5000);
            };
        }

        async function fetchEmployees() {
            try {
                const response = await api.post("dispatch/?action=getEmployees");
                employees.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        }

        async function fetchVehicles() {
            try {
                const response = await api.post("dispatch/?action=getVehicles");
                vehicles.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        }

        async function fetchDispatches() {
            try {
                const response = await api.post("dispatch/?action=getDispatches");
                dispatches.value = response.data.map(dispatch => ({
                    ...dispatch,
                    employees: dispatch.employees || [],
                    vehicles: dispatch.vehicles || []
                }));
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        }

        async function saveDispatch(dispatch) {
            try {
                const response = await api.post("dispatch/?action=saveDispatch", { id: dispatch.id, status: dispatch.status });
                if (response.data.success) {
                    errorSnackbar.value.message = 'Erfolgreich gespeichert';
                    errorSnackbar.value.visible = true;
                    errorSnackbar.value.color = 'green';

                    socket.value?.send(JSON.stringify({ type: 'update', site: 'dispatch' }));
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        }

        async function assignEmployeeToDispatch(employeeId, dispatchId) {
            try {
                await api.post("dispatch/?action=assignEmployee", { employee_id: employeeId, dispatch_id: dispatchId });
                socket.value?.send(JSON.stringify({ type: 'update', site: 'dispatch' }));
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        }

        async function assignVehicleToDispatch(vehicleId, dispatchId) {
            try {
                await api.post("dispatch/?action=assignVehicle", { vehicle_id: vehicleId, dispatch_id: dispatchId });
                socket.value?.send(JSON.stringify({ type: 'update', site: 'dispatch' }));
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible = true;
            }
        }

        function onEmployeeDrop(evt) {
            const employeeId = evt.item.dataset.id;
            const newDispatch = evt.to.closest('.v-card');
            const oldDispatch = evt.from.closest('.v-card');
            const newDispatchId = newDispatch ? newDispatch.dataset.id : null;
            const oldDispatchId = oldDispatch ? oldDispatch.dataset.id : null;

            if (newDispatchId) {
                assignEmployeeToDispatch(employeeId, parseInt(newDispatchId));
            } else if (oldDispatchId) {
                assignEmployeeToDispatch(employeeId, 0);
            }
        }

        function onVehicleDrop(evt) {
            const vehicleId = evt.item.dataset.id;
            const newDispatch = evt.to.closest('.v-card');
            const oldDispatch = evt.from.closest('.v-card');
            const newDispatchId = newDispatch ? newDispatch.dataset.id : null;
            const oldDispatchId = oldDispatch ? oldDispatch.dataset.id : null;

            if (newDispatchId) {
                assignVehicleToDispatch(vehicleId, parseInt(newDispatchId));
            } else if (oldDispatchId) {
                assignVehicleToDispatch(vehicleId, 0);
            }
        }

        return {
            errorSnackbar,
            employees,
            vehicles,
            dispatches,
            saveDispatch,
            onEmployeeDrop,
            onVehicleDrop
        }
    }
});
</script>

<style>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Adjust this value if needed */
}
</style>
