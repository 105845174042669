import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_VacationCard = _resolveComponent("VacationCard")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleShowRecentReturns(30))),
                  class: "ml-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Letzte 30 Tage ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleShowRecentReturns(7))),
                  class: "ml-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Letzte 7 Tage ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  onClick: _ctx.toggleShowVacations,
                  class: "ml-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Aktuell ")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_btn, {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleShowRecentVacations(7))),
                  class: "ml-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Nächste 7 Tage ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleShowRecentVacations(30))),
                  class: "ml-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Nächste 30 Tage ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.search) = $event)),
                  label: "Search",
                  dark: "",
                  outlined: "",
                  "hide-details": "",
                  onInput: _ctx.updateFilteredItems
                }, null, 8, ["modelValue", "onInput"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ranksWithEmployee, (rank, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rank.employees.filter(item => item.name.toLowerCase().includes(_ctx.search.toLowerCase())), (employee, employeeIndex) => {
                  return (_openBlock(), _createBlock(_component_VacationCard, {
                    key: employeeIndex,
                    member: employee,
                    logoURL: rank.rankImage,
                    companies: _ctx.companies,
                    departments: _ctx.departments,
                    ranks: _ctx.ranks,
                    licenses: _ctx.licenses,
                    showPreview: _ctx.showPreview,
                    showIsTerminated: _ctx.showIsTerminated,
                    showRecentReturns: _ctx.showRecentReturns,
                    showRecentVacations: _ctx.showRecentVacations,
                    filterdays: _ctx.filterdays
                  }, null, 8, ["member", "logoURL", "companies", "departments", "ranks", "licenses", "showPreview", "showIsTerminated", "showRecentReturns", "showRecentVacations", "filterdays"]))
                }), 128))
              ], 64))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}