<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
        <h1>Bewerberfragen</h1>
        <v-divider></v-divider>
        <v-row class="mt-4">
            <v-col cols="12" sm="6" md="4" v-for="(docType, index) in documentTypes" :key="index">
                <v-card @click="openApplicantQuestionEditor(docType.id)">
                    <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;">
                    <div style="display: flex; justify-content: flex-end;">
                        <v-btn class="mr-2 mt-2" density="comfortable" icon="mdi-sort" @click.stop="openSortingDialog(docType.id)"></v-btn>
                    </div>
                    <v-card-text class="d-flex justify-center align-center mt-n4">
                        <v-icon size="60">mdi-file-document</v-icon>
                    </v-card-text>
                    <v-card-title class="d-flex justify-center align-center">{{ docType.name }}</v-card-title>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="1000" persistent>
            <v-card>
                <v-card-title>Edit Questions</v-card-title>
                <v-card-text>
                <div v-for="(element) in filteredQuestions" :key="element.id" class="d-flex mb-2 align-center">
                    <v-text-field v-model="element.question" label="Question" dense append-icon="mdi-close" @click:append="openDeleteConfirmationDialog(element.id)"></v-text-field>
                </div>
                <v-text-field
                    v-model="newQuestion"
                    label="New Question"
                    dense
                    @keydown.enter.prevent="addQuestion"
                ></v-text-field>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" text @click="dialog = false">Cancel</v-btn>
                <v-btn text @click="saveQuestions">Änderungen speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="sortingDialog" max-width="1000" persistent>
            <v-card>
                <v-card-title>Sort Questions</v-card-title>
                <v-card-text>
                <draggable v-model="filteredQuestions"  :list="filteredQuestions" tag="transition-group" :animation="150">
                    <template v-slot:item="{ element }">
                        <div :key="element.id" class="d-flex mb-2 align-center">
                            <v-icon class="mt-1 mr-2" style="cursor: move;">mdi-drag</v-icon>
                            <span>{{ element.question }}</span>
                        </div>
                    </template>
                </draggable>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" text @click="sortingDialog = false">Cancel</v-btn>
                <v-btn text @click="saveQuestionsSort">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteConfirmationDialog" max-width="400">
            <v-card>
                <v-card-title class="headline">Are you sure?</v-card-title>
                <v-card-text>
                    Do you really want to delete this question? This action cannot be undone.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteConfirmationDialog = false">Cancel</v-btn>
                    <v-btn color="danger" text @click="() => { deleteQuestion(questionToDelete); deleteConfirmationDialog = false; }">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import draggable from 'vuedraggable';
  import { Question } from '@/types/Application';
  import api from '@/api';
  import { JobTypes } from '@/types/Members';
  
  export default defineComponent({
    components: {
        draggable,
    },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const dialog = ref(false);
        const sortingDialog = ref(false);
        const questions = ref<Question[]>([]);
        const newQuestion = ref('');
        const filteredQuestions = ref<Question[]>([]);
        const currentDocumentId = ref<number | null>(null);
        const deleteConfirmationDialog = ref(false);
        const questionToDelete = ref<number | null>(null);

        const documentTypes = ref<JobTypes[]>([]);
        async function fetchJobTypes() {
            try {
                const response = await api.get('employee/index_fetch.php?action=getJobTypes');
                documentTypes.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
                documentTypes.value = [];
            }
        }




        function filterQuestions(id: number) {
            // Filtern Sie die Fragen basierend auf der "documentId"
            filteredQuestions.value = questions.value.filter((question) => question.type === id);
        }
    
        function openApplicantQuestionEditor(documentId: number) {
            currentDocumentId.value = documentId;
            filterQuestions(documentId); // Filtern Sie die Fragen basierend auf der gewählten "documentId"
            dialog.value = true;
        }

        function openSortingDialog(documentId: number) {
            filterQuestions(documentId); // Filtern Sie die Fragen basierend auf der gewählten "documentId"
            sortingDialog.value = true;
        }
    
        function openDeleteConfirmationDialog(questionId: number) {
            questionToDelete.value = questionId;
            deleteConfirmationDialog.value = true;
        }

        const deleteQuestion = async (questionId: number) => {
            try {
                const response = await api.post("admin/application/?action=deleteQuestion", {
                    id: questionId
                });

                if (response.data.success) {
                    console.log("Question deleted successfully");
                    filteredQuestions.value = filteredQuestions.value.filter((question) => question.id !== questionId);
                } else {
                    console.error("Failed to delete question:", response.data.error);
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }


        const saveQuestions = async () => {
            try {
                console.log("Sending questions data:", filteredQuestions.value);
                const response = await api.post("admin/application/?action=saveQuestions", JSON.stringify(filteredQuestions.value));

                if (response.data.success) {
                    console.log("Question saved successfully");
                    fetchQuestions();
                } else {
                console.error("Failed to save question:", response.data.error);
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
            dialog.value = false;
        };

        const saveQuestionsSort = async () => {
            try {
                console.log("Sending category data:", filteredQuestions.value);
                const response = await api.post("admin/application/?action=saveQuestionSorting", JSON.stringify(filteredQuestions.value));

                if (response.data.success) {
                console.log("Question sorting saved successfully");
                } else {
                console.error("Failed to save question sorting:", response.data.error);
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }

            sortingDialog.value = false;
        };



        async function fetchQuestions() {
            try {
            const response = await api.get('admin/application/?action=getQuestions');
                questions.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
                questions.value = [];
            }
        }

        async function addQuestion() {
            if (newQuestion.value.trim() && currentDocumentId.value) {
                const success = await saveNewQuestion(newQuestion.value, currentDocumentId.value);
                if (success) {
                newQuestion.value = "";
                }
            }
        }



        async function saveNewQuestion(newQuestion: string, documentId: number) {
            try {
                const response = await api.post("admin/application/?action=addQuestion", {
                    question: newQuestion,
                    type: documentId,
                    sort_order: filteredQuestions.value.length,
                });

                filteredQuestions.value.push(response.data);

                return true;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
                return false;
            }
        }



        onMounted(async () => {
            await fetchJobTypes();
            await fetchQuestions();
        })

        return {
            documentTypes,
            dialog,
            sortingDialog,
            questions,
            newQuestion,
            openApplicantQuestionEditor,
            openDeleteConfirmationDialog,
            deleteQuestion,
            saveQuestions,
            filteredQuestions,
            openSortingDialog,
            addQuestion,
            saveQuestionsSort,
            deleteConfirmationDialog,
            questionToDelete,
            errorSnackbar,
        };

    },
});
</script>
<style>
  .handle {
    cursor: grab;
  }
</style>