<template>
    <v-app>
        <v-navigation-drawer app class="navbar-drawer-dark" dark v-model="drawer" v-if="isLoggedIn && !isLoadingUser">
            <v-img :src="globalSettings.siteLogo" max-height="128" min-height="128" contain></v-img>
            <!-- Hier kommt der Inhalt für die Navigation Bar rein -->
            <v-list density="compact" nav>
                <v-list-item prepend-icon="mdi-view-dashboard" title="Dashboard" value="dashboard" to="/dashboard"></v-list-item>
                <v-list-group value="Admin" prepend-icon="mdi-security" v-if="hasAdminPermission()">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-security" title="Admin"></v-list-item>
                    </template>
                    <v-list-item title="Benutzer" value="user" to="/admin/users" v-if="hasPermission('ADMIN_VIEW_USERS')"></v-list-item>
                    <v-list-item title="Rollen" value="roles" to="/admin/roles" v-if="hasPermission('ADMIN_VIEW_ROLES')"></v-list-item>
                    <v-list-item title="Mitarbeiter" value="employees" to="/admin/employees" v-if="hasPermission('ADMIN_VIEW_EMPLOYEE')"></v-list-item>
                    <v-list-item title="Schulungen" value="admin_training" to="/admin/trainings" v-if="hasPermission('ADMIN_VIEW_TRAINING')"></v-list-item>
                    <v-list-item title="Bewerberfragen" value="applicationquestions" to="/admin/applicationquestions" v-if="hasPermission('ADMIN_VIEW_APPLICATION')"></v-list-item>
                    <v-list-item title="Nachrichten" value="adminmessages" to="/admin/messages" v-if="hasPermission('ADMIN_VIEW_MESSAGES')"></v-list-item>
                    <v-list-item title="Karte" value="adminmap" to="/admin/map" v-if="hasPermission('ADMIN_VIEW_MAP')"></v-list-item>
                    <v-list-item title="Wetter" value="weather" to="/admin/weather" v-if="hasPermission('ADMIN_VIEW_WEATHER')"></v-list-item>
                    <v-list-item title="Settings" value="settings" to="/admin/settings" v-if="hasPermission('ADMIN_VIEW_SETTINGS')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                <v-list-group value="Leitstelle" prepend-icon="mdi-fire-truck" v-if="hasPermission('VIEW_DISPATCH') || hasPermission('VIEW_VEHICLE')">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-fire-truck" title="Leitstelle"></v-list-item>
                    </template>
                    <v-list-item title="Leitstelle" value="dispatch" to="/dispatch" v-if="hasPermission('VIEW_DISPATCH')"></v-list-item>
                    <v-list-item title="Fahrzeuge" value="vehicle" to="/vehicle" v-if="hasPermission('VIEW_VEHICLE')"></v-list-item>
                    <v-list-item title="Besatzung" value="crew" to="/crew" v-if="hasPermission('VIEW_CREW')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                <v-list-group value="Schwarzes Brett" prepend-icon="mdi-developer-board" v-if="hasPermission('VIEW_BLACKBOARD_ADMIN') || hasPermission('VIEW_BLACKBOARD_EMPLOYEE')">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-developer-board" title="Schwarzes Brett"></v-list-item>
                    </template>
                    <v-list-item title="Schwarzes Brett" value="blackboardAdmin" to="/blackboard/admin" v-if="hasPermission('VIEW_BLACKBOARD_ADMIN')"></v-list-item>
                    <v-list-item title="Schwarzes Brett Mitarbeiter" value="blackboardEmployee" to="/blackboard/employee" v-if="hasPermission('VIEW_BLACKBOARD_EMPLOYEE')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                <v-list-group value="Mitarbeiter" prepend-icon="mdi-account-group" v-if="hasPermission('VIEW_EMPLOYEE')">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-account-group" title="Mitarbeiter"></v-list-item>
                    </template>
                    <v-list-item :title="globalSettings.employeeNavigation || 'Mitarbeiter'" value="employee" to="/employee" v-if="hasPermission('VIEW_EMPLOYEE')"></v-list-item>
                    <v-list-item :title="'Urlaube'" value="vacation" to="/vacation" v-if="hasPermission('VIEW_EMPLOYEE')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                <v-list-group value="Unternehmen" prepend-icon="mdi-domain" v-if="hasPermission('VIEW_COMPANY')">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-domain" title="Unternehmen"></v-list-item>
                    </template>
                    <v-list-item :title="'Unternehmen'" value="company" to="/company" v-if="hasPermission('VIEW_COMPANY')"></v-list-item>
                    <v-list-item :title="'Typ'" value="companytype" to="/companytype" v-if="hasPermission('VIEW_COMPANYTYPE')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                <v-list-item prepend-icon="mdi-file-edit-outline" title="Templates" value="template" to="/template" v-if="hasPermission('VIEW_TEMPLATE')"></v-list-item>
                <v-list-subheader v-if="hasPermission('VIEW_TODO') || hasPermission('VIEW_CALENDAR') || hasPermission('VIEW_APPLICATION') || hasPermission('VIEW_REPORT')">Organisation</v-list-subheader>
                <v-divider></v-divider>
                <v-list-group value="Berichte" prepend-icon="mdi-fire-truck" v-if="hasPermission('VIEW_REPORT')">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-fire-truck" title="Berichte"></v-list-item>
                    </template>
                    <v-list-item title="Berichte" value="report" to="/report" v-if="hasPermission('VIEW_REPORT')"></v-list-item>
                    <v-list-item title="Kategorien" value="reportcategory" to="/reportcategory" v-if="hasPermission('VIEW_REPORTCATEGORY')"></v-list-item>
                    <v-list-item title="Vorlagen" value="reporttemplate" to="/reporttemplate" v-if="hasPermission('VIEW_REPORTTEMPLATE')"></v-list-item>
                    <v-list-item title="Code" value="reportcode" to="/reportcode" v-if="hasPermission('VIEW_REPORTCODE')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                <v-list-item prepend-icon="mdi-format-list-checks" title="To-Do" value="todo" to="/todo" v-if="hasPermission('VIEW_TODO')"></v-list-item>
                <v-list-item prepend-icon="mdi-calendar-clock" title="Calendar" value="calendar" to="/calendar" v-if="hasPermission('VIEW_CALENDAR')"></v-list-item>
                <v-list-item prepend-icon="mdi-human-edit" title="Applications" value="application" to="/application" v-if="hasPermission('VIEW_APPLICATION')"></v-list-item>
                <v-list-subheader v-if="hasPermission('VIEW_DOCUMENT') || hasPermission('VIEW_DOCUMENT_TRAINING') || hasPermission('VIEW_DOCUMENT_ADMINISTRATION')">Allgemeines</v-list-subheader>
                <v-divider></v-divider>
                <v-list-group value="Dokumente" prepend-icon="mdi-file-document-outline" v-if="hasPermission('VIEW_DOCUMENT') || hasPermission('VIEW_DOCUMENT_TRAINING') || hasPermission('VIEW_DOCUMENT_ADMINISTRATION')">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-file-document-outline" title="Dokumente"></v-list-item>
                    </template>
                    <v-list-item title="FireDepartment" value="firedepartment" to="/firedepartment" v-if="hasPermission('VIEW_DOCUMENT_FIREDEPARTMENT')"></v-list-item>
                    <v-list-item title="Documents" value="document" to="/document" v-if="hasPermission('VIEW_DOCUMENT')"></v-list-item>
                    <v-list-item title="Trainings" value="trainings" to="/training" v-if="hasPermission('VIEW_DOCUMENT_TRAINING')"></v-list-item>
                    <v-list-item title="Administration" value="administration" to="/administration" v-if="hasPermission('VIEW_DOCUMENT_ADMINISTRATION')"></v-list-item>
                    <v-list-item title="Test" value="tests" to="/test" v-if="hasPermission('VIEW_TEST')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                <v-list-group value="Schulungen" prepend-icon="mdi-book-open" v-if="hasPermission('VIEW_EMPLOYEE') || hasPermission('VIEW_TEST')">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" prepend-icon="mdi-book-open" title="Schulungen"></v-list-item>
                    </template>
                    <v-list-item :title="'Übersicht'" value="trainingassign" to="/trainingassign" v-if="hasPermission('VIEW_TRAININGASSIGN')"></v-list-item>
                    <v-list-item title="Test Generieren" value="tests" to="/test" v-if="hasPermission('VIEW_TEST')"></v-list-item>
                    <v-divider></v-divider>
                </v-list-group>
                <v-list-subheader v-if="hasPermission('VIEW_FIREPROTECTION') || hasPermission('VIEW_MAP') || hasPermission('VIEW_DOCUMENT_ADMINISTRATION')">Sonstiges</v-list-subheader>
                <v-divider></v-divider>
                <v-list-item prepend-icon="mdi-fire" title="Fire Protection" value="fireprotection" to="/fireprotection" v-if="hasPermission('VIEW_FIREPROTECTION')"></v-list-item>
                <v-list-item prepend-icon="mdi-map" title="Karte" value="map" to="/map" v-if="hasPermission('VIEW_MAP')"></v-list-item>
                <v-list-item prepend-icon="mdi-note-text" title="Cheatsheet" value="cheatsheet" to="/cheatsheet"></v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar app dark v-if="isLoggedIn">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>{{ globalSettings.siteName || 'Kyuubi D. Dragon Administration Tool' }}</v-toolbar-title>
            <!-- Hier können weitere Elemente für die Navigation Bar eingefügt werden -->
            <v-spacer></v-spacer>
            <template v-if="isLoggedIn">
                <v-btn icon @click="goToMessages">
                    <v-badge :content="unreadMessagesCount" color="red">
                        <v-icon>mdi-email</v-icon>
                    </v-badge>
                </v-btn>
                <v-avatar>
                    <img src="https://via.placeholder.com/40" alt="Profile Image" />
                </v-avatar>
                <v-menu offset-y>
                    <template v-slot:activator="{ props }">
                        <v-btn text v-bind="props">{{ userProfile.name }}</v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="goToProfile">
                            <v-list-item-title>Profil anzeigen</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="editProfile">
                            <v-list-item-title>Profil bearbeiten</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="logout">
                            <v-list-item-title>Ausloggen</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-app-bar>
        <v-main id="web-body">
            <!-- Hier kommt der Hauptinhalt der Anwendung rein -->
            <router-view />
        </v-main>
        <!-- Snackbar für Benachrichtigungen -->
        <v-snackbar
            v-for="(notification, index) in notifications"
            :key="index"
            v-model="notification.visible"
            location="bottom right"
            timeout="5000"
            multi-line
            elevation="24"
            :stacked="true"
        >
            <div>
                <div>Neue Nachricht</div>
                <div>Von: {{ notification.senderName }}</div>
                <div>Titel: {{ notification.title }}</div>
            </div>
        </v-snackbar>
    </v-app>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import api from "@/api";
import { isTokenExpired } from "@/store";
import { GlobalSettings } from "@/types/Settings";

export default defineComponent({
    name: 'App',
    setup() {
        const drawer = ref(true);
        const store = useStore();
        const router = useRouter();
        const globalSettings = ref<GlobalSettings>({});
        const socket = ref<WebSocket | null>(null);
        const notifications = ref<{title: string, senderName: string, visible: boolean}[]>([]);

        const setupWebSocket = () => {
            const token = store.state.jwt;
            if (!token) return;

            socket.value = new WebSocket(`wss://system.kyuubiddragon.com/restapi/ws/?token=${token}`);

            socket.value.onopen = () => {
                console.log('WebSocket connected');
            };

            socket.value.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === 'update' && data.site === 'message') {
                    fetchUnreadMessagesCount();
                    showNotification(data.title, data.sender_name);
                }
                console.log('WebSocket message received:', data);
            };

            socket.value.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            socket.value.onclose = () => {
                console.log('WebSocket disconnected');
                // Automatically attempt to reconnect after 5 seconds
                setTimeout(setupWebSocket, 5000);
            };
        };

        const fetchGlobalSettings = async () => {
            try {
                const response = await api.get("settings/?action=getGlobalSettings");
                if (response.data && response.data.settings) {
                    return response.data.settings;
                }
            } catch (error) {
                console.error("Error fetching global settings:", error);
            }
            return {};
        };

        const fetchSettings = async () => {
            const settings = await fetchGlobalSettings();
            globalSettings.value = settings;
        };

        const isLoggedIn = computed(() => {
            const state = store.state;
            return !!state.jwt && !isTokenExpired(state);
        });

        const userProfile = computed(() => store.state.user);
        const unreadMessagesCount = computed(() => store.state.user?.unreadMessagesCount || 0);

        const fetchUnreadMessagesCount = async () => {
            try {
                const response = await api.get("message/?action=getUnreadMessagesCount");
                store.dispatch('updateUnreadMessagesCount', response.data.unread_count);
            } catch (error) {
                console.error("Error fetching unread messages count:", error);
            }
        };

        const goToMessages = () => {
            router.push('/message');
        };

        const goToProfile = () => {
            router.push('/profile');
        };

        const editProfile = () => {
            router.push('/edit-profile');
        };

        const logout = async () => {
            try {
                await api.post("account/?action=logout");
                store.dispatch('logout');
                router.push({ name: 'login' });
            } catch (error) {
                console.error('Error logging out:', error);
            }
        };

        const hasPermission = (permissionName) => {
            const profile = userProfile.value;
            if (profile && profile.permissions) {
                return profile.permissions.includes(permissionName) || profile.permissions.includes('ALL_PERMISSIONS');
            }
            return false;
        };

        const hasAdminPermission = () => {
            const profile = userProfile.value;
            if (profile && profile.permissions) {
                return profile.permissions.some((perm) => perm.startsWith('ADMIN_VIEW_')) || profile.permissions.includes('ALL_PERMISSIONS');
            }
            return false;
        };

        const showNotification = (title, senderName) => {
            notifications.value.push({ title, senderName, visible: true });
        };

        onMounted(async () => {
            await fetchSettings();
            await fetchUnreadMessagesCount();
            if (store.state.jwt && !isTokenExpired(store.state)) {
                setupWebSocket();
            }
        });

        onBeforeMount(async () => {
            await store.dispatch('reloadUserData'); // Load user data on mount
        });

        return {
            drawer,
            isLoggedIn,
            userProfile,
            goToProfile,
            editProfile,
            logout,
            hasPermission,
            hasAdminPermission,
            globalSettings,
            unreadMessagesCount,
            goToMessages,
            notifications,
            isLoadingUser: computed(() => store.state.isLoadingUser),
        };
    }
});
</script>

<style lang="scss">
#web-body {
    background-image: url('@/assets/background.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
}

.disable-scroll {
    overflow: hidden;
    height: 100%;
}

.ck-sticky-panel__content_sticky.ck > .ck-toolbar {
    margin-top: 64px !important;
    top: 64px !important;
}

.ck-editor__editable {
    resize: vertical;
    overflow: auto;
    min-height: 500px;
    padding: 30px;
}

.ck.ck-editor__main > .ck-editor__editable {
    padding-left: 30px;
}

.ck.ck-editor__top.ck-reset_all {
    z-index: var(--ck-z-modal);
    position: -webkit-sticky; /* Für Safari-Browser */
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--ck-color-toolbar-background);
}

.ck-content ul,
.ck-content ol {
    padding-left: 1.5em;
    margin-bottom: 1em;
}

.ck-content ul ul,
.ck-content ol ul,
.ck-content ul ol,
.ck-content ol ol {
    margin-top: 0;
    margin-bottom: 0;
}

.ck-content li {
    margin-bottom: 0.5em;
}

.ck-content li:last-child {
    margin-bottom: 0;
}

.ck-content blockquote {
    border-left: 1px solid #cccccc36 !important;
    font-style: italic;
    margin-left: 40px !important;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.ck-editor__editable {
    resize: vertical;
    overflow: hidden !important;
    min-height: 500px;
    padding: 30px;
}

.ck.ck-editor__main > .ck-editor__editable {
    padding-left: 30px;
}

.ck.ck-editor__top.ck-reset_all {
    z-index: var (--ck-z-modal);
    position: -webkit-sticky; /* Für Safari-Browser */
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var (--ck-color-toolbar-background);
}
.v-snackbar--multi-line .v-snackbar__wrapper {
  background-color: #212121;
  color: #ffffffab;
  border-radius: 8px;
}
</style>
