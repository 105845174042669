<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <!-- Eingabefelder -->
    <v-container>
        <v-row>
        <v-col cols="4" sm="4">
            <v-row>
            <v-col cols="12" sm="12">
                <v-select
                v-model="selectedType"
                :items="typeOptions"
                label="Typ"
                outlined
                ></v-select>
            </v-col>
        </v-row>
            <v-row>
            <v-col cols="12" sm="12">
                <v-text-field
                v-model="issuedOn"
                label="Ausgestellt am"
                type="date"
                outlined
                ></v-text-field>
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="12" sm="12">
                <v-text-field
                v-model="performedFrom"
                label="Durchgeführt von"
                outlined
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12">
                <v-text-field
                v-model="creator"
                label="Ausgestellt von"
                outlined
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12">
                <v-text-field
                v-model="office"
                label="Office"
                outlined
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12">
                <v-text-field
                v-model="signature"
                label="Unterschrfit (KEIN DROPBOX LINK)"
                outlined
                ></v-text-field>
            </v-col>
        </v-row>
            <v-row>
            <v-col cols="12" sm="12">
                <v-text-field
                v-model="objectFireProtection"
                label="Objekt"
                outlined
                ></v-text-field>
            </v-col>
            </v-row>
            <v-row>
            <v-col cols="12" sm="12">
                <v-text-field
                v-model="location"
                label="Standort"
                outlined
                ></v-text-field>
            </v-col>
        </v-row>
            <v-row>
            <v-col cols="12" sm="12">
                <v-text-field
                v-model="reportNumber"
                label="Gutachten-Nr"
                outlined
                ></v-text-field>
            </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" v-if="selectedType == 'Event'">
                    <v-text-field
                    v-model="validTo"
                    label="Gültig von:"
                    type="date"
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" v-if="selectedType == 'Event'">
                    <v-text-field
                    v-model="validFrom"
                    label="Gültig bis:"
                    type="date"
                    outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" v-if="shareableLink">
                <!-- In Ihrem Vue.js HTML-Template -->
                    <p>Dateiname: {{ shareableLinkFileName }}</p>
                    <p>Link: {{ shareableLink }}</p>
                    <!-- v-text-field mit dem gewünschten Text -->
                    <v-text-field
                        :value="'<img alt=&quot;&quot; src=&quot;' + shareableLink + '&quot; style=&quot;height:849px; width:600px&quot; />'"
                        readonly
                    ></v-text-field>
                    <v-btn @click="downloadImage">Bild herunterladen</v-btn>
                    <v-btn class="ml-3" @click="copyMail">Mail kopieren</v-btn>
                </v-col>

                <v-col cols="12" sm="12">
                <v-btn @click="submit" :loading="isLoading">Absenden</v-btn>
                </v-col>

                <v-card>
                    <v-data-table 
                        :headers="headers" 
                        :items="uploadedFiles"
                        density="compact"
                        :items-per-page="5"
                        class="custom-table-no-footer"
                    ></v-data-table>
                </v-card>

            </v-row>
        </v-col>
        <v-col cols="6" sm="6">
            <!-- A4-Seite -->
            <div id="a4-page" class="fireprotection-doc-background">
                <div style="text-align: center; width: 100%;">
                    <div class="fireprotection-doc-header-text">Los Santos Fire Department</div>
                    <img
                        :src="require('@/assets/pdf_top.png')"
                        style="width: 100%; height: auto;"
                    >
                    <div class="fireprotection-doc-header-subtext">Ausgestellt durch das Los Santos Fire Department</div>
                </div>
                
                <div style="display: absolute; justify-content: center;">
                    <img
                        :src="require('@/assets/logo.png')"
                        style="height: auto; width: 500px; opacity: 0.1; position: absolute;margin-left: calc((105mm) - 250px);margin-top: calc((103mm) - 250px);"
                    >
                </div>

                <v-container style="height:100%">
                    <v-row class="fireprotection-doc-table font-weight-bold">
                        <v-col cols="6">
                            <div style="text-align: right;">
                                Ausgestellt am:
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="text-align: left;">
                                {{ formatDate(issuedOn) }}
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="text-align: right;">
                                Durchgeführt von:
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="text-align: left;">
                                {{ performedFrom }}
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="text-align: right;">
                                {{ selectedType == 'Event' ? "Objekt | Event:" : "Objekt:" }}
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="text-align: left;">
                                {{ objectFireProtection }}
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="text-align: right;">
                                Standort:
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="text-align: left;">
                                {{ location }}
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="text-align: right;">
                                Gutachten-Nr:
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="text-align: left;">
                                {{ formatDate(issuedOn, true) + "-VB-" + reportNumber }}
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="text-align: right;">
                                {{ selectedType == 'Event' ? "Gültig von - bis:" : "Gültig bis:" }}
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div style="text-align: left;">
                                {{ 
                                    selectedType == 'Event' ? formatDate(validTo) + " - " + formatDate(validFrom) : selectedType == 'Unternehmen' ? 'Unbefristet (bis zu baulichen Veränderungen)' : addMonthsToDate(issuedOn, 6)
                                }}

                            </div>
                        </v-col>
                    </v-row>

                    <div style="padding: 0 20mm; text-align: center; padding-top: 43mm;">
                        <p>Brandschutzmaßnahmen wurden vorgenommen und bestätigt</p>
                        <p style="padding-top: 4mm;">Keine Mängel festgestellt</p>
                        <p class="font-weight-bold" style="padding: 14mm;">
                            Der Brandschutz ist gewährleistet
                        </p>
                        <p style="font-size: 11px; padding-top:3mm;">
                            {{ selectedType == 'Event' ? "Das Zertifikat ist für den oben in “Gültig von - bis” genannten Zeitraum gültig." : selectedType == 'Unternehmen' ? "Das Zertifikat ist nach §2 Brandschutzgesetz bis zu einem Umbau gültig." : "Das Zertifikat ist nach §2 Brandschutzgesetz 6 Monate gültig." }}
                        </p>
                        <p style="font-size: 11px;padding: 5mm;">
                            {{ selectedType == 'Event' ? "Das Brandschutzzertifikat wird nicht fortlaufend aktualisiert." : "Das Brandschutzzertifikat wird weiterhin fortlaufend vom Los Santos Fire Department aktualisiert." }}
                        </p>
                        <!-- Signatur -->
                    </div>

                    <div style="text-align: center;">
                        <p style="padding-top:21mm">Mit freundlichen Grüßen</p>
                        <p class="font-weight-bold" style="padding:3mm">{{ creator }}</p>
                        <p style="padding-bottom: 6mm;">{{ office }}</p>
                        <img
                            v-show="signature != ''"
                            :src="signature"
                            style="height:54px; margin-left: auto;margin-right: auto;"
                        >
                    </div>
                    
                </v-container>

                <div style="text-align: center; width: 100%;">
                    <img
                    :src="require('@/assets/pdf_bottom.png')"
                    style="width: 100%; height: auto;"
                    >
                    <div class="fireprotection-doc-footer-text">Brandschutzdienststelle des LSFD</div>
                </div>
            </div>
        </v-col>
    </v-row>
    </v-container>
  



  </template>
  
  <script lang="ts">
    import { defineComponent, ref, onMounted, computed } from "vue";
    import api from "@/api";
    import html2pdf from "html2pdf.js";
    import { PDFDocument } from "pdf-lib";
    import html2canvas from "html2canvas";
    import { useStore } from "vuex";


    export default defineComponent({
        setup() {
            const store = useStore();
            const errorSnackbar = ref<any>({ visible: false, message: "" });
            const issuedOn = ref(new Date().toISOString().substr(0, 10));
            const performedFrom = ref("FD");
            const objectFireProtection = ref("");
            const location = ref("");
            const creator = ref("");
            const reportNumber = ref("");
            const nextReport = ref("");
            const selectedType = ref("Unternehmen");
            const validFrom = ref("");
            const validTo = ref("");
            const typeOptions = ref(["Unternehmen", "Behörde", "Event"]);
            const shareableLink = ref("");
            const shareableLinkFileName = ref("");
            const isLoading = ref(false);
            const uploadedFiles = ref<any[]>([]);
            const signature = ref("");
            const office = ref("Fire Protection Office");

        const headers = [
            { title: 'Name', value: 'name' },
            { title: 'Link', value: 'link' },
        ];

        const submit = async () => {
            await generateImage();
        };

        const replaceSpacesWithUnderscores = (str:string) => {
            return str.replace(/ /g, "_");
        };


        const fetchLastFiles = async () => {
            try {
                const response = await api.post("fireprotection/?action=getLastFiles");
                uploadedFiles.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        onMounted(async () => {
            await fetchLastFiles();
            signature.value = store.state.user.signature;
        });

        const loadSignatureImage = (src: string): Promise<void> => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = () => resolve();
                img.onerror = (err) => reject(err);
            });
        };

        const generateImage = async () => {
            const a4Page = document.getElementById("a4-page");
                if (!a4Page) {
                    console.error("a4-page element not found");
                    return;
                }

                if (signature.value) {
                    try {
                        await loadSignatureImage(signature.value);
                    } catch (error) {
                        console.error("Error loading signature image:", error);
                    }
                }
                const timestamp = Date.now(); // Erstelle den Unix-Timestamp
                const filenameCreated = replaceSpacesWithUnderscores(objectFireProtection.value) + "_" + formatDate(issuedOn.value, false, true) + "_" + timestamp;

                // Verwenden Sie html2canvas, um die a4-page in ein Canvas-Element zu konvertieren
                const canvas = await html2canvas(a4Page, { useCORS: true, scale: 2 });

                // Konvertieren Sie das Canvas-Element in eine Data-URL (base64)
                const dataUrl = canvas.toDataURL("image/png", 1); // Verwenden Sie "image/png" für PNG

                // Erstellen Sie eine Blob-Datei aus der Data-URL
                const byteString = atob(dataUrl.split(",")[1]);
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                const file = new Blob([ab], { type: "image/png" }); // Verwenden Sie "image/png" für PNG

                // Senden Sie die erzeugte Datei an den Server
                sendImageToServer(file, filenameCreated + ".png"); // Ersetzen Sie '.jpg' durch '.png' für PNG
        };

        const sendImageToServer = async (imageData: Blob, filenameCreated:string) => {
            if(isLoading.value) return;
                isLoading.value = true;
                let year = formatDate(issuedOn.value, true, false);
                let isEvent = selectedType.value == 'Event';
                const formData = new FormData();
                formData.append("image", imageData, filenameCreated);
                formData.append("isEvent", isEvent.toString());
                formData.append("year", year.toString());


                try {
                    const response = await api.post("fireprotection/?action=submitDocument", formData);
                    if (response.data.status === "success") {
                        shareableLinkFileName.value = response.data.fileName;
                        shareableLink.value = response.data.shareableLink; // Speichert den Freigabelink in einer Datenvariable
                    } else {
                        console.error("Fehler beim Erstellen des Freigabelinks:", response.data.message);
                    }
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                } finally {
                    isLoading.value = false;
                }
        };

        const formatDate = (date: any, getYear = false, withoutDots = false) => {
            if (!date) return "";

            // Stellen Sie sicher, dass 'date' ein String ist
            const dateString = typeof date === "string" ? date : date.toString();

            const [year, month, day] = dateString.split("-");
            if (getYear) return `${year}`;
            else if (withoutDots) return `${day}${month}${year}`;
            else return `${day}.${month}.${year}`;
        };


        const addMonthsToDate = (date: any, numberOfMonths: number) => {
            const inputDate = new Date(date); // Konvertiere das date-Argument in ein Date-Objekt
                const resultDate = new Date(inputDate);
                const currentMonth = resultDate.getMonth();
                resultDate.setMonth(currentMonth + numberOfMonths);

                // Wenn der Tag des Monats nach dem Hinzufügen der Monate kleiner ist als der ursprüngliche Tag,
                // wird der resultierende Monat auf den letzten Tag des vorherigen Monats zurückgesetzt.
                if (resultDate.getDate() < inputDate.getDate()) {
                    resultDate.setDate(0);
                }

                const day = resultDate.getDate();
                const month = resultDate.getMonth() + 1; // JavaScript-Monate sind 0-basiert, daher +1 für den korrekten Monat
                const year = resultDate.getFullYear();

                return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
        };

        const downloadImage = () => {
            let link = document.createElement('a');
            link.href = shareableLink.value;
            link.download = shareableLinkFileName.value; // oder geben Sie hier Ihren bevorzugten Dateinamen ein
            link.click();
        };

        const getInitials = (value:string) => {
            const words = value.split(' ');
            const initialsValue = words.map(word => word.charAt(0).toUpperCase()).join('');
            return initialsValue;
        }


        const getDateToday = (withDots = false) => {
            const date = new Date();
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            if(!withDots) return `${day}.${month}.${year}`;
            else  return `${day}${month}${year}`;
        };



        const mailTemplate = computed(() => { return `An Benutzer: Unternhemen
            Betreff: Ihre Rechnung(en) & Brandschutzzertifikat(e) vom LSFD oder  Ihre Rechnung(en) vom LSFD 


            [img]https://www.dropbox.com/s/p8f8h1oiz2h54tw/Mail_OfficeFireChief_Header.png?raw=1[/img]

            Sehr geehrte Damen und Herren,
            anbei finden Sie die Rechnung für die bei Ihnen durchgeführte Dienstleistung.
            ________________________________________________________

            Rechnungsdatum: ${getDateToday()}

            Folgende Dienstleistung wurde durchgeführt:

            Brandschutzbegehung

            Wir bitte Sie, den Betrag innerhalb von 3 Tagen zu überweisen, sofern Sie die Rechnung noch nicht beglichen haben.
            Bitte beachten Sie, den richtigen Verwendungszweck zu benutzen.

            Kontoinhaber: Los Santos Fire Department
            Kontonummer: 67771352
            Verwendungszweck: ${getInitials(objectFireProtection.value)}/BS/${getDateToday(true)}
            Rechnungsbetrag: ${selectedType.value == "Behörde" ? 0 : 220}$

            [img]https://www.dropbox.com/s/cls0ihips1oph8a/Mail_Footer_Neutral_OfficeChief.png?raw=1[/img]`;
        });

        const copyMail = async() => {
            try {
                await navigator.clipboard.writeText(mailTemplate.value);
                errorSnackbar.value.message = 'Emailvorlage erfolgreich kopiert.';
                errorSnackbar.value.visible  = true;
                errorSnackbar.value.color  = 'green';
            } catch (err) {
                errorSnackbar.value.message = 'Emailvorlage konnte nicht kopiert werden.';
                errorSnackbar.value.visible  = true;
            }
        }





        return {
            issuedOn,
            performedFrom,
            objectFireProtection,
            location,
            reportNumber,
            nextReport,
            selectedType,
            validFrom,
            validTo,
            typeOptions,
            shareableLink,
            shareableLinkFileName,
            isLoading,
            submit,
            replaceSpacesWithUnderscores,
            generateImage,
            sendImageToServer,
            formatDate,
            addMonthsToDate,
            errorSnackbar,
            downloadImage,
            headers,
            uploadedFiles,
            copyMail,
            creator,
            office,
            signature
        };
        },
    });
    </script>

  <style>
    .fireprotection-doc-background{
        background-color:#fff9ed;
        color:black;
    }
    #a4-page{
        width: 210mm; 
        height: 297mm; 
        margin: 0 auto; 
        border: 1px solid #000; 
        display: flex; 
        flex-direction: column; 
        justify-content: space-between; 
        font-family: 'PT Serif', serif;
    }
    .fireprotection-doc-header-text {
        font-size: 35px;
        font-weight: bold;
        font-style: initial;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .fireprotection-doc-footer-text {
        font-size: 15px;
        font-weight: bold;
        font-style: initial;
        margin-bottom: 8px;
        margin-top: 24px;
    }
    .fireprotection-doc-header-subtext{
        font-size: 11px;
        padding-top: 10px;
    }
    .fireprotection-doc-table > .v-col.v-col-6 {
        padding: 5px;
        font-size: 16px;
    }
    .fireprotection-doc-table{
        top: 21mm;
        position: relative;
    }
</style>