import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onDblclick"]
const _hoisted_2 = { style: {"display":"flex","flex-direction":"column","justify-content":"space-between","height":"100%"} }
const _hoisted_3 = { style: {"display":"flex","justify-content":"flex-end"} }
const _hoisted_4 = ["colspan"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_VBtn = _resolveComponent("VBtn")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_DocumentEditor = _resolveComponent("DocumentEditor")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDocumentEditor(null, true)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Dokument hinzufügen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  class: "ml-5",
                  onClick: _ctx.openAddCategorieDialog
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Kategorie hinzufügen")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_btn, {
                  class: "ml-5",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSortingModal = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Kategorien sortieren")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  class: "ml-5",
                  onClick: _ctx.toggleViewMode
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Ansicht wechseln")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  label: "Suche",
                  class: "search-input",
                  "single-line": "",
                  "hide-details": "",
                  clearable: "",
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.viewMode === 'tiles')
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filteredCategories, (category) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: category.id
              }, [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      sm: "6",
                      md: "4",
                      lg: "3"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.editingField.type !== 'categoryName' || _ctx.editingField.id !== (category ? category.id : -1))
                          ? (_openBlock(), _createElementBlock("h2", {
                              key: 0,
                              onDblclick: ($event: any) => (_ctx.startEditingField('categoryName', category ? category.id : -1))
                            }, _toDisplayString(category ? category.name : ''), 41, _hoisted_1))
                          : (_openBlock(), _createBlock(_component_v_text_field, {
                              key: 1,
                              modelValue: _ctx.editedValue,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editedValue) = $event)),
                              "single-line": "",
                              onKeydown: [
                                _withKeys(($event: any) => (_ctx.updateField(category ? category.id : -1)), ["enter"]),
                                _withKeys(_ctx.cancelEditingField, ["esc"])
                              ],
                              onBlur: _ctx.cancelEditingField,
                              ref_for: true,
                              ref: "editField",
                              class: "mt-5 mr-5"
                            }, null, 8, ["modelValue", "onKeydown", "onBlur"]))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_draggable, {
                      modelValue: category.documents,
                      "onUpdate:modelValue": ($event: any) => ((category.documents) = $event),
                      group: { name: 'documents', pull: 'clone', put: false },
                      tag: "transition-group",
                      animation: 150,
                      onEnd: ($event: any) => (_ctx.saveDocumentSorting(category.documents))
                    }, {
                      item: _withCtx(({ element }) => [
                        (_openBlock(), _createBlock(_component_v_col, {
                          key: element.id,
                          cols: "12",
                          sm: "6",
                          md: "4",
                          lg: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "top"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(element.title), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_card, {
                              onClick: ($event: any) => (_ctx.openDocumentEditor(element))
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                  _createElementVNode("div", _hoisted_3, [
                                    _createVNode(_component_v_btn, {
                                      class: "mr-2 mt-2",
                                      density: "comfortable",
                                      icon: "mdi-delete",
                                      onClick: _withModifiers(($event: any) => (_ctx.openDeleteConfirmationDialog(element.id)), ["stop"])
                                    }, null, 8, ["onClick"])
                                  ]),
                                  _createVNode(_component_v_card_text, { class: "d-flex justify-center align-center mt-n4" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, { size: "60" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-file-document")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_card_title, { class: "truncate mb-n2" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(element.title), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_card_subtitle, { class: "d-flex justify-center align-center" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(!element.creator_name ? '-' : 'Erstellt von ' + element.creator_name), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_card_subtitle, { class: "d-flex justify-center align-center" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(!element.updated_at ? '-' : 'Geändert: ' + _ctx.formatDate(element.updated_at)) + " " + _toDisplayString(element.updated_at_user == null ? '' : " von " + element.updated_at_user), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ])
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024))
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue", "onEnd"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_v_divider, { class: "border-opacity-50 mt-12 mb-12" })
              ], 64))
            }), 128))
          : (_ctx.viewMode === 'table')
            ? (_openBlock(), _createBlock(_component_v_data_table, {
                key: 1,
                headers: _ctx.headers,
                items: _ctx.filteredFlattenedDocuments,
                class: "elevation-1",
                "group-by": _ctx.groupBy,
                "items-per-page": "25"
              }, {
                top: _withCtx(() => [
                  _createVNode(_component_v_toolbar, { flat: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_toolbar_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Dokumente")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_divider, {
                        class: "mx-4",
                        inset: "",
                        vertical: ""
                      }),
                      _createVNode(_component_v_spacer)
                    ]),
                    _: 1
                  })
                ]),
                "group-header": _withCtx(({ item, columns, toggleGroup, isGroupOpen }) => [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", {
                      colspan: columns.length
                    }, [
                      _createVNode(_component_VBtn, {
                        icon: isGroupOpen(item) ? '$expand' : '$next',
                        size: "small",
                        variant: "text",
                        onClick: ($event: any) => (toggleGroup(item))
                      }, null, 8, ["icon", "onClick"]),
                      _createTextVNode(" " + _toDisplayString(item.value), 1)
                    ], 8, _hoisted_4)
                  ])
                ]),
                [`item.title`]: _withCtx(({ item }) => [
                  _createElementVNode("span", {
                    style: {"cursor":"pointer"},
                    onClick: ($event: any) => (_ctx.openDocumentEditor(item))
                  }, _toDisplayString(item.title), 9, _hoisted_5)
                ]),
                [`item.created_at`]: _withCtx(({ item }) => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.created_at)), 1)
                ]),
                [`item.updated_at`]: _withCtx(({ item }) => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(item.updated_at)) + " " + _toDisplayString(item.updated_at_user == null ? '' : " von " + item.updated_at_user), 1)
                ]),
                [`item.actions`]: _withCtx(({ item }) => [
                  _createVNode(_component_v_icon, {
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.openDeleteConfirmationDialog(item.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-delete ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_v_icon, {
                    size: "small",
                    class: "me-2",
                    onClick: ($event: any) => (_ctx.openDocumentEditor(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" mdi-pencil ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 2
              }, 1032, ["headers", "items", "group-by"]))
            : _createCommentVNode("", true),
        _createVNode(_component_DocumentEditor, {
          class: "document-editor-container",
          "selected-document": _ctx.selectedDocument,
          categories: _ctx.categories,
          "onUpdate:selectedDocument": _ctx.updateSelectedDocument,
          onCloseDocument: _ctx.resetSelectedDocument,
          persistent: ""
        }, null, 8, ["selected-document", "categories", "onUpdate:selectedDocument", "onCloseDocument"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.addCategorieDialog,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.addCategorieDialog) = $event)),
          width: "500"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Neue Kategorie hinzufügen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "addCategorieForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Name",
                                  required: "",
                                  modelValue: _ctx.newCategorie.name,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newCategorie.name) = $event)),
                                  onKeydown: _withKeys(_ctx.openAddCategorieDialog, ["enter"])
                                }, null, 8, ["modelValue", "onKeydown"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      text: "true",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addCategorieDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      text: "true",
                      onClick: _ctx.addNewCategorie
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Hinzufügen")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.showSortingModal,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showSortingModal) = $event)),
          "max-width": "600px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Kategorien sortieren ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_draggable, {
                      modelValue: _ctx.categories,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.categories) = $event)),
                      list: _ctx.categories,
                      tag: "transition-group",
                      animation: 150
                    }, {
                      item: _withCtx(({ element }) => [
                        (_openBlock(), _createBlock(_component_v_list_item, {
                          key: element.id,
                          class: "list-item mb-2 d-flex align-center",
                          style: {"cursor":"move"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_avatar, {
                              size: "24",
                              class: "me-2"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("mdi-drag-variant")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createElementVNode("span", null, _toDisplayString(element.name), 1)
                          ]),
                          _: 2
                        }, 1024))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "list"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "error",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showSortingModal = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, { onClick: _ctx.saveCategorySorting }, {
                      default: _withCtx(() => [
                        _createTextVNode("Speichern")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.deleteConfirmationDialog,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.deleteConfirmationDialog) = $event)),
          width: "500"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Dokument löschen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Bist du sicher, dass du das Dokument löschen willst? ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      text: "true",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.deleteConfirmationDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Nein")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "error",
                      text: "true",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => {_ctx.deleteDocument(_ctx.documentToDeleteId); _ctx.deleteConfirmationDialog = false})
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Ja")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ], 64))
}