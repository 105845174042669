<!-- Marquee.vue -->
<template>
    <div class="marquee">
      <span><slot></slot></span>
    </div>
  </template>
  
  <script lang="ts">
  export default {
    name: 'MarqueeAddon',
  };
  </script>
  
  <style scoped>
  .marquee {
    width: 100vw;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
  }
  
  .marquee span {
    display: inline-block;
    padding-left: 90%;
    animation: marquee 25s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
  </style>
  