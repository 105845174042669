<template>
    <v-container fluid>
      <v-row>
        <v-col>
          <h3>Gruppen</h3>
          <v-btn @click="openNewGroupDialog">Neue Gruppe hinzufügen</v-btn>
          <v-data-table
            :headers="groupHeaders"
            :items="groups"
            :item-key="'id'"
            class="mt-4"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="openEditGroupDialog(item)">mdi-pencil</v-icon>
              <v-icon small @click="confirmDelete(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
  
      <v-dialog v-model="editDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ itemType === 'group' ? 'Gruppe bearbeiten' : 'Gruppe hinzufügen' }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="selectedGroup.name" label="Name" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select v-model="selectedMembers" :items="users" item-title="employee" item-value="id" label="Mitglieder" multiple chips></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="editDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="saveGroup">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <v-dialog v-model="confirmDialog" persistent max-width="300px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Löschen bestätigen</span>
          </v-card-title>
          <v-card-text>
            Willst du das wirklich löschen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="confirmDialog = false">Abbrechen</v-btn>
            <v-btn color="blue darken-1" text @click="proceedWithDelete">Löschen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import api from '@/api';
  import { Group, User, GroupMember } from '@/types/AdminMessage';
  
  export default defineComponent({
    setup() {
      const groups = ref<Group[]>([]);
      const users = ref<User[]>([]);
      const selectedGroup = ref<Group | null>(null);
      const selectedMembers = ref<number[]>([]);
      const editDialog = ref(false);
      const confirmDialog = ref(false);
      const itemType = ref('');
      const errorSnackbar = ref<any>({ visible: false, message: "" });
  
      const groupHeaders = [
        { title: 'ID', key: 'id', align: 'start' },
        { title: 'Name', key: 'name' },
        { title: 'Aktionen', key: 'actions' },
      ];
  
      const fetchGroups = async () => {
        try {
          const response = await api.get("admin/messages/?action=getGroups");
          groups.value = response.data.map((group: Group) => ({
            ...group,
            members: group.members || []
          }));
        } catch (error) {
          errorSnackbar.value.message = error.response.data.error;
          errorSnackbar.value.visible = true;
        }
      };
  
      const fetchUsers = async () => {
        try {
          const response = await api.get("admin/messages/?action=getUsers");
          users.value = response.data;
        } catch (error) {
          errorSnackbar.value.message = error.response.data.error;
          errorSnackbar.value.visible = true;
        }
      };
  
      const openEditGroupDialog = (group: Group) => {
        selectedGroup.value = { ...group };
        selectedMembers.value = group.members.map((member: GroupMember) => member.user_id);
        itemType.value = 'group';
        editDialog.value = true;
      };
  
      const openNewGroupDialog = () => {
        selectedGroup.value = { id: 0, name: '', created_at: '', is_member: false, sort_order: 0, members: [] };
        selectedMembers.value = [];
        itemType.value = 'group';
        editDialog.value = true;
      };
  
      const saveGroup = async () => {
        try {
          const payload = {
            ...selectedGroup.value,
            members: selectedMembers.value
          };
          await api.post("admin/messages/?action=saveGroup", payload);
          editDialog.value = false;
          await fetchGroups();
        } catch (error) {
          errorSnackbar.value.message = error.response.data.error;
          errorSnackbar.value.visible = true;
        }
      };
  
      const deleteGroup = async (group: Group) => {
        try {
          await api.post("admin/messages/?action=deleteGroup", { id: group.id });
          await fetchGroups();
        } catch (error) {
          errorSnackbar.value.message = error.response.data.error;
          errorSnackbar.value.visible = true;
        }
      };
  
      const confirmDelete = (group: Group) => {
        selectedGroup.value = group;
        confirmDialog.value = true;
      };
  
      const proceedWithDelete = async () => {
        if (selectedGroup.value) {
          await deleteGroup(selectedGroup.value);
          confirmDialog.value = false;
        }
      };
  
      onMounted(async () => {
        await fetchGroups();
        await fetchUsers();
      });
  
      return {
        groups,
        users,
        groupHeaders,
        selectedGroup,
        selectedMembers,
        editDialog,
        confirmDialog,
        itemType,
        openEditGroupDialog,
        openNewGroupDialog,
        saveGroup,
        confirmDelete,
        proceedWithDelete,
        errorSnackbar,
      };
    },
  });
  </script>
  
  <style scoped>
  </style>
  