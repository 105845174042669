<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Global Settings</v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="formValid">
                <v-text-field
                  v-model="globalSettings.settings.siteName"
                  label="Site Name"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  v-model="globalSettings.settings.siteLogo"
                  label="Site Logo"
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  v-model="globalSettings.settings.employeeNavigation"
                  label="Employee Site Name"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="saveSettings" :disabled="!formValid">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import api from '@/api';
  
  export default defineComponent({
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
      const form = ref(null);
      const formValid = ref(false);
      const globalSettings = ref({
        settings:{
            siteName: '',
            siteLogo: '',
            employeeNavigation: '',
        }
      });
  
      const rules = {
        required: (value: string) => !!value || 'Required',
      };
  
      const fetchSettings = async () => {
        try {
            const response = await api.get('settings/?action=getGlobalSettings');
            globalSettings.value = response.data;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
        }
      };
  
      const saveSettings = async () => {
        if (form.value.validate()) {
          try {
            await api.post('/admin/settings/?action=updateSettings', globalSettings.value);
            form.value.resetValidation();
          } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
          }
        }
      };
  
      onMounted(async () => {
        await fetchSettings();
      });
  
      return {
        form,
        formValid,
        globalSettings,
        rules,
        saveSettings,
        errorSnackbar,
      };
    },
  });
  </script>
  