import axios, { InternalAxiosRequestConfig } from "axios";
import store from "@/store"; // Stellen Sie sicher, dass der Pfad zu Ihrer store/index.ts-Datei korrekt ist

const baseURL = 'https://system.kyuubiddragon.com/restapi/';

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

// Funktion zum Überprüfen, ob das Token abgelaufen ist
function isTokenExpired(state) {
  const tokenExpiry = state.tokenExpiry;
  if (!tokenExpiry) {
    return true;
  }
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime >= tokenExpiry;
}

// Variable zum Speichern des Zeitstempels des letzten Aufrufs
let lastInteractTime = 0;

// Interceptor hinzufügen, um bei jeder Anfrage updateLastInteract aufzurufen
api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const token = store.state.jwt;
  const currentTime = Date.now();

  // Überprüfen, ob das Token gültig ist und ob der letzte Aufruf mehr als eine Minute her ist
  if (token && !isTokenExpired(store.state) && (currentTime - lastInteractTime > 60000)) {
    try {
      await axios.get(`${baseURL}user/?action=updateLastInteract`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      lastInteractTime = currentTime; // Aktualisieren des Zeitstempels
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Interaktionszeit:", error);
    }
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  
  return config;
});

export default api;
