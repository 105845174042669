import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ee3dca50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "headline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_data_table, {
          headers: _ctx.headers,
          items: _ctx.roles,
          class: "elevation-1"
        }, {
          top: _withCtx(() => [
            _createVNode(_component_v_toolbar, { flat: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Rollen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider, {
                  class: "mx-4",
                  inset: "",
                  vertical: ""
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDialog(null)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Neue Rolle")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          [`item.actions`]: _withCtx(({ item }) => [
            _createVNode(_component_v_icon, {
              small: "",
              class: "mr-2",
              onClick: ($event: any) => (_ctx.openDialog(item ?? null))
            }, {
              default: _withCtx(() => [
                _createTextVNode("mdi-pencil")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1032, ["headers", "items"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.dialog,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dialog) = $event)),
          "max-width": "800px",
          persistent: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.dialogTitle), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_container, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              sm: "8",
                              md: "8"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Role Name",
                                  modelValue: _ctx.editedRole.name,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editedRole.name) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              sm: "4",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Power",
                                  modelValue: _ctx.editedRole.power,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editedRole.power) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              sm: "8",
                              md: "8"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Description",
                                  modelValue: _ctx.editedRole.description,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editedRole.description) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              sm: "4",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Sort Order",
                                  modelValue: _ctx.editedRole.sort_order,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editedRole.sort_order) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedPermissions, (sitePermissions, site) => {
                              return (_openBlock(), _createBlock(_component_v_col, {
                                cols: "12",
                                md: "6",
                                key: site
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("h5", null, _toDisplayString(site), 1),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((sitePermissions as Permission[]), (permission) => {
                                    return (_openBlock(), _createBlock(_component_v_checkbox, {
                                      class: "checkbox-spacing",
                                      key: permission.id,
                                      label: permission.name,
                                      value: permission,
                                      modelValue: _ctx.editedRole.permissions,
                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editedRole.permissions) = $event))
                                    }, null, 8, ["label", "value", "modelValue"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.close
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Cancel")
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "",
                      onClick: _ctx.save
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Save")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ], 64))
}