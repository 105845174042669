<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <div class="todo-list-view">
      <v-container>
        <v-row>
          <v-col cols="3" class="v-col-xl-3">
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>Todo Listen</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
            <!-- Liste der Ordner -->
            <v-list class="lists-container d-flex flex-column">
                <template v-for="list in topLevelTodoLists" :key="list.id">
                <v-list-group>
                  <template #activator="{ props }">
                    <v-list-item v-bind="props">
                      <v-list-item-content>
                        {{ list.name }}
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <!-- Rendern der Subordner -->
                  <template v-for="subList in getSubLists(list.id)" :key="subList.id">
                    <v-list-item
                      @click.stop="selectList(subList)"
                      :class="{ active: isActiveList(subList) }"
                    >
                      <v-list-item-content>
                        {{ subList.name }}
                      </v-list-item-content>
                        <template v-slot:append>
                            <v-btn
                                icon="$close"
                                size="medium"
                                variant="text"
                                @click.stop="showDeleteSubListDialog(subList)"
                            ></v-btn>
                        </template>
                    </v-list-item>
                  </template>
                  <v-text-field
                    v-model="newSubGroup"
                    label="Neue Subgruppe hinzufügen..."
                    @keyup.enter="createSubList(list.id)"
                    prepend-inner-icon="mdi-plus"
                    variant="underlined"
                  ></v-text-field>
                </v-list-group>
              </template>
              <!-- Delete confirmation dialog -->
                <v-dialog v-model="deleteSubListDialog.show" max-width="400">
                    <v-card>
                        <v-card-title>
                        <span class="headline">Subgruppe löschen</span>
                        </v-card-title>
                        <v-card-text>
                        Bist du sicher, dass du diese Subgruppe löschen möchtest?
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="deleteSubListDialog.show = false">
                                Abbrechen
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="deleteSubList">
                                Löschen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-spacer></v-spacer>
                <v-list-item @click="showCreateListDialog = true">
                    <v-list-item-title class="d-flex align-center">
                        <v-list-item-icon>
                            <v-icon>mdi-plus</v-icon>
                        </v-list-item-icon>
                            Neuen Ordner hinzufügen
                        </v-list-item-title>
                </v-list-item>
            </v-list>
  
            <v-dialog v-model="showCreateListDialog" max-width="400">
                <v-card>
                    <v-card-title>
                    <span class="headline">Neuen Ordner hinzufügen</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                            <v-col cols="12">
                                <v-text-field
                                v-model="newListName"
                                label="Ordnername"
                                ></v-text-field>
                            </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="showCreateListDialog = false">
                                Abbrechen
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="createList">
                                Erstellen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
                </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <!-- Liste der Todos -->
            <v-card>
                <v-toolbar>
                    <v-btn variant="text" icon="mdi-menu" disabled></v-btn>
                    <v-toolbar-title>
                        <template v-if="editingField.type !== 'listName'">
                            <span @dblclick="startEditingField('listName', selectedList ? selectedList.id : -1)">
                                {{ selectedList ? selectedList.name : '' }}
                            </span>
                        </template>
                        <template v-else>
                            <v-text-field
                                v-model="editedValue"
                                single-line
                                @keydown.enter="updateField"
                                @keydown.esc="cancelEditingField"
                                @blur="cancelEditingField"
                                ref="editField"
                                class="mt-5 mr-5"
                            ></v-text-field>
                        </template>
                    </v-toolbar-title>
                    <div v-if="isSelectedList()" class="d-flex align-center justify-end">
                        <span>Fertige Todos</span>
                        <v-switch
                            v-model="showCompletedTodos"
                            class="d-flex align-center ml-3 mr-3"
                        ></v-switch>
                    </div>
                </v-toolbar>
                <v-card-text>
                    <v-list class="todos-container">
                        <v-list-item
                            v-for="todo in unfinishedTodos"
                            :key="todo.id"
                            @click="selectTodo(todo)"
                            :class="{ active: isActiveTodo(todo) }"
                            :style="getDueDateStyle(todo.due_date)"
                        >
                            <v-row no-gutters align="center">
                                <v-col cols="auto">
                                    <v-checkbox
                                        v-model="todo.completed"
                                        hide-details
                                        @change="updateTodoCompletionStatus(todo)"
                                        :style="todo.importance == 'low' ? 'color:green' : todo.importance == 'medium' ? 'color:orange' : 'color:red'"
                                    ></v-checkbox>
                                </v-col>
                                <v-col>
                                    <v-list-item-title :class="todo.completed ? 'text-decoration-line-through' : ''">{{ todo.title }}</v-list-item-title>
                                    <v-list-item-subtitle
                                        v-if="isSelectedList() && todo.all_checkboxes_count != 0"
                                    >
                                        {{ todo.completed_checkboxes_count + "/" + todo.all_checkboxes_count }} abgeschlossen
                                    </v-list-item-subtitle>
                                </v-col>
                                <!-- Füge das due_date hier hinzu -->
                                <v-col class="text-right d-flex align-center justify-end">
                                    {{ formatDateToDDMMYYYY(todo.due_date) }}
                                </v-col>
                            </v-row>
                        </v-list-item>
                        <v-text-field
                            v-if="isSelectedList()"
                            v-model="newTodoTitle"
                            label="Neues Todo hinzufügen..."
                            @keyup.enter="createTodo"
                            prepend-inner-icon="mdi-plus"
                            variant="underlined"
                        ></v-text-field>
                    </v-list>

                </v-card-text>
            </v-card>
            </v-col>

          <v-col cols="5">
            <!-- Todo-Details -->
            <v-card v-if="selectedTodo">
                <v-toolbar dense flat>
                    <v-toolbar-title>
                        <template v-if="editingField.type !== 'todoTitle'">
                            <span @dblclick="startEditingField('todoTitle', selectedTodo ? selectedTodo.id : -1)">
                                {{ selectedTodo ? selectedTodo.title : '' }}
                            </span>
                        </template>
                        <template v-else>
                            <v-text-field
                                v-model="editedValue"
                                single-line
                                @keydown.enter="updateField"
                                @keydown.esc="cancelEditingField"
                                @blur="cancelEditingField"
                                ref="editField"
                                class="mt-5 mr-5"
                            ></v-text-field>
                        </template>
                    </v-toolbar-title>
                    <div class="d-flex align-center justify-end">
                        <span>Fertige Todos</span>
                        <v-switch
                            v-model="showCompletedTodoCheckboxes"
                            class="d-flex align-center ml-3 mr-3"
                        ></v-switch>
                    </div>
                </v-toolbar>

                <!--<v-card-subtitle>
                    <template v-if="editingField.type !== 'todoShortDescription'">
                        <span @dblclick="startEditingField('todoShortDescription', selectedTodo ? selectedTodo.id : -1)">
                            {{ selectedTodo ? selectedTodo.short_description : '' }}
                        </span>
                    </template>
                    <template v-else>
                        <v-text-field
                            v-model="editedValue"
                            single-line
                            @keydown.enter="updateField"
                            @keydown.esc="cancelEditingField"
                            @blur="cancelEditingField"
                            ref="editField"
                        ></v-text-field>
                    </template>
                </v-card-subtitle>-->

                <v-card-text>
                    <div v-if="selectedTodo">
                        <v-select
                            v-model="selectedTodo.importance"
                            :items="[
                                { text: 'Niedrig', value: 'low'},
                                { text: 'Mittel', value: 'medium'},
                                { text: 'Hoch', value: 'high'}
                            ]"
                            item-title="text"
                            item-value="value"
                            label="Wichtigkeit"
                            @update:model-value="updateImportance"
                        ></v-select>
                    </div>
                        <div v-if="selectedTodo">
                            <v-text-field
                                v-model="selectedTodo.due_date"
                                label="Erledigt bis:"
                                type="date"
                                @change="updateDueDate"
                            ></v-text-field>
                        </div>
                        <!-- Checkboxes -->
                        <div v-if="selectedTodo">
                            <v-list>
                                <v-list-item
                                    v-for="(checkbox) in unfinishedTodoCheckboxes"
                                    :key="checkbox.id"
                                >
                                    <v-row no-gutters align="center">
                                        <v-col cols="auto">
                                            <v-checkbox
                                                v-model="checkbox.completed"
                                                hide-details
                                                @change="updateCheckbox(checkbox.id, checkbox.completed)"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col>
                                            <template v-if="editingField.type !== 'todoCheckboxEdit' || editingField.id !== checkbox.id">
                                                <span @dblclick="startEditingField('todoCheckboxEdit', checkbox ? checkbox.id : -1)">
                                                    {{ checkbox ? checkbox.title : '' }}
                                                </span>
                                            </template>
                                            <template v-else-if="editingField.type === 'todoCheckboxEdit' && editingField.id === checkbox.id">
                                                <v-text-field
                                                    v-model="editedValue"
                                                    single-line
                                                    @keydown.enter="updateField"
                                                    @keydown.esc="cancelEditingField"
                                                    @blur="cancelEditingField"
                                                    ref="editField"
                                                    class="mt-5 mr-5"
                                                ></v-text-field>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-list-item>
                            </v-list>
                        </div>

                        <!-- Neues Todo hinzufügen -->
                        <v-text-field
                            v-model="newTodoCheckbox"
                            label="Neuer Punkt"
                            prepend-inner-icon="mdi-plus"
                            variant="underlined"
                            @keydown.enter="createTodoCheckbox"
                        ></v-text-field>
                    <!-- Zuweisung zu Benutzern -->
                    <div v-if="selectedTodo">
                        <v-select
                            v-model="selectedTodo.assigned_users"
                            :items="usersWithPermissions"
                            item-title="username"
                            item-value="id"
                            label="Zugewiesen an"
                            chips
                            multiple
                            @update:model-value="updateAssignedUser"
                        ></v-select>
                        <v-textarea 
                            label="Informationen" 
                            v-model="selectedTodo.description"
                            @change="updateDescription">
                        </v-textarea>
                    </div>
                </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted, computed } from "vue";
  import api from "@/api";
  import { TodoList, Todo } from "@/types/Todo";


  export default defineComponent({
    name: "TodoView",
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const todoLists = ref<TodoList[]>([]);
        const todos = ref<Todo[]>([]);
        const selectedList = ref<TodoList | null>(null);
        const selectedTodo = ref<Todo | null>(null);
        const newListName = ref<string>("");
        const selectedParentFolder = ref<number | null>(null);
        const showCreateListDialog = ref<boolean>(false);
        const newTodoTitle = ref("");
        const showCompletedTodos = ref(false);
        const showCompletedTodoCheckboxes = ref(false);
        const newSubGroup = ref("");

        const editedValue = ref('');
        const editingField = ref({ type: '', id: -1 });

        const selectedAssignedUsers = computed(() => {
            if(selectedTodo.value?.is_global){
                return usersWithPermissions;
            }else if (selectedTodo.value?.assigned_users) {
                return usersWithPermissions.value.filter(user => selectedTodo?.value!.assigned_users?.includes(user.id));
            } else {
                return [];
            }
        });
        
        const startEditingField = (fieldType: string, fieldId: number) => {
            editingField.value = { type: fieldType, id: fieldId };
            if (fieldType === 'listName' && selectedList.value) {
                editedValue.value = selectedList.value.name;
            }else if (fieldType === 'todoTitle' && selectedTodo.value) {
                editedValue.value = selectedTodo.value.title;
            }else if (fieldType === 'todoSubDescription' && selectedList.value) {
                editedValue.value = selectedList.value.name;
            } else if (fieldType === 'todoCheckboxEdit' && selectedTodo.value && selectedTodo.value.checkboxes) {
                const checkbox = selectedTodo.value.checkboxes.find((c) => c.id === fieldId);
                if (checkbox) {
                    editedValue.value = checkbox.title;
                }
            }
            // Füge hier weitere Bedingungen für andere Feldtypen hinzu
        };

        const cancelEditingField = () => {
            editingField.value = { type: '', id: -1 };
        };

        async function updateField() {
            if (editedValue.value.trim() === '') return;

            if (editingField.value.type === 'listName' && selectedList.value) {
                try {
                    await api.post('todo/?action=updateTodoListName', {
                        id: selectedList.value.id,
                        name: editedValue.value,
                    });
                    selectedList.value.name = editedValue.value;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            } else if (editingField.value.type === 'todoTitle' && selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateTodoTitle', {
                        id: selectedTodo.value.id,
                        title: editedValue.value,
                    });
                    selectedTodo.value.title = editedValue.value;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }else if (editingField.value.type === 'todoCheckboxEdit' && selectedTodo.value && selectedTodo.value.checkboxes) {
                const checkboxIndex = selectedTodo.value.checkboxes.findIndex(
                    (c) => c.id === editingField.value.id
                );
                if (checkboxIndex > -1) {
                    try {
                        await api.post("todo/?action=updateTodoCheckboxText", {
                            id: editingField.value.id,
                            title: editedValue.value,
                        });
                        selectedTodo.value.checkboxes[checkboxIndex].title = editedValue.value;
                    } catch (error) {
                        errorSnackbar.value.message = error.response.data.error;
                        errorSnackbar.value.visible  = true;	
                    }
                }
            }

            // Füge hier weitere Bedingungen für andere Feldtypen hinzu

            cancelEditingField(); // Schließt das bearbeitete Feld nach der Aktualisierung
        }

        const usersWithPermissions = ref<Array<{ id: number; username: string }>>([]);
        async function fetchUsers(listId: number) {
            try {
                const response = await api.post(`todo/?action=getUsersWithTodoPermissions`, {
                    list_id: listId,
                });
                usersWithPermissions.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        const menu = ref(false);
        const newTodoCheckbox = ref('');

        async function updateImportance() {
            if (selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateTodoImportance', {
                        id: selectedTodo.value.id,
                        importance: selectedTodo.value.importance,
                    });
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }

        async function updateDueDate() {
            if (selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateTodoDueDate', {
                        id: selectedTodo.value.id,
                        due_date: selectedTodo.value.due_date,
                    });
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }

        async function updateCheckbox(id:number, checked:boolean) {
            if (selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateCheckbox', {
                        id: id,
                        completed: checked,
                    });
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }

        async function createTodoCheckbox() {
            if (selectedTodo.value && newTodoCheckbox.value) {
                try {
                    const response = await api.post('todo/?action=createTodoCheckbox', {
                        id: selectedTodo.value.id,
                        name: newTodoCheckbox.value,
                    });
                    const newCheckbox = {
                        id: response.data.checkbox_id,
                        todo_id: response.data.list_id,
                        title: newTodoCheckbox.value,
                        completed: false,
                    };
                    if (!selectedTodo.value.checkboxes) {
                        selectedTodo.value.checkboxes = [];
                    }
                    selectedTodo.value.checkboxes.push(newCheckbox);
                    newTodoCheckbox.value = "";
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }
        async function updateAssignedUser() {
            if (selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateTodoAssignedUser', {
                        id: selectedTodo.value.id,
                        assigned_users: selectedTodo.value.assigned_users?.filter((user: any) => user !== ""),
                    });
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }

        async function updateDescription() {
            // Logik zum Aktualisieren des zugewiesenen Benutzers
            if (selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateTodoDescription', {
                        id: selectedTodo.value.id,
                        description: selectedTodo.value.description,
                    });
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }



        const deleteSubListDialog = ref({
            show: false,
            subList: null as TodoList | null,
        });

        function showDeleteSubListDialog(subList: TodoList) {
            deleteSubListDialog.value.show = true;
            deleteSubListDialog.value.subList = subList;
        }

        async function deleteSubList() {
            if (!deleteSubListDialog.value.subList) return;

            try {
                await api.post("todo/?action=deleteTodoList", {
                    list_id: deleteSubListDialog.value.subList.id,
                });
                deleteSubListDialog.value.show = false;
                deleteSubListDialog.value.subList = null;
                await fetchTodoLists();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
            }
        }



        const folderOptions = computed(() => {
            return [{ text: "Neuer Ordner", value: null as (number | null) }].concat(
                todoLists.value.map((list) => ({
                text: list.name,
                value: list.id,
                }))
            );
        });

        const unfinishedTodos = computed(() => {
            let sortedTodos = todos.value.slice().sort((a, b) => {
                return (a.completed ? 1 : 0) - (b.completed ? 1 : 0);
            });

            if (showCompletedTodos.value) {
                return sortedTodos;
            } else {
                return sortedTodos.filter((todo) => !todo.completed);
            }
        });



        const formatDateToDDMMYYYY = (datePart: any) => {
            if(datePart == null) return;
            let [year, month, day] = datePart.split('-');
            const formattedDate = `${day}.${month}.${year}`;
            return formattedDate;
        };

        const getDueDateStyle = (dueDate: any) => {
            if(dueDate == null) return;
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            const todoDueDate = new Date(dueDate);
            todoDueDate.setHours(0, 0, 0, 0);

            if (todoDueDate.getTime() === today.getTime()) {
                return { backgroundColor: '#e862005e' };
            } else if (todoDueDate.getTime() <= yesterday.getTime()) {
                return { backgroundColor: '#ae00005e' };
            } else {
                return {};
            }
        };

        const unfinishedTodoCheckboxes = computed(() => {
            if (showCompletedTodoCheckboxes.value) {
                return selectedTodo.value?.checkboxes;
            } else {
                return selectedTodo.value && selectedTodo.value.checkboxes
                    ? selectedTodo.value.checkboxes.filter((checkbox) => !checkbox.completed)
                    : [];
            }
        });



        // Add this function
        async function updateTodoCompletionStatus(todo: Todo) {
            try {
                await api.post("todo/?action=updateTodoCompletionStatus", {
                    id: todo.id,
                    completed: todo.completed,
                });
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        // Add this function
        function toggleCompletedTodos() {
            showCompletedTodos.value = !showCompletedTodos.value;
        }



        const isActiveList = (list: TodoList) => {
            return selectedList.value && selectedList.value.id === list.id;
        };
        const isActiveTodo = (todo: Todo) => {
            return selectedTodo.value && selectedTodo.value.id === todo.id;
        };
        const isSelectedList = () => {
            return selectedList.value !== null;
        };

        const topLevelTodoLists = computed(() => {
            return todoLists.value.filter((list) => !list.parent_list_id);
        });

        const getSubLists = (parentId: number) => {
            return todoLists.value.filter((list) => list.parent_list_id === parentId);
        };



        onMounted(async () => {
            await fetchTodoLists();
        });

        async function fetchTodoLists() {
            try {
                const response = await api.get("todo/?action=getTodoLists");
                todoLists.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function fetchTodos(listId: number) {
            try {
                const response = await api.post(`todo/?action=getTodos`, {
                    list_id: listId,
                });
                todos.value = response.data.map((todo: Todo) => ({
                    ...todo,
                    completed: !!todo.completed,
                    assigned_users:  todo.assigned_users ? todo.assigned_users.map((userId: number) => Number(userId)) : [],
                    checkboxes: todo.checkboxes ? todo.checkboxes.map((checkbox) => ({
                        ...checkbox,
                        completed: !!checkbox.completed,
                    })) : [],
                }));
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }


        function selectList(list: TodoList) {
            selectedList.value = list;
            fetchTodos(list.id);
            fetchUsers(list.id);
        }

        function selectTodo(todo: Todo) {
         selectedTodo.value = todo;
        }

        async function createList() {
            try {
                await api.post("todo/?action=createTodoList", {
                    name: newListName.value,
                });
                await fetchTodoLists();
                showCreateListDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function createTodo() {
            if (newTodoTitle.value.trim() === "" || !selectedList.value) return;

            try {
                await api.post("todo/?action=createTodo", {
                    title: newTodoTitle.value,
                    list_id: selectedList.value.id,
                });
                newTodoTitle.value = "";
                await fetchTodos(selectedList.value.id);
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function createSubList(parentListId: number) {
            try {
                if(newSubGroup.value == "") return;
                await api.post("todo/?action=createTodoList", {
                    name: newSubGroup.value,
                    parent_list_id: parentListId,
                });
                newSubGroup.value = "";
                await fetchTodoLists();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

  
        return {
            todoLists,
            todos,
            selectedList,
            selectedTodo,
            selectList,
            selectTodo,
            isActiveList,
            isActiveTodo,
            newTodoTitle,
            createTodo,
            isSelectedList,
            folderOptions,
            selectedParentFolder,
            createList,
            newListName,
            showCreateListDialog,
            createSubList,
            topLevelTodoLists,
            getSubLists,
            showCompletedTodos,
            unfinishedTodos,
            updateTodoCompletionStatus,
            toggleCompletedTodos,
            newSubGroup,
            deleteSubListDialog,
            showDeleteSubListDialog,
            deleteSubList,
            startEditingField, cancelEditingField, updateField, editedValue, editingField,
            menu,
            newTodoCheckbox,
            updateImportance,
            updateDueDate,
            updateCheckbox,
            createTodoCheckbox,
            updateAssignedUser,
            usersWithPermissions,
            updateDescription,
            unfinishedTodoCheckboxes,
            showCompletedTodoCheckboxes,
            selectedAssignedUsers,
            formatDateToDDMMYYYY,
            getDueDateStyle,
            errorSnackbar,
        };
    },
  });

  </script>
  
<style scoped>
    .lists-container,
    .todos-container {
        min-height: 50vh;
    }
  
    .todos-container {
        height: 80vh;
    }
    .active {
        background-color: rgba(102, 102, 102, 0.1); /* Hintergrundfarbe für aktive Elemente */
    }
</style>